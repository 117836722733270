import React, { Component } from "react";
import {
  Button,
  CardFooter,
  Label,
  Input,
  List,
  Spinner,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import EmojiPicker from "emoji-picker-react";
import {
  deepClone,
  errorHandler,
  extractQueryParams,
  formatTime,
  generateCalenderDate,
  showToast,
  uploadFileOnServer,
} from "../helper-methods";
import cuid from "cuid";
import moment from "moment";
import ChatMultiFileComponent from "./ChatMultiFileComponent";
import { newSocket } from "../socket-io";
import {
  deleteMessageById,
  getAllMessagesByGroupId,
  updateMessageById,
} from "../http/http-calls";
import InfiniteScroll from "react-infinite-scroller";
import SweetAlert from "react-bootstrap-sweetalert";

class GroupChatBoxComponent extends Component {
  state = {
    isShowEmoji: false,
    messageText: "",
    contents: [],
    messages: [],
    messagesCount: 0,
    formattedMessages: [],
    isMessageDropdownToggle: null,
    messagesPayload: {
      skip: 0,
      limit: 20,
    },
    loadMoreMessage: false,
    manuallyScrolled: false,
    messagesLoading: false,
    alert: null,
    isEditMessage: false,
  };

  _resetState = () => {
    this.setState({
      isShowEmoji: false,
      messageText: "",
      contents: [],
      messages: [],
      messagesCount: 0,
      formattedMessages: [],
      isMessageDropdownToggle: null,
      messagesPayload: {
        skip: 0,
        limit: 20,
      },
      loadMoreMessage: false,
      manuallyScrolled: false,
      messagesLoading: false,
      alert: null,
      isEditMessage: false,
    });
  };

  _formattedMessages = (
    messages = [],
    formattedMessages = [],
    isReset = false
  ) => {
    return new Promise((resolve) => {
      try {
        if (isReset) {
          messages.forEach((message) => {
            const findSameDateObj = formattedMessages?.length
              ? formattedMessages.find((each) =>
                  moment(each.headerTime).isSame(message.timestamp, "day")
                )
              : null;
            if (findSameDateObj) {
              findSameDateObj.messages.unshift(message);
            } else {
              formattedMessages.unshift({
                headerTime: message.timestamp,
                messages: [{ ...message }],
              });
            }
          });

          resolve(formattedMessages);
        } else {
          messages.forEach((message) => {
            const findSameDateObj = formattedMessages?.length
              ? formattedMessages.find((each) =>
                  moment(each.headerTime).isSame(message.timestamp, "day")
                )
              : null;
            if (findSameDateObj) {
              findSameDateObj.messages.unshift(message);
            } else {
              formattedMessages.unshift({
                headerTime: message.timestamp,
                messages: [{ ...message }],
              });
            }
          });

          resolve(formattedMessages);
        }
      } catch (error) {
        console.log("error>>", error);
        resolve([]);
      }
    });
  };

  _getAllMessagesByGroupId = (
    isResetPagination = true,
    isUpdatePagination = false
  ) => {
    const { selectedGroup } = this.props;

    let { messagesPayload, messages, formattedMessages, manuallyScrolled } =
      deepClone(this.state);

    if (isResetPagination) {
      messagesPayload["skip"] = 0;
    } else if (isUpdatePagination) {
      messagesPayload["skip"] = messages.length;
    }

    this.setState({ messagesLoading: true });

    const { resortId } = extractQueryParams();
    messagesPayload["resortId"] = resortId;

    getAllMessagesByGroupId(selectedGroup.id, messagesPayload)
      .then(async (res) => {
        if (res?.messages?.length) {
          if (isResetPagination) {
            messages = res.messages;
            formattedMessages = await this._formattedMessages(
              res.messages,
              [],
              true
            );
          } else {
            messages = messages.concat(res.messages);
            formattedMessages = await this._formattedMessages(
              res.messages,
              formattedMessages,
              false
            );
          }
        } else {
          messages = [];
          formattedMessages = [];
        }

        this.setState(
          {
            messages,
            messagesCount: res.count,
            formattedMessages,
            messagesPayload,
            loadMoreMessage: false,
            messagesLoading: false,
          },
          () => {
            if (!manuallyScrolled) this._scrollToBottomInChatWindow();
          }
        );
        if (!manuallyScrolled) this._scrollToBottomInChatWindow();
      })
      .catch((error) => {
        this.setState({ loadMoreMessage: false, messagesLoading: false });
        errorHandler(error);
      });
  };

  _unsubscribeToGroupChannel = (selectedGroupId) => {
    return new Promise((resolve) => {
      try {
        console.log("unsubscribe selectedGroupId", selectedGroupId);
        if (selectedGroupId) {
          const params = { room: selectedGroupId };
          // remove all callback of newmessage event
          newSocket.removeAllListeners("newmessage");
          // unsubscribe event callback pause
          newSocket.emit("unsubscribe", params, function (res) {
            console.log("unsubscribed>>", res);
            if (res.error) {
              console.log("error>>", res.error);
            }
          });
        }
        resolve(true);
      } catch (error) {
        console.log("error>>", error);
        resolve(false);
      }
    });
  };

  _subscribeToGroupChannel = (selectedGroupId) => {
    try {
      if (selectedGroupId) {
        const params = { room: selectedGroupId };
        newSocket.emit("subscribe", params, function (res) {
          console.log("subscribed>>", res);
          if (res.error) {
            showToast(
              res.reason && res.reason.length
                ? res.reason
                : "Server error. Try again after sometime.",
              "error"
            );
          }
        });

        newSocket.on("newmessage", (receiveMessage) => {
          console.log("receive>>", receiveMessage);
          if (receiveMessage.error) {
            showToast(
              receiveMessage.reason && receiveMessage.reason.length
                ? receiveMessage.reason
                : "Server error. Try again after sometime.",
              "error"
            );
          } else {
            const { user } = this.props;

            // Check if it's own message, then don't append
            if (receiveMessage._from !== user?._id) {
              this._appendLocalMessage({
                ...receiveMessage,
                isMyMessage: false,
                hasMedia:
                  receiveMessage.content && receiveMessage.content.length
                    ? true
                    : false,
              });
            }
          }
        });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { selectedGroup } = this.props;

    if (prevProps?.selectedGroup?.id !== selectedGroup?.id) {
      this._resetState();
      if (prevProps?.selectedGroup?.id)
        await this._unsubscribeToGroupChannel(prevProps.selectedGroup.id);

      if (selectedGroup?.id) {
        this._subscribeToGroupChannel(selectedGroup.id);
        this._getAllMessagesByGroupId();
      }
    }
  };

  componentWillUnmount = () => {
    const { selectedGroup } = this.props;
    if (selectedGroup?.id) this._unsubscribeToGroupChannel(selectedGroup.id);
  };

  _resetSendMessageDetail = () => {
    setTimeout(() => {
      this.setState({
        isShowEmoji: false,
        messageText: "",
        contents: [],
        isEditMessage: false,
      });
    }, 1);
  };

  _saveScrollStatus = (e) => {
    const { manuallyScrolled } = this.state;
    if (!manuallyScrolled) {
      if (
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight >
        2
      )
        this.setState({ manuallyScrolled: true });
    }
  };

  _scrollToBottomInChatWindow = () => {
    if (this.chatWindow)
      this.chatWindow.scrollTop = this.chatWindow.scrollHeight;
  };

  _updateLocalPhoto = (e) => {
    try {
      if (e?.target?.files?.length) {
        const { contents } = this.state;

        for (let file of e.target.files) {
          const fileType = file.type.split("/")[0];
          if (fileType === "image") {
            const previewBlob = URL.createObjectURL(file);
            contents.push({
              uploadData: file,
              previewBlob,
              type: fileType,
            });
          } else {
            showToast("Only image file is allowed", "error");
            continue;
          }
        }
        this.setState({ contents });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  _removePhoto = (index) => {
    const { contents } = this.state;
    contents.splice(index, 1);
    this.setState({ contents });
  };

  _onChangeMessage = (messageText) => {
    this.setState({ messageText });
  };

  _onKeyDownMessage = (e) => {
    if (
      e &&
      e.key &&
      e.keyCode &&
      e.key === "Enter" &&
      e.keyCode === 13 &&
      !e.shiftKey
    )
      this._onClickSendMessage();
  };

  _appendLocalMessage = (message) => {
    return new Promise((resolve, reject) => {
      try {
        let { messages, formattedMessages } = this.state;

        message["isUploading"] = true;

        if (formattedMessages.length) {
          const findSameDateObj = formattedMessages.find((each) =>
            moment(each.headerTime).isSame(message.when, "day")
          );
          if (findSameDateObj) {
            findSameDateObj.messages.push(message);
          } else {
            formattedMessages.push({
              headerTime: message.when,
              messages: [{ ...message }],
            });
          }
        } else {
          formattedMessages = [
            { headerTime: message.when, messages: [{ ...message }] },
          ];
        }

        messages.push(message);

        this.setState({ messages, formattedMessages }, () => {
          resolve(true);
          this._scrollToBottomInChatWindow();
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  _markMessageAsSent = (message) => {
    try {
      const { formattedMessages } = this.state;

      const findSameDateObj = formattedMessages.find((each) =>
        moment(each.headerTime).isSame(message.when, "day")
      );

      if (findSameDateObj) {
        const findMessage = findSameDateObj.messages.find(
          (each) => message.tempMessageId === each.tempMessageId
        );
        findMessage["isUploading"] = false;

        this.setState({ formattedMessages });
      }
    } catch (error) {
      console.log("error>>", error);
    }
  };

  _sendMessageError = (message) => {
    try {
      const { formattedMessages } = this.state;

      const findSameDateObj = formattedMessages.find((each) =>
        moment(each.headerTime).isSame(message.when, "day")
      );

      if (findSameDateObj) {
        const findMessage = findSameDateObj.messages.find(
          (each) => message.tempMessageId === each.tempMessageId
        );
        findMessage["isUploading"] = false;
        findMessage["isError"] = true;
        this.setState({ formattedMessages });
      }
    } catch (error) {
      console.log("error>>", error);
    }
  };

  _publishMessageOnChannel = (message) => {
    try {
      newSocket.emit("newmessage", message, (res) => {
        console.log("send>>", res);
        this._markMessageAsSent(message);
        if (res.error) {
          showToast(
            res.reason && res.reason.length
              ? res.reason
              : "Server error. Try again after sometime.",
            "error"
          );
          this._sendMessageError(message);
        }
      });
    } catch (error) {
      this._sendMessageError(message);
      errorHandler(error);
    }
  };

  _updateMessageContent = (message) => {
    return new Promise((resolve, reject) => {
      try {
        const { formattedMessages } = this.state;

        const findSameDateObj = formattedMessages.find((each) =>
          moment(each.headerTime).isSame(message.when, "day")
        );

        if (findSameDateObj) {
          const findMessage = findSameDateObj.messages.find(
            (each) => message.tempMessageId === each.tempMessageId
          );
          findMessage["content"] = message?.content?.length
            ? message.content
            : [];

          this.setState({ formattedMessages }, () => resolve(true));
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  _uploadMessage = async (message) => {
    try {
      if (
        message?.content?.length &&
        message.content.every((content) => content.uploadData)
      ) {
        const fileUpdateRes = await uploadFileOnServer(message.content);
        message["content"] = [];
        fileUpdateRes.forEach((res) => {
          message.content.push({
            url: res.url,
            mediaType: res.contentType,
          });
        });

        await this._updateMessageContent(message);
      }

      this._publishMessageOnChannel(message);
    } catch (error) {
      errorHandler(error);
    }
  };

  _updateMessageById = async (newMessage, message) => {
    try {
      message["text"] = newMessage.text.trim() || "";
      message["content"] = [];

      const { messages, formattedMessages } = deepClone(this.state);

      const findMessage = messages.find(
        (each) =>
          (each.messageId &&
            message.messageId &&
            each.messageId === message.messageId) ||
          (each.tempMessageId &&
            message.tempMessageId &&
            each.tempMessageId === message.tempMessageId)
      );

      const findSameDateObj = formattedMessages.find((each) =>
        moment(each.headerTime).isSame(message.when || message.timestamp, "day")
      );

      let findFormatMessage = null;

      if (findSameDateObj) {
        findFormatMessage = findSameDateObj.messages.find(
          (each) =>
            (each.messageId &&
              message.messageId &&
              each.messageId === message.messageId) ||
            (each.tempMessageId &&
              message.tempMessageId &&
              each.tempMessageId === message.tempMessageId)
        );
      }

      if (findMessage && findFormatMessage) {
        findMessage["text"] = newMessage.text.trim() || "";
        findMessage["content"] = newMessage.content?.length
          ? newMessage.content
          : [];
        findFormatMessage["text"] = newMessage.text.trim() || "";
        findFormatMessage["content"] = newMessage.content?.length
          ? newMessage.content
          : [];
        this.setState({ messages, formattedMessages });
        if (
          newMessage?.content?.length &&
          newMessage.content.some((content) => content.url)
        ) {
          const filterUploadUrl = newMessage.content.filter(
            (content) => content.url
          );
          filterUploadUrl.forEach((res) => {
            message.content.push({
              url: res.url,
              mediaType: res.type,
            });
          });
        }
        if (
          newMessage?.content?.length &&
          newMessage.content.some((content) => content.uploadData)
        ) {
          const filterUploadData = newMessage.content.filter(
            (content) => content.uploadData
          );
          const fileUpdateRes = await uploadFileOnServer(filterUploadData);
          fileUpdateRes.forEach((res) => {
            message.content.push({
              url: res.url,
              mediaType: res.contentType,
            });
          });
        }
        const payload = {
          text: message.text.trim() || "",
          content: message.content.length ? message.content : [],
        };
        findMessage["content"] = message.content.length ? message.content : [];
        findFormatMessage["content"] = message.content.length
          ? message.content
          : [];
        this.setState({ messages, formattedMessages });

        const { resortId } = extractQueryParams();
        payload["resortId"] = resortId;

        updateMessageById(message.messageId || message.tempMessageId, payload)
          .then((res) => {
            showToast("Message updated", "success");
          })
          .catch((error) => {
            this.setState({ manuallyScrolled: false }, () =>
              this._getAllMessagesByGroupId()
            );
            errorHandler(error);
          });
      } else {
        showToast("Somthing went wrong, Try again after sometime.", "error");
      }
    } catch (error) {
      this.setState({ manuallyScrolled: false }, () =>
        this._getAllMessagesByGroupId()
      );
      errorHandler(error);
    }
  };

  _onClickSendMessage = async () => {
    try {
      const { messageText, contents, isEditMessage } = this.state;
      const { selectedGroup, user } = this.props;

      if (
        user?.id &&
        selectedGroup?.id &&
        (messageText?.trim().length || contents?.length)
      ) {
        const message = {
          _group: selectedGroup.id,
          _from: user.id,
          text: messageText.trim(),
          content: contents?.length ? contents : [],
          when: new Date().toISOString(),
          tempMessageId: cuid(),
        };

        if (isEditMessage) {
          this._updateMessageById(message, isEditMessage);
          this._resetSendMessageDetail();
        } else {
          this._resetSendMessageDetail();
          await this._appendLocalMessage(message);
          this._uploadMessage(message);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  _renderMessage = (message) => {
    let hasImage = false;
    let hasMultipleFile = false;

    if (message?.content?.length) {
      if (message.content.length > 1) {
        hasMultipleFile = true;
      } else {
        switch (message.content[0].type || message.content[0].mediaType) {
          case "image": {
            hasImage = true;
            break;
          }
          default:
        }
      }
    }

    return (
      <>
        {hasImage ? (
          <img
            src={message.content[0].previewBlob || message.content[0].url}
            alt="imagePreview"
            loading="lazy"
          />
        ) : null}
        {hasMultipleFile ? <ChatMultiFileComponent message={message} /> : null}

        {message.text ? (
          <span className="message-content">{message.text}</span>
        ) : null}
      </>
    );
  };

  _cancelEdit = () => {
    this._resetSendMessageDetail();
  };

  _editMessage = (message) => {
    let { contents } = deepClone(this.state);

    contents = [];

    if (message?.content?.length) {
      message.content.forEach((each) => {
        if (each.url && each.mediaType) {
          contents.push({
            url: each.url,
            type: each.mediaType,
          });
        }
      });
    }

    this.setState({
      isEditMessage: message,
      messageText: message?.text || "",
      contents,
    });
  };

  _hideAlert = () => {
    this.setState({ alert: null });
  };

  _onConfirmDeleteMessage = (message) => {
    try {
      this._hideAlert();

      const { messages, formattedMessages } = deepClone(this.state);

      const findMessageIndex = messages.findIndex(
        (each) =>
          (each.messageId &&
            message.messageId &&
            each.messageId === message.messageId) ||
          (each.tempMessageId &&
            message.tempMessageId &&
            each.tempMessageId === message.tempMessageId)
      );

      const findSameDateObj = formattedMessages.find((each) =>
        moment(each.headerTime).isSame(message.when || message.timestamp, "day")
      );

      let findFormatMessageIndex = -1;

      if (findSameDateObj) {
        findFormatMessageIndex = findSameDateObj.messages.findIndex(
          (each) =>
            (each.messageId &&
              message.messageId &&
              each.messageId === message.messageId) ||
            (each.tempMessageId &&
              message.tempMessageId &&
              each.tempMessageId === message.tempMessageId)
        );
      }

      if (findMessageIndex > -1 && findFormatMessageIndex > -1) {
        messages.splice(findMessageIndex, 1);

        findSameDateObj.messages.splice(findFormatMessageIndex, 1);

        this.setState({
          messages,
          formattedMessages,
          messagesCount: this.state.messagesCount - 1,
        });

        const { resortId } = extractQueryParams();
        const payload = { resortId };

        deleteMessageById(message.messageId || message.tempMessageId, payload)
          .then((res) => {
            showToast("Message deleted", "success");
          })
          .catch((error) => {
            this.setState({ manuallyScrolled: false }, () =>
              this._getAllMessagesByGroupId()
            );
            errorHandler(error);
          });
      } else {
        showToast("Somthing went wrong, Try again after sometime.", "error");
      }
    } catch (error) {
      this.setState({ manuallyScrolled: false }, () =>
        this._getAllMessagesByGroupId()
      );
      errorHandler(error);
    }
  };

  _deleteMessageAlert = (message) => {
    if (message) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => this._onConfirmDeleteMessage(message)}
            onCancel={() => this._hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            You want to delete this message. You will not be able to recover it!
          </SweetAlert>
        ),
      });
    } else {
      showToast("Somthing went wrong, Try again after sometime.", "error");
    }
  };

  _toggleIsMessageDropdownToggle = (messageId = null) => {
    if (messageId !== this.state.isMessageDropdownToggle)
      this.setState({ isMessageDropdownToggle: messageId });
    else this.setState({ isMessageDropdownToggle: null });
  };

  _loadMoreMessages = () => {
    const { loadMoreMessage } = this.state;

    if (!loadMoreMessage) {
      this.setState({ loadMoreMessage: true }, () => {
        this._getAllMessagesByGroupId(false, true);
      });
    }
  };

  render() {
    const {
      messages,
      messagesCount,
      loadMoreMessage,
      formattedMessages,
      messageText,
      isShowEmoji,
      contents,
      isMessageDropdownToggle,
      messagesLoading,
      alert,
      isEditMessage,
    } = this.state;

    const { user } = this.props;

    return (
      <>
        {alert}

        <div
          className="card-body"
          ref={(ref) => (this.chatWindow = ref)}
          onScroll={(e) => this._saveScrollStatus(e)}
        >
          <InfiniteScroll
            isReverse={true}
            hasMore={messages.length < messagesCount ? true || false : false}
            loadMore={() => this._loadMoreMessages()}
            loader={
              messages.length < messagesCount && loadMoreMessage ? (
                <div className="text-center" key="messageSpinner">
                  <Spinner />
                </div>
              ) : null
            }
            useWindow={false}
          >
            {formattedMessages?.length ? (
              formattedMessages.map((each) => (
                <div className="ChatDay" key={each.headerTime}>
                  <div className="ChatDate">
                    {generateCalenderDate(each.headerTime)}
                  </div>

                  {each.messages.map((message, messageIndex) => (
                    <React.Fragment
                      key={message["messageId"] || message["tempMessageId"]}
                    >
                      {message._from || message._from.id === user.id ? (
                        // my message
                        <div className="message-sender Message">
                          {this._renderMessage(message)}

                          <div className="d-flex align-items-center">
                            <span className="time">
                              {" "}
                              {formatTime(
                                message.when || message.timestamp
                              )}{" "}
                            </span>
                            {message["isError"] ? (
                              <i
                                title="Error"
                                className="fa fa-exclamation-circle"
                                style={{
                                  color: "#f16667",
                                }}
                              />
                            ) : (
                              <>
                                {message["isRead"] ? (
                                  <i className="fa fa-check seenTick" />
                                ) : (
                                  <i className="fa fa-check greyCheck" />
                                )}
                                <Dropdown
                                  isOpen={
                                    isMessageDropdownToggle ===
                                      message["messageId"] ||
                                    isMessageDropdownToggle ===
                                      message["tempMessageId"]
                                  }
                                  toggle={() =>
                                    this._toggleIsMessageDropdownToggle(
                                      message["messageId"] ||
                                        message["tempMessageId"]
                                    )
                                  }
                                >
                                  <DropdownToggle className="p-0">
                                    <i className="fa fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => this._editMessage(message)}
                                    >
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        this._deleteMessageAlert(message)
                                      }
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        // reciever message
                        <div className="message-reciever Message">
                          {this._renderMessage(message)}

                          <span className="time">
                            {formatTime(message.when || message.timestamp)}
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              ))
            ) : messagesLoading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <div />
            )}
          </InfiniteScroll>
        </div>

        <CardFooter>
          <div className="chat-input-box">
            <div className="emoji-text">
              {/* Emoji */}
              <div className="emoji px-3">
                <Button
                  color="transparent"
                  className="fs-24 p-0"
                  onClick={() =>
                    this.setState({ isShowEmoji: !this.state.isShowEmoji })
                  }
                >
                  <p className="mb-0">
                    <i className="far fa-grin" />
                  </p>
                </Button>
              </div>
              {/* Input Box */}
              <div className="input-box flex-grow-1 h-100">
                <Input
                  type="textarea"
                  placeholder="Type your message here..."
                  onFocus={() => this.setState({ isShowEmoji: false })}
                  value={messageText}
                  onKeyDown={(e) => this._onKeyDownMessage(e)}
                  onChange={(e) => this._onChangeMessage(e.target.value)}
                />
                <Label className="custom-attachment">
                  <Input
                    type="file"
                    value=""
                    multiple
                    accept="image/*"
                    onChange={(e) => this._updateLocalPhoto(e)}
                  />
                  <i className="fa fa-paperclip fs-18" />
                </Label>
              </div>
            </div>
            {/* Send Button */}
            <div className="send-button">
              <Button
                className="fs-24 p-0 h-100 btn-primary btn-round"
                onClick={() => this._onClickSendMessage()}
              >
                <i className="fa fa-send" />
              </Button>
            </div>
          </div>

          {isShowEmoji ? (
            <div className="chatEmoji">
              <EmojiPicker
                onEmojiClick={(event, emojiObject) =>
                  this._onChangeMessage(messageText + emojiObject.emoji)
                }
              />
            </div>
          ) : null}

          {/* this design show when user send files */}
          {contents?.length ? (
            <div className="filePreview">
              <List>
                {contents.map(
                  (content, index) =>
                    (content.previewBlob || content.url) && (
                      <li key={index}>
                        <img
                          src={content.previewBlob || content.url}
                          alt="preview"
                          loading="lazy"
                        />
                        <i
                          className="fa fa-close"
                          onClick={() => this._removePhoto(index)}
                        />
                      </li>
                    )
                )}
              </List>
            </div>
          ) : null}

          {isEditMessage ? (
            <Button className="editCancel" onClick={() => this._cancelEdit()}>
              Cancel
            </Button>
          ) : null}
        </CardFooter>
      </>
    );
  }
}

export default GroupChatBoxComponent;
