import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Table, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { requestStatusConfig } from "../../config/helper-config";
import {
  capitalize,
  formatAddressInSingleText,
  formatDate,
  showToast,
} from "../../helper-methods";
import { getVacationerTrips } from "../../http/http-calls";

const VacationerTripsModal = ({ isOpen, data, toggle }) => {
  const [trips, setTrips] = useState([]);
  const [tripsCount, setTripsCount] = useState(0);

  const [tripPayload, setTripPayload] = useState({
    vacationerId: null,
    skip: 0,
    limit: 5,
    pageNumber: 0,
  });
  const [loading, setLoading] = useState(false);

  const _closeModal = () => {
    toggle();
  };

  const _getVacationerTrips = (payload) => {
    setLoading(true);
    getVacationerTrips(payload)
      .then((res) => {
        setTrips(res?.trips?.length ? res.trips : []);
        setTripsCount(res?.count || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _onPageChange = (pageNumber = 0) => {
    const newTripPayload = { ...tripPayload };
    newTripPayload["skip"] = pageNumber * newTripPayload["limit"];
    newTripPayload["pageNumber"] = pageNumber;
    setTripPayload(newTripPayload);
    _getVacationerTrips(newTripPayload);
  };

  const _getStatusInFormat = (status) => {
    const findStatus = requestStatusConfig.find(
      (each) => each.value === status
    );
    if (findStatus) return findStatus.label;
    else return "N/A";
  };

  useEffect(() => {
    if (isOpen && data?._id) {
      const newTripPayload = { ...tripPayload };
      newTripPayload["vacationerId"] = data._id;
      setTripPayload(newTripPayload);
      _getVacationerTrips(newTripPayload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data?._id]);

  return (
    <Modal
      className="vacationerTripsModal"
      modalTransition={{ timeout: 100 }}
      isOpen={isOpen}
      size="xl"
      toggle={() => _closeModal()}
      centered
    >
      <ModalHeader toggle={() => _closeModal()}>
        Trips {loading ? <Spinner /> : null}
      </ModalHeader>
      <ModalBody>
        {/* ======== Table ======== */}
        <Table responsive className="m-0">
          <thead>
            <tr>
              <th>Resort</th>
              <th>Duration</th>
              <th>Location</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {trips?.length ? (
              trips.map((trip) => (
                <tr key={trip._id}>
                  <td>
                    {trip?._resort?.name
                      ? capitalize(trip._resort.name)
                      : "N/A"}
                  </td>
                  <td>
                    {trip?.startTimeStamp
                      ? formatDate(trip.startTimeStamp)
                      : "N/A"}{" "}
                    {trip?.endTimeStamp ? formatDate(trip.endTimeStamp) : ""}
                  </td>
                  <td>
                    {trip?._resort?.address
                      ? formatAddressInSingleText(trip._resort.address)
                      : "N/A"}
                  </td>
                  <td>
                    {trip?.status ? _getStatusInFormat(trip.status) : "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="4">
                  {loading ? <Spinner /> : "There is no data to display."}
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* ====== Pagination ====== */}
        {trips?.length && tripsCount ? (
          <div className="row mt-4">
            <div className="col-md-6">
              <span className="showng_entries">
                Showing {trips?.length} entries out of {tripsCount}
              </span>
            </div>
            <div className="col-md-6">
              <ReactPaginate
                forcePage={tripPayload.pageNumber}
                className="pagination justify-content-end mb-2"
                breakLabel="..."
                nextLabel={<i className="fa fa-chevron-right" />}
                previousLabel={<i className="fa fa-chevron-left" />}
                previousClassName="previous"
                nextClassName="next"
                onPageChange={({ selected }) => _onPageChange(selected)}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(tripsCount / tripPayload.limit)}
                pageClassName="page-item"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                disabledClassName={"page-item"}
              />
            </div>
          </div>
        ) : null}
      </ModalBody>
    </Modal>
  );
};

export default VacationerTripsModal;
