import React from "react";
import { APP_NAME } from "../../config";

function AuthFooter() {
  return (
    <>
      <footer className="footer public">
        <div className="d-flex align-items-center justify-content-between">
          <span>&copy; 2021 {APP_NAME}</span>
          {/* <span className="powered-by" style={{ color: "#333" }}>
            Powered by
            <a
              href="https://www.logic-square.com/"
              target="_blank"
              className="ml-2"
              rel="noopener noreferrer"
            >
              Logic Square
            </a>
          </span> */}
        </div>
        {/* <Container>
          <nav>
            <ul className="footer-menu d-flex justify-content-center">
              <li>
                <a className="m-0" href="#" onClick={(e) => e.preventDefault()}>
                  Home
                </a>
              </li>
              <li>
                <a className="m-0" href="#" onClick={(e) => e.preventDefault()}>
                  Company
                </a>
              </li>
              <li>
                <a className="m-0" href="#" onClick={(e) => e.preventDefault()}>
                  Portfolio
                </a>
              </li>
              <li>
                <a className="m-0" href="#" onClick={(e) => e.preventDefault()}>
                  Blog
                </a>
              </li>
            </ul>
            <p className="copyright text-center m-0"></p>
          </nav>
        </Container> */}
      </footer>
    </>
  );
}

export default AuthFooter;
