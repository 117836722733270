import React, { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Button, Container, Row, Col, Spinner } from "reactstrap";
import { BASE_URL } from "../../config";
import {
  extractQueryParams,
  formatCurrencyValue,
  formatDate,
  showToast,
} from "../../helper-methods";
import { getAllResortBillings } from "../../http/http-calls";

const SubscriptionInvoice = () => {
  const history = useHistory();

  const userCredential = useSelector((state) => state["userCredential"]);

  const [getAllBillingsPayload, setGetAllBillingsPayload] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 0,
    resortId: null,
  });
  const [billings, setBillings] = useState([]);
  const [billingsCount, setBillingsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const _getAllResortBillings = (payload) => {
    setLoading(true);

    getAllResortBillings(payload)
      .then((res) => {
        setBillings(res.billings.length ? res.billings : []);
        setBillingsCount(res.count ? res.count : 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _onPageChange = (pageNumber = 0) => {
    const newGetAllBillingsPayload = { ...getAllBillingsPayload };
    newGetAllBillingsPayload["skip"] =
      pageNumber * newGetAllBillingsPayload["limit"];
    newGetAllBillingsPayload["pageNumber"] = pageNumber;
    setGetAllBillingsPayload(newGetAllBillingsPayload);
    _getAllResortBillings(newGetAllBillingsPayload);
  };

  const _downloadSubscriptionInvoice = (bill) => {
    const params = {
      userId: userCredential?.user?._id,
      billingId: bill?._id,
      resortId: getAllBillingsPayload?.resortId,
    };

    window.open(
      `${BASE_URL}/resort/download/bill?userId=${params.userId}&billingId=${params.billingId}&resortId=${params.resortId}`,
      "_blank"
    );
  };

  useEffect(() => {
    const { resortId } = extractQueryParams();

    const newGetAllBillingsPayload = { ...getAllBillingsPayload };
    newGetAllBillingsPayload["resortId"] = resortId;
    setGetAllBillingsPayload(newGetAllBillingsPayload);

    _getAllResortBillings(newGetAllBillingsPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header">
            <Row className="align-items-center">
              <Col xl={5} md={4}>
                <div className="page_title">
                  <Button
                    color="link"
                    className="pl-0 pr-2"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  <h1 className="space_remove">
                    Billing {loading ? <Spinner /> : null}
                  </h1>
                </div>
              </Col>
            </Row>
          </div>

          {/* ======== Table ======== */}
          <Table responsive className="m-0">
            <thead>
              <tr>
                <th>Plan</th>
                <th>Billing Period</th>
                <th># of Rooms</th>
                <th>Amount</th>
                <th>Due Date</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {billings?.length ? (
                billings.map((bill) => (
                  <tr key={bill._id}>
                    <td>{bill.plan || "N/A"}</td>
                    <td>
                      {bill.start ? formatDate(bill.start) : "N/A"}{" "}
                      {bill.end ? `to ${formatDate(bill.end)}` : ""}
                    </td>
                    <td>{bill.rooms || "N/A"}</td>
                    <td>
                      {bill.amount || bill.amount === 0
                        ? formatCurrencyValue(bill.amount)
                        : "N/A"}
                    </td>
                    <td>{bill.end ? formatDate(bill.end) : "N/A"}</td>
                    <td>
                      {bill.createdAt ? formatDate(bill.createdAt) : "N/A"}
                    </td>
                    <td>{bill.status || "N/A"}</td>
                    <td>
                      {bill.status === "Paid" ? (
                        <Button
                          className="btn-primary-outline px-4 mw-130"
                          onClick={() => _downloadSubscriptionInvoice(bill)}
                        >
                          Download
                        </Button>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="8">
                    {loading ? <Spinner /> : "There is no data to display."}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {billings?.length && billingsCount ? (
            <div className="row mt-4">
              <div className="col-md-6">
                <span className="showng_entries">
                  Showing {billings?.length} entries out of {billingsCount}
                </span>
              </div>
              <div className="col-md-6">
                <ReactPaginate
                  forcePage={getAllBillingsPayload.pageNumber}
                  className="pagination justify-content-end mb-2"
                  breakLabel="..."
                  nextLabel={<i className="fa fa-chevron-right" />}
                  previousLabel={<i className="fa fa-chevron-left" />}
                  previousClassName="previous"
                  nextClassName="next"
                  onPageChange={({ selected }) => _onPageChange(selected)}
                  pageRangeDisplayed={3}
                  pageCount={Math.ceil(
                    billingsCount / getAllBillingsPayload.limit
                  )}
                  pageClassName="page-item"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  disabledClassName={"page-item"}
                />
              </div>
            </div>
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default SubscriptionInvoice;
