import React, { Component } from "react";
import { Button, CardFooter, Label, Input, List, Spinner } from "reactstrap";
import EmojiPicker from "emoji-picker-react";
import {
  deepClone,
  formatTime,
  generateCalenderDate,
  showToast,
  uploadFileOnServer,
} from "../helper-methods";
import cuid from "cuid";
import moment from "moment";
import ChatMultiFileComponent from "./ChatMultiFileComponent";
import { newSocket } from "../socket-io";
import { getAllMessagesByRequestId } from "../http/http-calls";
import InfiniteScroll from "react-infinite-scroller";

class RequestChatBoxComponent extends Component {
  state = {
    isShowEmoji: false,
    messageText: "",
    contents: [],
    messages: [],
    messagesCount: 0,
    formattedMessages: [],
    messagesPayload: {
      skip: 0,
      limit: 20,
      resortId: this.props.activeResortId,
    },
    loadMoreMessage: false,
    manuallyScrolled: false,
    messagesLoading: false,
    alert: null,
  };

  _resetState = () => {
    this.setState({
      isShowEmoji: false,
      messageText: "",
      contents: [],
      messages: [],
      messagesCount: 0,
      formattedMessages: [],
      messagesPayload: {
        skip: 0,
        limit: 20,
        resortId: this.props.activeResortId,
      },
      loadMoreMessage: false,
      manuallyScrolled: false,
      messagesLoading: false,
      alert: null,
    });
  };

  _formattedMessages = (
    messages = [],
    formattedMessages = [],
    isReset = false
  ) => {
    return new Promise((resolve) => {
      try {
        if (isReset) {
          messages.forEach((message) => {
            const findSameDateObj = formattedMessages?.length
              ? formattedMessages.find((each) =>
                  moment(each.headerTime).isSame(message.timestamp, "day")
                )
              : null;
            if (findSameDateObj) {
              findSameDateObj.messages.unshift(message);
            } else {
              formattedMessages.unshift({
                headerTime: message.timestamp,
                messages: [{ ...message }],
              });
            }
          });

          resolve(formattedMessages);
        } else {
          messages.forEach((message) => {
            const findSameDateObj = formattedMessages?.length
              ? formattedMessages.find((each) =>
                  moment(each.headerTime).isSame(message.timestamp, "day")
                )
              : null;
            if (findSameDateObj) {
              findSameDateObj.messages.unshift(message);
            } else {
              formattedMessages.unshift({
                headerTime: message.timestamp,
                messages: [{ ...message }],
              });
            }
          });

          resolve(formattedMessages);
        }
      } catch (error) {
        console.log("error>>", error);
        resolve([]);
      }
    });
  };

  _getAllMessagesByRequestId = (
    isResetPagination = true,
    isUpdatePagination = false
  ) => {
    const { selectedRequest } = this.props;

    let { messagesPayload, messages, formattedMessages, manuallyScrolled } =
      deepClone(this.state);

    if (isResetPagination) {
      messagesPayload["skip"] = 0;
    } else if (isUpdatePagination) {
      messagesPayload["skip"] = messages.length;
    }

    this.setState({ messagesLoading: true });

    getAllMessagesByRequestId(selectedRequest.id, messagesPayload)
      .then(async (res) => {
        if (res?.messages?.length) {
          if (isResetPagination) {
            messages = res.messages;
            formattedMessages = await this._formattedMessages(
              res.messages,
              [],
              true
            );
          } else {
            messages = messages.concat(res.messages);
            formattedMessages = await this._formattedMessages(
              res.messages,
              formattedMessages,
              false
            );
          }
        } else {
          messages = [];
          formattedMessages = [];
        }

        this.setState(
          {
            messages,
            messagesCount: res.count,
            formattedMessages,
            messagesPayload,
            loadMoreMessage: false,
            messagesLoading: false,
          },
          () => {
            if (!manuallyScrolled) this._scrollToBottomInChatWindow();
          }
        );
        if (!manuallyScrolled) this._scrollToBottomInChatWindow();
      })
      .catch((error) => {
        console.log("error>>", error);
        this.setState({ loadMoreMessage: false, messagesLoading: false });
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      });
  };

  _unsubscribeToGroupChannel = (selectedRequestId) => {
    return new Promise((resolve) => {
      try {
        console.log("unsubscribe selectedRequestId", selectedRequestId);
        if (selectedRequestId) {
          const params = { room: selectedRequestId };
          // remove all callback of newmessage event
          newSocket.removeAllListeners("newmessage");
          // unsubscribe event callback pause
          newSocket.emit("unsubscribe", params, function (res) {
            console.log("unsubscribed>>", res);
            if (res.error) {
              console.log("error>>", res.error);
            }
          });
        }
        resolve(true);
      } catch (error) {
        console.log("error>>", error);
        resolve(false);
      }
    });
  };

  _subscribeToGroupChannel = (selectedRequestId) => {
    try {
      if (selectedRequestId) {
        const params = { room: selectedRequestId };
        newSocket.emit("subscribe", params, function (res) {
          console.log("subscribed>>", res);
          if (res.error) {
            showToast(
              res.reason && res.reason.length
                ? res.reason
                : "Server error. Try again after sometime.",
              "error"
            );
          }
        });

        newSocket.on("newmessage", (receiveMessage) => {
          console.log("receive>>", receiveMessage);
          if (receiveMessage.error) {
            showToast(
              receiveMessage.reason && receiveMessage.reason.length
                ? receiveMessage.reason
                : "Server error. Try again after sometime.",
              "error"
            );
          } else {
            const { user } = this.props;

            // Check if it's own message, then don't append
            if (receiveMessage._from !== user?._id) {
              this._appendLocalMessage({
                ...receiveMessage,
                isMyMessage: false,
                hasMedia:
                  receiveMessage.content && receiveMessage.content.length
                    ? true
                    : false,
              });
            }
          }
        });
      }
    } catch (error) {
      console.log("error>>", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  componentDidMount = () => {
    const { selectedRequest } = this.props;

    if (selectedRequest?.id) {
      this._subscribeToGroupChannel(selectedRequest.id);
      this._getAllMessagesByRequestId();
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { selectedRequest } = this.props;

    if (prevProps?.selectedRequest?.id !== selectedRequest?.id) {
      this._resetState();
      if (prevProps?.selectedRequest?.id)
        await this._unsubscribeToGroupChannel(prevProps.selectedRequest.id);

      if (selectedRequest?.id) {
        this._subscribeToGroupChannel(selectedRequest.id);
        this._getAllMessagesByRequestId();
      }
    }
  };

  componentWillUnmount = () => {
    const { selectedRequest } = this.props;
    if (selectedRequest?.id)
      this._unsubscribeToGroupChannel(selectedRequest.id);
  };

  _resetSendMessageDetail = () => {
    setTimeout(() => {
      this.setState({
        isShowEmoji: false,
        messageText: "",
        contents: [],
      });
    }, 1);
  };

  _saveScrollStatus = (e) => {
    const { manuallyScrolled } = this.state;
    if (!manuallyScrolled) {
      if (
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight >
        2
      )
        this.setState({ manuallyScrolled: true });
    }
  };

  _scrollToBottomInChatWindow = () => {
    if (this.chatWindow)
      this.chatWindow.scrollTop = this.chatWindow.scrollHeight;
  };

  _updateLocalPhoto = (e) => {
    try {
      if (e?.target?.files?.length) {
        const { contents } = this.state;

        for (let file of e.target.files) {
          const fileType = file.type.split("/")[0];
          if (fileType === "image") {
            const previewBlob = URL.createObjectURL(file);
            contents.push({
              uploadData: file,
              previewBlob,
              type: fileType,
            });
          } else {
            showToast("Only image file is allowed", "error");
            continue;
          }
        }
        this.setState({ contents });
      }
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  _removePhoto = (index) => {
    const { contents } = this.state;
    contents.splice(index, 1);
    this.setState({ contents });
  };

  _onChangeMessage = (messageText) => {
    this.setState({ messageText });
  };

  _appendLocalMessage = (message) => {
    return new Promise((resolve, reject) => {
      try {
        let { messages, formattedMessages } = this.state;

        message["isUploading"] = true;

        if (formattedMessages.length) {
          const findSameDateObj = formattedMessages.find((each) =>
            moment(each.headerTime).isSame(message.when, "day")
          );
          if (findSameDateObj) {
            findSameDateObj.messages.push(message);
          } else {
            formattedMessages.push({
              headerTime: message.when,
              messages: [{ ...message }],
            });
          }
        } else {
          formattedMessages = [
            { headerTime: message.when, messages: [{ ...message }] },
          ];
        }

        messages.push(message);

        this.setState({ messages, formattedMessages }, () => {
          resolve(true);
          this._scrollToBottomInChatWindow();
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  _markMessageAsSent = (message) => {
    try {
      const { formattedMessages } = this.state;

      const findSameDateObj = formattedMessages.find((each) =>
        moment(each.headerTime).isSame(message.when, "day")
      );

      if (findSameDateObj) {
        const findMessage = findSameDateObj.messages.find(
          (each) => message.tempMessageId === each.tempMessageId
        );
        findMessage["isUploading"] = false;

        this.setState({ formattedMessages });
      }
    } catch (error) {
      console.log("error>>", error);
    }
  };

  _sendMessageError = (message) => {
    try {
      const { formattedMessages } = this.state;

      const findSameDateObj = formattedMessages.find((each) =>
        moment(each.headerTime).isSame(message.when, "day")
      );

      if (findSameDateObj) {
        const findMessage = findSameDateObj.messages.find(
          (each) => message.tempMessageId === each.tempMessageId
        );
        findMessage["isUploading"] = false;
        findMessage["isError"] = true;
        this.setState({ formattedMessages });
      }
    } catch (error) {
      console.log("error>>", error);
    }
  };

  _publishMessageOnChannel = (message) => {
    try {
      newSocket.emit("newmessage", message, (res) => {
        console.log("send>>", res);
        this._markMessageAsSent(message);
        if (res.error) {
          showToast(
            res.reason && res.reason.length
              ? res.reason
              : "Server error. Try again after sometime.",
            "error"
          );
          this._sendMessageError(message);
        }
      });
    } catch (error) {
      console.log("error>>", error);
      this._sendMessageError(message);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  _updateMessageContent = (message) => {
    return new Promise((resolve, reject) => {
      try {
        const { formattedMessages } = this.state;

        const findSameDateObj = formattedMessages.find((each) =>
          moment(each.headerTime).isSame(message.when, "day")
        );

        if (findSameDateObj) {
          const findMessage = findSameDateObj.messages.find(
            (each) => message.tempMessageId === each.tempMessageId
          );
          findMessage["content"] = message?.content?.length
            ? message.content
            : [];

          this.setState({ formattedMessages }, () => resolve(true));
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  _uploadMessage = async (message) => {
    try {
      if (
        message?.content?.length &&
        message.content.every((content) => content.uploadData)
      ) {
        const fileUpdateRes = await uploadFileOnServer(message.content);
        message["content"] = [];
        fileUpdateRes.forEach((res) => {
          message.content.push({
            url: res.url,
            mediaType: res.contentType,
          });
        });

        await this._updateMessageContent(message);
      }

      this._publishMessageOnChannel(message);
    } catch (error) {
      console.log("error>>", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Something went wrong. Try again after sometime.",
        "error"
      );
    }
  };

  _onClickSendMessage = async () => {
    try {
      const { messageText, contents } = this.state;
      const { selectedRequest, user } = this.props;

      if (
        user?._id &&
        selectedRequest?.id &&
        (messageText?.trim().length || contents?.length)
      ) {
        const message = {
          _booking: selectedRequest.id,
          _from: user._id,
          text: messageText.trim(),
          content: contents?.length ? contents : [],
          when: new Date().toISOString(),
          tempMessageId: cuid(),
        };

        this._resetSendMessageDetail();
        await this._appendLocalMessage(message);
        this._uploadMessage(message);
      }
    } catch (error) {
      console.log("error>>", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Something went wrong. Try again after sometime.",
        "error"
      );
    }
  };

  _onKeyDownMessage = (e) => {
    if (
      e &&
      e.key &&
      e.keyCode &&
      e.key === "Enter" &&
      e.keyCode === 13 &&
      !e.shiftKey
    )
      this._onClickSendMessage();
  };

  _renderMessage = (message) => {
    let hasImage = false;
    let hasMultipleFile = false;

    if (message?.content?.length) {
      if (message.content.length > 1) {
        hasMultipleFile = true;
      } else {
        switch (message.content[0].type || message.content[0].mediaType) {
          case "image": {
            hasImage = true;
            break;
          }
          default:
        }
      }
    }

    return (
      <>
        {hasImage ? (
          <img
            src={message.content[0].previewBlob || message.content[0].url}
            alt="imagePreview"
            loading="lazy"
          />
        ) : null}
        {hasMultipleFile ? <ChatMultiFileComponent message={message} /> : null}

        {message.text ? (
          <span className="message-content">{message.text}</span>
        ) : null}
      </>
    );
  };

  _loadMoreMessages = () => {
    const { loadMoreMessage } = this.state;

    if (!loadMoreMessage) {
      this.setState({ loadMoreMessage: true }, () => {
        this._getAllMessagesByRequestId(false, true);
      });
    }
  };

  render() {
    const {
      messages,
      messagesCount,
      loadMoreMessage,
      formattedMessages,
      messageText,
      isShowEmoji,
      contents,
      messagesLoading,
      alert,
    } = this.state;

    const { user } = this.props;

    return (
      <>
        {alert}

        <div
          className="card-body messageChatWrapper"
          ref={(ref) => (this.chatWindow = ref)}
          onScroll={(e) => this._saveScrollStatus(e)}
        >
          <InfiniteScroll
            isReverse={true}
            hasMore={messages.length < messagesCount ? true || false : false}
            loadMore={() => this._loadMoreMessages()}
            loader={
              messages.length < messagesCount && loadMoreMessage ? (
                <div className="text-center" key="messageSpinner">
                  <Spinner />
                </div>
              ) : null
            }
            useWindow={false}
          >
            {formattedMessages?.length ? (
              formattedMessages.map((each) => (
                <div className="ChatDay" key={each.headerTime}>
                  <div className="ChatDate">
                    {generateCalenderDate(each.headerTime)}
                  </div>

                  {each.messages.map((message, messageIndex) => (
                    <React.Fragment
                      key={message["messageId"] || message["tempMessageId"]}
                    >
                      {console.log({ message, user })}
                      {(message._from._id || message._from) === user._id ? (
                        // my message
                        <div className="message-sender Message">
                          {this._renderMessage(message)}

                          <div className="d-flex align-items-center">
                            <span className="time">
                              {" "}
                              {formatTime(
                                message.when || message.timestamp
                              )}{" "}
                            </span>
                            {message["isError"] ? (
                              <i
                                title="Error"
                                className="fa fa-exclamation-circle"
                                style={{
                                  color: "#f16667",
                                }}
                              />
                            ) : message["isUploading"] ? (
                              <Spinner
                                className="ml-1"
                                style={{
                                  width: "1rem",
                                  height: "1rem",
                                }}
                              />
                            ) : (
                              <>
                                {message["isRead"] ? (
                                  <i className="fa fa-check seenTick" />
                                ) : (
                                  <i className="fa fa-check greyCheck" />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        // reciever message
                        <div className="message-reciever Message">
                          {this._renderMessage(message)}

                          <span className="time">
                            {formatTime(message.when || message.timestamp)}
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              ))
            ) : messagesLoading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <div />
            )}
          </InfiniteScroll>
        </div>

        <CardFooter>
          <div className="chat-input-box">
            <div className="emoji-text">
              {/* Emoji */}
              <div className="emoji px-3">
                <Button
                  color="transparent"
                  className="fs-24 p-0"
                  onClick={() =>
                    this.setState({ isShowEmoji: !this.state.isShowEmoji })
                  }
                >
                  <p className="mb-0">
                    <i className="far fa-grin" />
                  </p>
                </Button>
              </div>
              {/* Input Box */}
              <div className="input-box flex-grow-1 h-100">
                <Input
                  type="textarea"
                  placeholder="Type your message here..."
                  onFocus={() => this.setState({ isShowEmoji: false })}
                  value={messageText}
                  onKeyDown={(e) => this._onKeyDownMessage(e)}
                  onChange={(e) => this._onChangeMessage(e.target.value)}
                />
                <Label className="custom-attachment">
                  <Input
                    type="file"
                    value=""
                    multiple
                    accept="image/*"
                    onChange={(e) => this._updateLocalPhoto(e)}
                  />
                  <i className="fa fa-paperclip fs-18" />
                </Label>
              </div>
            </div>
            {/* Send Button */}
            <div className="send-button">
              <Button
                className="fs-24 p-0 h-100 btn-primary btn-round"
                onClick={() => this._onClickSendMessage()}
              >
                <i className="fa fa-send" />
              </Button>
            </div>
          </div>

          {isShowEmoji ? (
            <div
              className="chatEmoji"
              style={{ bottom: contents?.length ? "120px" : "60px" }}
            >
              <EmojiPicker
                onEmojiClick={(event, emojiObject) =>
                  this._onChangeMessage(messageText + emojiObject.emoji)
                }
              />
            </div>
          ) : null}

          {/* this design show when user send files */}
          {contents?.length ? (
            <div className="filePreview">
              <List>
                {contents.map(
                  (content, index) =>
                    (content.previewBlob || content.url) && (
                      <li key={index}>
                        <img
                          src={content.previewBlob || content.url}
                          alt="preview"
                          loading="lazy"
                        />
                        <i
                          className="fa fa-close"
                          onClick={() => this._removePhoto(index)}
                        />
                      </li>
                    )
                )}
              </List>
            </div>
          ) : null}
        </CardFooter>
      </>
    );
  }
}

export default RequestChatBoxComponent;
