import React, { useEffect, useState } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import Chart from "react-apexcharts";
import { errorHandler } from "../../helper-methods";
import { getServiceGraphData } from "../../http/http-calls";
import LoadingSkeleton from "react-loading-skeleton";

const ServiceGraph = ({ payload }) => {
  // #4
  const [activeOnChatGraph, setActiveOnChatGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        // tickAmount: 4,
        // min: 0,
        // max: 100,
        labels: {
          formatter: (value) => value.toFixed(0),
          style: {
            colors: "#FF1654",
          },
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#FF1654"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: "# Active Chat",
        data: [],
      },
    ],
  });

  // #2.2
  const [reservationDonutGraph, setReservationDonutGraph] = useState({
    donutOptions: {
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      labels: [],
      colors: ["#008ffb", "#FF1654", "#feb019", "#F1512E", "#255aee"],
    },
    donutSeries: [],
  });

  const [serviceBreakdown, setServiceBreakdown] = useState([]);
  const [loading, setLoading] = useState(false);

  const _setBreakdownGraph = (serviceBreakdown = []) => {
    const newReservationDonutGraph = { ...reservationDonutGraph };
    newReservationDonutGraph.donutOptions.labels = [];
    newReservationDonutGraph.donutSeries = [];
    serviceBreakdown.forEach((each) => {
      newReservationDonutGraph.donutOptions.labels.push(each.name);
      newReservationDonutGraph.donutSeries.push(each.count);
    });
    setReservationDonutGraph(newReservationDonutGraph);
    setServiceBreakdown(serviceBreakdown);
  };

  const _setActiveChatGraph = (chatGraph = []) => {
    const newActiveOnChatGraph = { ...activeOnChatGraph };
    newActiveOnChatGraph.lineOptions.xaxis.categories = [];
    newActiveOnChatGraph.lineSeries[0].data = [];

    chatGraph.forEach((each) => {
      newActiveOnChatGraph.lineOptions.xaxis.categories.push(
        `Week ${each._id.week}`
      );
      newActiveOnChatGraph.lineSeries[0].data.push(each.count);
    });

    setActiveOnChatGraph(newActiveOnChatGraph);
  };

  const _getServiceGraphData = (payload) => {
    setLoading(true);
    getServiceGraphData(payload)
      .then((res) => {
        if (res.result) {
          _setBreakdownGraph(res.result.serviceBreakdown);
          _setActiveChatGraph(res.result.chatGraph);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  useEffect(() => {
    if (payload?.startDate && payload?.endDate && payload?.groupByUnit)
      _getServiceGraphData(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.startDate, payload?.endDate, payload?.groupByUnit]);

  return (
    <div className="graphWrapper">
      <div className="graphTitleWrap">
        <h1>Service Breakdown</h1>
      </div>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {reservationDonutGraph.donutSeries?.length ? (
            <Chart
              options={reservationDonutGraph.donutOptions}
              series={reservationDonutGraph.donutSeries}
              type="donut"
              height="200"
              className="chartWrap mb-2"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}

      {serviceBreakdown?.length ? (
        <>
          <p className="legendTxt">Legend</p>

          <ListGroup className="donutGraphDetails">
            {serviceBreakdown.map((each, index) => (
              <ListGroupItem key={index}>
                <span
                  style={{
                    background:
                      reservationDonutGraph.donutOptions.colors[index % 5],
                  }}
                />{" "}
                {each.name} <div>{each.count}</div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </>
      ) : null}

      <div className="graphTitleWrap" style={{ marginTop: 50 }}>
        <h1># Active Chat</h1>
      </div>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {activeOnChatGraph.lineSeries[0]?.data?.length ? (
            <Chart
              options={activeOnChatGraph.lineOptions}
              series={activeOnChatGraph.lineSeries}
              type="area"
              width="100%"
              height="270"
              className="chartWrap"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}
    </div>
  );
};

export default ServiceGraph;
