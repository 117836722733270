import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getPropertiesGraphData } from "../../http/http-calls";
import { errorHandler, formatDecimalNumber } from "../../helper-methods";
import LoadingSkeleton from "react-loading-skeleton";

const PropertiesOverviewGraph = ({ payload }) => {
  // #1.1
  const [activeResortsGraph, setActiveResortsGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: (value) => value.toFixed(0),
          style: {
            colors: "#247BA0",
          },
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#247BA0"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: "# Resorts",
        data: [],
      },
    ],
  });
  // #1.2
  const [resortRoomGraph, setResortRoomGraph] = useState({
    barOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          style: {
            colors: "#247BA0",
          },
          formatter: function (value) {
            if (value === "500+") return value;

            if (typeof value === "string") {
              const [min, max] = value.split("-");

              if (max) return `${min} - ${max} Rooms`;
            }

            return value;
          },
        },
      },
      xaxis: {
        categories: [
          "1-50",
          "51-100",
          "101-150",
          "151-200",
          "201-250",
          "251-300",
          "301-350",
          "351-400",
          "401-450",
          "451-500",
          "500+",
        ],
      },
      colors: ["#247BA0"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    barSeries: [
      {
        name: "# Resorts",
        data: [],
      },
    ],
  });

  const [activeProperties, setActiveProperties] = useState(0);
  const [pastWeekPercentage, setPastWeekPercentage] = useState(0);

  const [loading, setLoading] = useState(false);

  const _setActiveResortsGraph = (data = []) => {
    const newActiveResortsGraph = { ...activeResortsGraph };
    newActiveResortsGraph.lineOptions.xaxis.categories = [];
    newActiveResortsGraph.lineSeries[0].data = [];
    data.forEach((each) => {
      newActiveResortsGraph.lineOptions.xaxis.categories.push(
        `Week ${each._id.week}`
      );
      newActiveResortsGraph.lineSeries[0].data.push(each.count);
    });
    setActiveResortsGraph(newActiveResortsGraph);

    const guestLen = data?.length;

    if (guestLen > 1) {
      const percentage = formatDecimalNumber(
        (data[guestLen - 1].count / data[guestLen - 2].count) * 100 - 100
      );
      setPastWeekPercentage(percentage);
    } else {
      setPastWeekPercentage(0);
    }
  };

  const _setResortRoomGraph = (data = []) => {
    const newResortRoomGraph = { ...resortRoomGraph };

    newResortRoomGraph.barSeries[0].data = [];

    newResortRoomGraph.barOptions.xaxis.categories.forEach((category) => {
      const findGuestAge = data.find((each) => each._id === category);

      newResortRoomGraph.barSeries[0].data.push(
        findGuestAge?.count ? findGuestAge.count : 0
      );
    });
    setResortRoomGraph(newResortRoomGraph);
  };

  const _getPropertiesGraphData = (payload) => {
    setLoading(true);
    getPropertiesGraphData(payload)
      .then((res) => {
        if (res.result) {
          // activeProperties
          setActiveProperties(res.result.activeProperties || 0);
          // activeResortsGraph
          _setActiveResortsGraph(res.result.activeResortsGraph);
          // resortRoomGraph
          _setResortRoomGraph(res.result.resortRoomGraph);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  useEffect(() => {
    if (payload?.startDate && payload?.endDate && payload?.groupByUnit)
      _getPropertiesGraphData(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.startDate, payload?.endDate, payload?.groupByUnit]);

  return (
    <div className="graphWrapper">
      <div className="graphTitleWrap">
        <h1>Properties Overview</h1>

        <div className="activeUser">
          {loading ? (
            <LoadingSkeleton />
          ) : (
            <h4>
              <span style={{ background: "#247ba0" }} /> {activeProperties}
            </h4>
          )}

          <div>
            <h5># Active Properties</h5>

            <p
              className={
                pastWeekPercentage
                  ? pastWeekPercentage > 0
                    ? "success"
                    : "danger"
                  : ""
              }
            >
              {" "}
              {loading ? (
                <LoadingSkeleton />
              ) : (
                <>
                  {pastWeekPercentage ? (
                    pastWeekPercentage > 0 ? (
                      <i className="fa fa-caret-up" />
                    ) : (
                      <i className="fa fa-caret-down" />
                    )
                  ) : null}
                  <span>{Math.abs(pastWeekPercentage)}%</span> Over the past
                  Week
                </>
              )}
            </p>
          </div>
        </div>
      </div>

      <p className="subHeading" style={{ marginTop: 40 }}>
        # Active Registered Resorts
      </p>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {activeResortsGraph.lineSeries[0]?.data?.length ? (
            <Chart
              options={activeResortsGraph.lineOptions}
              series={activeResortsGraph.lineSeries}
              type="area"
              width="100%"
              height="270"
              className="chartWrap"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}

      {/* resortRoomGraph */}
      <p className="subHeading" style={{ marginTop: 40 }}>
        # Resort Room
      </p>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {resortRoomGraph.barSeries[0]?.data?.length ? (
            <Chart
              options={resortRoomGraph.barOptions}
              series={resortRoomGraph.barSeries}
              type="bar"
              width="100%"
              height="300"
              className="chartWrap mt-3"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}
    </div>
  );
};

export default PropertiesOverviewGraph;
