import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { errorHandler } from "../../helper-methods";
import { getFeedGraphData } from "../../http/http-calls";
import LoadingSkeleton from "react-loading-skeleton";

const FeedGraph = ({ payload }) => {
  // #3
  const [feedViewsGraph, setFeedViewsGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        type: "line",
      },
      stroke: {
        width: [0, 4],
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      // yaxis: {
      //   // tickAmount: 4,
      //   // min: 0,
      //   // max: 100,
      //   labels: {
      //     formatter: (value) => value.toFixed(0),
      //   },
      // },
      yaxis: [
        {
          labels: {
            formatter: (value) => value.toFixed(0),
            style: {
              colors: "#FF1654",
            },
          },
          title: {
            text: "# Page Views",
          },
        },
        {
          opposite: true,
          labels: {
            formatter: (value) => value.toFixed(0),
            style: {
              colors: "#247BA0",
            },
          },
          title: {
            text: "Click Rate",
          },
        },
      ],
      xaxis: {
        categories: [],
      },
      colors: ["#247BA0", "#FF1654"],
      tooltip: {
        // shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: "# Page Views",
        type: "column",
        data: [],
      },
      {
        name: "Click Rate",
        type: "line",
        data: [],
      },
    ],
  });

  const [loading, setLoading] = useState(false);
  const [totalViews, setTotalViews] = useState(0);
  const [clickRate, setClickRate] = useState("0%");

  const _setFeedViewsGraph = ({ pageViewsGraph = [], clickRateGraph = [] }) => {
    const newFeedViewsGraph = { ...feedViewsGraph };

    newFeedViewsGraph.lineOptions.xaxis.categories = [];
    newFeedViewsGraph.lineSeries[0].data = []; // # Page Views
    newFeedViewsGraph.lineSeries[1].data = []; // # Click Rate

    pageViewsGraph.forEach((each) => {
      newFeedViewsGraph.lineOptions.xaxis.categories.push(
        `Week ${each._id.week}`
      );
      newFeedViewsGraph.lineSeries[0].data.push(each.count);
      newFeedViewsGraph.lineSeries[1].data.push(0);
    });
    clickRateGraph.forEach((each) => {
      const findIndex =
        newFeedViewsGraph.lineOptions.xaxis.categories.findIndex(
          (e) => e === `Week ${each._id.week}`
        );

      if (findIndex > -1) {
        newFeedViewsGraph.lineSeries[1].data[findIndex] = each.count;
      } else {
        newFeedViewsGraph.lineOptions.xaxis.categories.push(
          `Week ${each._id.week}`
        );
        newFeedViewsGraph.lineSeries[0].data.push(0);
        newFeedViewsGraph.lineSeries[1].data.push(each.count);
      }
    });

    setFeedViewsGraph(newFeedViewsGraph);
  };

  const _getFeedGraphData = (payload) => {
    setLoading(true);
    getFeedGraphData(payload)
      .then((res) => {
        if (res.result) {
          setTotalViews(res.result.totalViews || 0);
          setClickRate(res.result.clickRate || "0%");
          _setFeedViewsGraph({
            pageViewsGraph: res.result.pageViewsGraph,
            clickRateGraph: res.result.clickRateGraph,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  useEffect(() => {
    if (payload?.startDate && payload?.endDate && payload?.groupByUnit)
      _getFeedGraphData(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.startDate, payload?.endDate, payload?.groupByUnit]);

  return (
    <div className="graphWrapper">
      <div className="graphTitleWrap">
        <h1>Outings Engagement</h1>

        <div className="specialOffers">
          <div>
            {loading ? (
              <LoadingSkeleton />
            ) : (
              <h4>
                <span style={{ background: "#247ba0" }} />
                {totalViews}
              </h4>
            )}

            <p># Page Views</p>
          </div>
          <div>
            {loading ? (
              <LoadingSkeleton />
            ) : (
              <h4>
                <span style={{ background: "#ff1654" }} />
                {clickRate}
              </h4>
            )}

            <p>% Click Rate</p>
          </div>
        </div>
      </div>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {feedViewsGraph.lineSeries[0]?.data?.length ? (
            <Chart
              options={feedViewsGraph.lineOptions}
              series={feedViewsGraph.lineSeries}
              type="line"
              width="100%"
              height="270"
              className="chartWrap"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}
    </div>
  );
};

export default FeedGraph;
