import React, { Component } from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";

class FullPageLoader extends Component {
  state = {};
  render() {
    const { loaderData } = this.props;
    if (loaderData && loaderData.isVisible) {
      return (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            zIndex: 9999999,
            opacity: 0.95,
            backgroundColor: "white",
          }}
        >
          <div className="loaderWrap">
            <Loader type="Oval" color="#1e295c" />
            {loaderData && loaderData.loaderText ? (
              <p>
                {typeof loaderData.loaderText === "string" ||
                loaderData.loaderText instanceof String
                  ? loaderData.loaderText
                  : null}
              </p>
            ) : (
              <div />
            )}
          </div>
        </div>
      );
    } else {
      return <React.Fragment />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

export default connect(mapStateToProps, null)(FullPageLoader);
