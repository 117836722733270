import React, { Component } from "react";
import MultiMediaModal from "./Modals/multi-media-modal";
import { Button } from "reactstrap";
import { deepClone } from "../helper-methods";

class ChatMultiFileComponent extends Component {
  state = {
    isMultiMediaOpen: false,
    content: null,
    contentIndex: null,
  };

  constructor(props) {
    super(props);
    this.audioSource = React.createRef();
    this.videoSource = React.createRef();
  }

  _onMultiMediaOpenMuteAudioVideo = () => {
    // this.videoSource.current.pause();
    if (this.audioSource && this.audioSource.current) {
      this.audioSource.current.pause();
    }
  };

  _openMultiMediaModal = (message = null, index) => {
    let { isMultiMediaOpen, content, contentIndex } = this.state;

    isMultiMediaOpen = !isMultiMediaOpen;
    if (isMultiMediaOpen && message) {
      if (index || index === 0) contentIndex = index;
      content = message.content;
      // this._onMultiMediaOpenMuteAudioVideo();
    } else {
      contentIndex = null;
      isMultiMediaOpen = false;
      content = null;
    }
    this.setState({ isMultiMediaOpen, content, contentIndex });
  };

  _getActiveContentItem = (item, message, index) => {
    switch (item.type || item.mediaType) {
      case "image": {
        return (
          <div
            key={(item?._id ? item._id : "multifile") + index}
            className="groupMediaWrap"
            onClick={() => this._openMultiMediaModal(message, index)}
          >
            <img
              src={item.previewBlob || item.url}
              className="groupMedia"
              alt="media"
              loading="lazy"
            />
          </div>
        );
      }
      case "video": {
        return (
          <div
            key={(item?._id ? item._id : "multifile") + index}
            className="groupMediaWrap"
            onClick={() => this._openMultiMediaModal(message, index)}
          >
            <img
              src={item && item.thumbnail ? `${item.thumbnail}` : ""}
              className="groupMedia"
              alt="thumbnail"
              loading="lazy"
            />

            <div className="shadowOverlayImg">
              <Button className="videoPlayBtn">
                <i className="fa fa-play" />
              </Button>
            </div>
          </div>
        );
      }
      case "audio": {
        return (
          <div
            key={(item?._id ? item._id : "multifile") + index}
            className="groupMediaWrap"
            onClick={() => this._openMultiMediaModal(message, index)}
          >
            <img
              src="/assets/img/microphone.png"
              alt="Microphone"
              style={{ width: 35, height: 50 }}
              loading="lazy"
            />
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  _renderAlbumPreview = () => {
    const { message } = deepClone(this.props);

    let contents = null;
    let albumPreview = null;
    let seeMoreLength = null;
    let seeMore = false;

    contents = message.content;

    if (contents && contents.length) {
      if (contents.length > 4) {
        seeMoreLength = contents.length - 4;
        contents = contents.slice(0, 4);
        seeMore = true;
      }
      albumPreview = (
        <div className="d-flex flex-wrap position-relative">
          {contents.map((content, index) =>
            this._getActiveContentItem(content, message, index)
          )}
          {seeMore ? (
            <div
              className="seeMoreMedia cursorPointer"
              onClick={() => this._openMultiMediaModal(message, 3)}
            >
              <Button color="link">+{seeMoreLength}</Button>
            </div>
          ) : null}
        </div>
      );
    }

    return albumPreview;
  };

  render() {
    const { isMultiMediaOpen, content, contentIndex } = this.state;

    return (
      <div>
        <div className="mediaPhotoWrap">{this._renderAlbumPreview()}</div>

        <MultiMediaModal
          isOpen={isMultiMediaOpen}
          content={content}
          activeIndex={contentIndex}
          toggle={this._openMultiMediaModal}
        />
      </div>
    );
  }
}

export default ChatMultiFileComponent;
