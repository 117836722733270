import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Media,
  Nav,
  NavLink,
  Spinner,
} from "reactstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import AddChatGroupModal from "../../components/Modals/add-chat-group-modal";
import QRCodeModal from "../../components/Modals/qr-code-modal";
import GroupChatBoxComponent from "../../components/GroupChatBoxComponent";
import {
  getAllGroupMembers,
  getAllGroups,
  getResortDetails,
  updateChatGroup,
} from "../../http/http-calls";
import {
  capitalize,
  extractQueryParams,
  formatDate,
  showToast,
} from "../../helper-methods";
import SweetAlert from "react-bootstrap-sweetalert";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import GroupChatMemberModal from "../../components/Modals/GroupChatMemberModal";

let searchInterval;

const GroupChat = () => {
  const history = useHistory();

  const mountedRef = useRef(true);

  const userCredential = useSelector((state) => state["userCredential"]);

  const [groups, setGroups] = useState([]);

  const [members, setMembers] = useState([]);

  const [resortDetails, setResortDetails] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [membersCount, setMembersCount] = useState(0);
  const [membersLoading, setMembersLoading] = useState(false);
  const [loadMoreMembers, setLoadMoreMembers] = useState(false);
  const [timeZone, setTimeZone] = useState(null);

  const [searchLoading, setSearchLoading] = useState(false);
  const [getAllGroupMemberPayload, setGetAllGroupMemberPayload] = useState({
    _group: null,
    search: "",
    skip: 0,
    limit: 20,
  });

  const [addChatGroupModal, setAddChatGroupModal] = useState({
    isOpen: false,
    data: null,
  });
  const [qrCodeModal, setQRCodeModal] = useState({
    isOpen: false,
    data: null,
  });
  const [groupChatMemberModal, setGroupChatMemberModal] = useState({
    isOpen: false,
    data: null,
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isShowMembersList, setIsShowMembersList] = useState(true);

  const _getResortDetails = () => {
    const { resortId } = extractQueryParams();

    getResortDetails(resortId)
      .then((res) => setResortDetails(res.resort))
      .catch((error) => {
        console.log("error>>", error);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _getAllGroupMembers = (payload, isUpdate = false) => {
    if (!payload?._group) {
      console.log("payload>>", payload);
      showToast("Something went wrong, Try again after sometime.", "error");
      return;
    }
    setMembersLoading(true);

    const { resortId } = extractQueryParams();
    payload["resortId"] = resortId;

    getAllGroupMembers(payload)
      .then((res) => {
        setMembers(
          res.vacationers.length
            ? isUpdate
              ? members.concat(res.vacationers)
              : res.vacationers
            : []
        );
        setMembersCount(res.count);
        setMembersLoading(false);
        setSearchLoading(false);
        setLoadMoreMembers(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setMembersLoading(false);
        setSearchLoading(false);
        setLoadMoreMembers(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _toggleSelectedGroup = (newSelectedGroup) => {
    if (newSelectedGroup?.id && selectedGroup !== newSelectedGroup.id) {
      setSelectedGroup(newSelectedGroup);
      const newGetAllGroupMemberPayload = { ...getAllGroupMemberPayload };
      newGetAllGroupMemberPayload["_group"] = newSelectedGroup.id;
      newGetAllGroupMemberPayload["search"] = "";
      setGetAllGroupMemberPayload(newGetAllGroupMemberPayload);
      _getAllGroupMembers(newGetAllGroupMemberPayload);
    }
  };

  const _hideAlert = () => {
    setAlert(null);
  };

  const _toggleAddChatGroupModal = (isOpen = false, data = null) => {
    setAddChatGroupModal({
      isOpen,
      data,
    });
  };

  const _toggleQRCodeModal = (isOpen = false, data = null) => {
    setQRCodeModal({
      isOpen,
      data,
    });
  };

  const _toggleGroupChatMemberModal = (isOpen = false, data = null) => {
    setGroupChatMemberModal({
      isOpen,
      data,
    });
  };

  const _resetState = () => {
    setGroups([]);
    setSelectedGroup(null);
    setGetAllGroupMemberPayload({
      _group: null,
      search: "",
      skip: 0,
      limit: 20,
    });
    setMembers([]);
    setMembersCount(0);
  };

  const _getAllGroups = () => {
    setLoading(true);

    const { resortId } = extractQueryParams();

    getAllGroups({ resortid: resortId })
      .then((res) => {
        // if (!mountedRef.current) return null;
        if (res?.groups?.length) {
          let isSelectMain = true;
          if (selectedGroup) {
            const newSelectedGroup = res.groups.find(
              (group) => group.id === selectedGroup.id
            );
            if (newSelectedGroup) {
              _toggleSelectedGroup(newSelectedGroup);
              isSelectMain = false;
            }
          }
          if (isSelectMain) {
            const mainGroup = res.groups.find((group) => group.isMain);
            if (mainGroup) _toggleSelectedGroup(mainGroup);
            else _toggleSelectedGroup(res.groups[0]);
          }
          setGroups(res.groups);
        } else {
          _resetState();
        }
        setLoading(false);
      })
      .catch((error) => {
        if (!mountedRef.current) return null;

        console.log("error>>", error);
        setLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _editGroupDetail = () => {
    if (selectedGroup) _toggleAddChatGroupModal(true, selectedGroup);
    else showToast("Somthing went wrong, Try again after sometime.", "error");
  };

  const _generateQRCode = () => {
    if (selectedGroup) _toggleQRCodeModal(true, selectedGroup);
    else showToast("Somthing went wrong, Try again after sometime.", "error");
  };

  const _archiveChatGroupErrorAlert = (group, error) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={`${
          group?.name ? `${capitalize(group.name)}` : "N/A"
        } group is not archived.`}
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
        confirmBtnBsStyle="info"
      >
        {error?.reason?.length
          ? error.reason
          : "Server error, Try again after sometime."}
      </SweetAlert>
    );
  };

  const _successArchiveChatGroup = (group) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={`${
          group?.name ? `${capitalize(group.name)}` : "N/A"
        } group is archived.`}
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
      />
    );
  };

  const _archiveChatGroup = (group) => {
    const payload = { isArchived: true };

    const { resortId } = extractQueryParams();
    payload["resortId"] = resortId;

    updateChatGroup(group.id, payload)
      .then((res) => {
        _successArchiveChatGroup(group);
        _getAllGroups();
      })
      .catch((error) => {
        console.log("error>>", error);
        _archiveChatGroupErrorAlert(group, "error");
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _onConfirmAlert = (group) => {
    _archiveChatGroup(group);

    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <Spinner className="mr-2" /> Archiving
            <span>{group?.name ? `${capitalize(group.name)}` : "N/A"} </span>
            group
          </div>
        }
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
        showConfirm={false}
        cancelBtnBsStyle="danger"
        cancelBtnText="Dismiss"
        showCancel
      />
    );
  };

  const _onArchiveChatGroupAlert = () => {
    if (selectedGroup) {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => _onConfirmAlert(selectedGroup)}
          onCancel={() => _hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, archive it!"
          cancelBtnText="Cancel"
          showCancel
        >
          You are archiving{" "}
          {selectedGroup.name ? `${capitalize(selectedGroup.name)}` : "N/A"}{" "}
          group. You will not be able to recover it!
        </SweetAlert>
      );
    } else showToast("Somthing went wrong, Try again after sometime.", "error");
  };

  const _onChangeSearch = (searchValue) => {
    clearInterval(searchInterval);
    setSearchLoading(true);
    const newGetAllGroupMemberPayload = { ...getAllGroupMemberPayload };
    newGetAllGroupMemberPayload["search"] = searchValue;
    newGetAllGroupMemberPayload["skip"] = 0;
    setGetAllGroupMemberPayload(newGetAllGroupMemberPayload);
    searchInterval = setTimeout(() => {
      _getAllGroupMembers(newGetAllGroupMemberPayload);
    }, 1000);
  };

  useEffect(() => {
    const { timeZone } = extractQueryParams();

    setTimeZone(timeZone);

    _getAllGroups();
    _getResortDetails();

    // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _loadMoreMembers = () => {
    if (!loadMoreMembers) {
      setLoadMoreMembers(true);
      const newGetAllGroupMemberPayload = { ...getAllGroupMemberPayload };
      newGetAllGroupMemberPayload["skip"] = members.length;
      setGetAllGroupMemberPayload(newGetAllGroupMemberPayload);
      _getAllGroupMembers(newGetAllGroupMemberPayload, true);
    }
  };

  const _removeMemberFromList = (groupId, memberId) => {
    if (selectedGroup?.id === groupId) {
      const newMembers = [...members];
      const findMemberIndex = newMembers.findIndex(
        (each) => each.id === memberId
      );
      if (findMemberIndex > -1) {
        newMembers.splice(findMemberIndex, 1);
        setMembersCount((prevCount) => --prevCount);
        setMembers(newMembers);
      } else {
        const newGetAllGroupMemberPayload = { ...getAllGroupMemberPayload };
        newGetAllGroupMemberPayload["skip"] = 0;
        setGetAllGroupMemberPayload(newGetAllGroupMemberPayload);
        _getAllGroupMembers(newGetAllGroupMemberPayload);
      }
    }
  };

  return (
    <>
      {alert}

      <div className="content wedding-group-chat">
        <Container fluid className="mobilePadding-0">
          {/* ===== Page Title ===== */}
          <div className="page_Inner_header">
            <Row className="align-items-center no-margin">
              <Col xl={8} className="mobilePadding-0">
                <div className="page_title">
                  <Button
                    color="link"
                    className="pl-0 pr-2"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  <h1 className="space_remove">
                    Feed {loading ? <Spinner /> : null}
                  </h1>
                </div>
              </Col>
              <Col md={4} className="text-right">
                <Button
                  onClick={() => _toggleAddChatGroupModal(true)}
                  className="btn btn-primary"
                >
                  Create Group
                </Button>
              </Col>
            </Row>
          </div>

          {/* ======== Nav Tabs ======= */}
          <Nav tabs className="justify-content-end align-items-center my-4">
            <div className="tabsCustom">
              <div className="capsule-container bttn">
                {groups?.length ? (
                  groups.map((group) => (
                    <NavLink
                      key={group.id}
                      className={`capsule-btn fs-16 ${
                        selectedGroup && selectedGroup.id === group.id
                          ? "active"
                          : ""
                      }`}
                      onClick={() => _toggleSelectedGroup(group)}
                    >
                      {group.name ? capitalize(group.name) : "N/A"}
                    </NavLink>
                  ))
                ) : loading ? (
                  <NavLink className={`capsule-btn fs-16 active`}>
                    Loading...
                  </NavLink>
                ) : null}
              </div>
            </div>
          </Nav>

          <div className="d-flex justify-content-center"></div>

          <div>
            {/* ====== Chat ======= */}
            <Row className="m-0 align-items-stretch capsule-container">
              {/* ======= Chat Person List ====== */}
              <Col xl="4" className="mb-4 mb-xl-0 capsule-btn p-0">
                <Card body className="">
                  <div className="mamber_header d-flex justify-content-between align-items-center">
                    <h3 className="space_remove fs-18 font-weight-bold text-blue">
                      All Members{" "}
                      {membersCount ? (
                        <span className="ml-2">({membersCount})</span>
                      ) : null}
                    </h3>
                    <i
                      className={`fa ${
                        isShowMembersList ? "fa-chevron-up" : "fa-chevron-down"
                      }`}
                      onClick={() => {
                        setIsShowMembersList(!isShowMembersList);
                      }}
                    />
                  </div>

                  {isShowMembersList ? (
                    <InputGroup className="my-4">
                      <FormControl
                        type="text"
                        placeholder="Enter"
                        autoComplete="off"
                        name="firstName"
                        value={getAllGroupMemberPayload.search}
                        onChange={(e) => _onChangeSearch(e.target.value)}
                      />
                      <InputGroup.Text>
                        {searchLoading ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : getAllGroupMemberPayload.search ? (
                          <i
                            className="fas fa-times"
                            onClick={() => _onChangeSearch("")}
                          />
                        ) : (
                          <i className="fas fa-search" />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  ) : null}

                  {/* ======== All Members ======== */}
                  {isShowMembersList ? (
                    <div className="all-members-chat">
                      <InfiniteScroll
                        hasMore={
                          members.length < membersCount ? true || false : false
                        }
                        loadMore={() => _loadMoreMembers()}
                        loader={
                          members.length < membersCount && loadMoreMembers ? (
                            <div className="text-center" key="memberSpinner">
                              <Spinner className="chatSpinner" />
                            </div>
                          ) : null
                        }
                        useWindow={false}
                      >
                        <div className="all-members-chat-container mt-2">
                          {members.length
                            ? members.map((member) => (
                                <div
                                  key={member.id}
                                  className="all-members-chat-row d-flex align-items-center justify-content-between"
                                >
                                  <div
                                    className="chat-profile w-100"
                                    onClick={() =>
                                      _toggleGroupChatMemberModal(true, member)
                                    }
                                  >
                                    <Media className="align-items-center w-100">
                                      <Media left>
                                        <Media
                                          object
                                          src={
                                            member?.avatar
                                              ? member.avatar
                                              : DEFAULT_PROFILE_PICTURE
                                          }
                                          onError={(e) =>
                                            (e.target.src =
                                              DEFAULT_PROFILE_PICTURE)
                                          }
                                        />
                                      </Media>
                                      <Media body className="pl-2">
                                        <Media
                                          heading
                                          className="fs-18 m-0 font-weight-bold lh-1"
                                        >
                                          {member?.name?.full
                                            ? capitalize(member.name.full)
                                            : "N/A"}
                                        </Media>
                                        <span className="fs-13 text-secondary d-block">
                                          {member?.username
                                            ? `@${member.username}`
                                            : "N/A"}
                                        </span>
                                      </Media>
                                    </Media>
                                  </div>
                                </div>
                              ))
                            : !loadMoreMembers && (
                                <div
                                  className="all-members-chat-row d-flex align-items-center justify-content-center"
                                  style={{ height: "50px" }}
                                >
                                  <div className="chat-profile  w-100">
                                    <Media className="justify-content-center align-items-center w-100">
                                      {loading || membersLoading ? (
                                        <Spinner />
                                      ) : (
                                        "No member found in this group"
                                      )}
                                    </Media>
                                  </div>
                                </div>
                              )}
                        </div>
                      </InfiniteScroll>
                    </div>
                  ) : null}
                </Card>
              </Col>

              {/* ====== Chat Card ====== */}
              <Col xl="8" className="capsule-btn p-0">
                <div className="chat-card h-100 p-0">
                  <Card className="p-0 h-100">
                    <CardHeader className="px-0 pt-0">
                      <Row className="align-items-center">
                        {/* === Card Header Left === */}
                        <Col xs="6">
                          {/* === Chat Profile === */}
                          <div className="chat-profile  w-100">
                            <Media className="align-items-center">
                              <Media left>
                                <Media
                                  className="cursorPointer"
                                  object
                                  src={
                                    selectedGroup?.displayPicture
                                      ? selectedGroup.displayPicture
                                      : DEFAULT_PROFILE_PICTURE
                                  }
                                  onError={(e) =>
                                    (e.target.src = DEFAULT_PROFILE_PICTURE)
                                  }
                                />
                              </Media>
                              <Media body className="pl-2">
                                <Media heading className="m-0">
                                  {selectedGroup?.name
                                    ? capitalize(selectedGroup.name)
                                    : "Loading..."}
                                </Media>
                                {!selectedGroup?.isMain &&
                                selectedGroup?.startDate ? (
                                  <span
                                    className="fs-13"
                                    title="Check In - Check Out"
                                  >
                                    {formatDate(selectedGroup.startDate)}
                                    {selectedGroup?.endDate ? (
                                      <>
                                        {" "}
                                        - {formatDate(selectedGroup.endDate)}
                                      </>
                                    ) : null}
                                  </span>
                                ) : null}
                              </Media>
                            </Media>
                          </div>
                        </Col>

                        {selectedGroup ? (
                          <Col xs="6">
                            <div className="d-flex justify-content-end">
                              <Button
                                color="transparent"
                                title={`Generate QR Code of ${
                                  selectedGroup.name
                                    ? capitalize(selectedGroup.name)
                                    : ""
                                }`}
                                className="fs-22"
                                onClick={() => _generateQRCode()}
                              >
                                <i className="fa fa-qrcode text-blue" />
                              </Button>

                              {!selectedGroup.isMain ? (
                                <>
                                  <Button
                                    color="transparent"
                                    title={`Update ${
                                      selectedGroup.name
                                        ? capitalize(selectedGroup.name)
                                        : ""
                                    }`}
                                    className="fs-22"
                                    onClick={() => _editGroupDetail()}
                                  >
                                    <i className="fas fa-pencil-alt text-blue" />
                                  </Button>
                                  <Button
                                    color="transparent"
                                    title={`Archive ${
                                      selectedGroup.name
                                        ? capitalize(selectedGroup.name)
                                        : ""
                                    }`}
                                    className="fs-22"
                                    onClick={() => _onArchiveChatGroupAlert()}
                                  >
                                    <i className="fa fa-archive" />
                                  </Button>
                                </>
                              ) : null}
                            </div>
                          </Col>
                        ) : null}
                      </Row>
                    </CardHeader>

                    {/* Chat Box Component */}
                    <GroupChatBoxComponent
                      selectedGroup={selectedGroup}
                      user={userCredential?.user}
                    />
                  </Card>
                </div>
              </Col>
            </Row>
          </div>

          {/* ==================== */}
          <AddChatGroupModal
            isOpen={addChatGroupModal.isOpen}
            data={addChatGroupModal.data}
            toggle={() => _toggleAddChatGroupModal()}
            getAllGroups={() => _getAllGroups()}
          />

          <QRCodeModal
            isOpen={qrCodeModal.isOpen}
            data={qrCodeModal.data}
            resortDetails={resortDetails}
            toggle={() => _toggleQRCodeModal()}
          />

          <GroupChatMemberModal
            isOpen={groupChatMemberModal.isOpen}
            data={groupChatMemberModal.data}
            resortDetails={resortDetails}
            selectedGroup={selectedGroup}
            timeZone={timeZone}
            removeMemberFromList={(groupId, memberId) =>
              _removeMemberFromList(groupId, memberId)
            }
            toggle={() => _toggleGroupChatMemberModal()}
          />
        </Container>
      </div>
    </>
  );
};

export default GroupChat;
