import KpiDashboard from "./views/protectedPages/KpiDashboard";
import TeamMembers from "./views/protectedPages/TeamMembers";
import Resorts from "./views/protectedPages/Resorts";
import Vacationers from "./views/protectedPages/Vacationers";
import Outings from "./views/protectedPages/Outings";

// ====== icons ========
import KpiDashboardIcon from "./assets/img/KpiDashboardIcon.svg";
import TeamIcon from "./assets/img/team.svg";
import ResortDetailsIcon from "./assets/img/resort_details_icon.svg";
import VacationersIcon from "./assets/img/vacationers.svg";
import OutingsIcon from "./assets/img/outings.svg";

var routes = [
  {
    path: "/dashboard",
    layout: "",
    name: "Dashboard",
    icon: "fa fa-users",
    imageIcon: KpiDashboardIcon,
    ImageSize: 30,
    component: KpiDashboard,
    permissionName: "manageresort",
    isCheckPermission: true,
  },
  {
    path: "/team-members",
    layout: "",
    name: "Team Members",
    icon: "fa fa-users",
    imageIcon: TeamIcon,
    component: TeamMembers,
  },
  {
    path: "/resorts",
    layout: "",
    name: "Properties",
    icon: "fa fa-user-circle",
    imageIcon: ResortDetailsIcon,
    component: Resorts,
  },
  {
    path: "/vacationers",
    layout: "",
    name: "Vacationers",
    icon: "fa fa-user-circle",
    imageIcon: VacationersIcon,
    component: Vacationers,
  },
  {
    path: "/outings",
    layout: "",
    name: "Outings",
    icon: "fa fa-user-circle",
    imageIcon: OutingsIcon,
    component: Outings,
  },
];
export default routes;
