import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Label,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";
import {
  deepClone,
  showToast,
  formatTime,
  uploadFileOnServer,
} from "../../helper-methods";
import ReactDatetime from "react-datetime";
import { getResortDetails, updateResortDetails } from "../../http/http-calls";
import { countryCodes } from "../../config/country-codes";
import { StatesConfig } from "../../config/states";

class ResortDetails extends Component {
  state = {
    resort: null,
    formFields: {
      name: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      numberOfRooms: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      resortAddress: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      country: {
        value: "US",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      state: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      city: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      zipcode: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      checkInTime: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      checkOutTime: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      description: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: false,
      },
      logo: {
        value: {
          uploadData: null,
          previewBlob: null,
          type: null,
          uploadUrl: null,
        },
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      photos: {
        value: [],
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
    },
    loading: false,
  };

  _setForm = () => {
    try {
      const { resort, formFields } = deepClone(this.state);

      if (resort) {
        formFields.name.value = resort.name || "";
        formFields.numberOfRooms.value = resort.numberOfRooms
          ? resort.numberOfRooms.toString()
          : "";
        formFields.checkInTime.value = resort.checkInTime || "";
        formFields.checkOutTime.value = resort.checkOutTime || "";

        formFields.resortAddress.value = resort.address?.street || "";
        formFields.city.value = resort.address?.city || "";
        formFields.country.value = resort.address?.country
          ? resort.address.country
          : "US";
        formFields.state.value = resort.address?.state || "";
        formFields.zipcode.value = resort.address?.zip || "";

        formFields.description.value = resort.description || "";

        formFields.logo.value = {
          uploadData: null,
          type: "image",
          previewBlob: null,
          isCoverPhoto: true,
          uploadUrl: resort.logo ? resort.logo : null,
        };

        formFields.photos.value = [];

        if (resort.coverImage) {
          formFields.photos.value.push({
            uploadData: null,
            type: "image",
            previewBlob: null,
            isCoverPhoto: true,
            uploadUrl: resort.coverImage,
          });
        }

        if (resort.photos?.length) {
          resort.photos.forEach((photoUrl) =>
            formFields.photos.value.push({
              uploadData: null,
              type: "image",
              previewBlob: null,
              uploadUrl: photoUrl,
            })
          );
        }

        this.setState({ formFields, loading: false });
      } else {
        this.setState({ loading: false });
        showToast("Something went wrong, Try again after sometime.");
      }
    } catch (error) {
      console.log("error>>", error);
      this.setState({ loading: false });
      showToast("Something went wrong, Try again after sometime.");
    }
  };

  _getResortDetails = (resortId) => {
    this.setState({ loading: true });

    getResortDetails(resortId)
      .then((res) => {
        this.setState({ resort: res.resort }, () => this._setForm());
      })
      .catch((error) => {
        console.log("error>>", error);
        this.setState({ loading: false });
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
        this.props.history?.goBack();
      });
  };

  _updateResortDetails = (payload) => {

    this.setState({ loading: true });

    const resortId = this.props.match?.params?.resortId;

    updateResortDetails(payload, resortId)
      .then((res) => {
        showToast("Updated successfully", "success");

        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log("error>>", error);
        this.setState({ loading: false });
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  componentDidMount = () => {
    const resortId = this.props.match?.params?.resortId;

    this._getResortDetails(resortId);
  };

  _validateFormFields = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidationRequired) {
          switch (key) {
            case "name":
            case "numberOfRooms":
            case "resortAddress":
            case "country":
            case "state":
            case "city":
            case "zipcode": {
              if (formFields[key].value?.trim().length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "checkInTime": {
              if (formFields[key].value?.length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;

                // condition in which checkIn time less than checkout time
                // if (formFields.checkOutTime.value?.length) {
                //   if (
                //     new Date(formFields[key].value) >
                //     new Date(formFields.checkOutTime.value)
                //   ) {
                //     formFields[key].error = "*Invalid time";
                //     isFormValid = false;
                //   } else {
                //     formFields[key].error = null;
                //     formFields[key].isDirty = false;
                //   }
                // } else {
                //   formFields[key].error = null;
                //   formFields[key].isDirty = false;
                // }
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "checkOutTime": {
              if (formFields[key].value?.length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;

                // condition in which checkIn time less than checkout time
                // if (formFields.checkInTime.value?.length) {
                //   if (
                //     new Date(formFields[key].value) <
                //     new Date(formFields.checkInTime.value)
                //   ) {
                //     formFields[key].error = "*Invalid time";
                //     isFormValid = false;
                //   } else {
                //     formFields[key].error = null;
                //     formFields[key].isDirty = false;
                //   }
                // } else {
                //   formFields[key].error = null;
                //   formFields[key].isDirty = false;
                // }
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "logo": {
              if (
                formFields[key].value?.uploadData ||
                formFields[key].value?.uploadUrl
              ) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "photos": {
              if (formFields[key].value?.length) {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              } else {
                formFields[key].error = "*Atleast one image is required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => resolve(isFormValid));
    });
  };

  // key = logo or photos
  _updateLocalPhoto = (e, key = "photos") => {
    try {
      if (e?.target?.files?.length) {
        const { formFields } = deepClone(this.state);
        for (let file of e.target.files) {
          const fileType = file.type.split("/")[0];
          if (fileType === "image") {
            const previewBlob = URL.createObjectURL(file);

            switch (key) {
              case "logo": {
                formFields[key].value = {
                  uploadData: file,
                  previewBlob,
                  type: fileType,
                  forKeyName: key,
                  uploadUrl: null,
                };
                break;
              }
              case "photos": {
                if (formFields[key].value.length) {
                  formFields[key].value.push({
                    uploadData: file,
                    previewBlob,
                    type: fileType,
                    isCoverPhoto: false,
                    forKeyName: key,
                    uploadUrl: null,
                  });
                } else {
                  formFields[key].value.push({
                    uploadData: file,
                    previewBlob,
                    type: fileType,
                    isCoverPhoto: true,
                    forKeyName: "coverImage",
                    uploadUrl: null,
                  });
                }
                break;
              }
              default:
            }
          } else {
            showToast("Only image file is allowed", "error");
            continue;
          }
        }

        formFields[key].isDirty = true;

        this.setState({ formFields }, () => this._validateFormFields());
      }
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  _makeCoverPhoto = (index) => {
    const { formFields } = deepClone(this.state);
    formFields.photos.value.forEach((photo) => {
      photo.isCoverPhoto = false;
      photo.forKeyName = "photos";
    });
    formFields.photos.value[index]["isCoverPhoto"] = true;
    formFields.photos.value[index]["forKeyName"] = "coverImage";
    this.setState({ formFields });
  };

  // key = logo or photos
  _removePhoto = (index, key = "photos") => {
    const { formFields } = deepClone(this.state);
    switch (key) {
      case "logo": {
        formFields[key].value = {
          uploadData: null,
          previewBlob: null,
          type: null,
          uploadUrl: null,
        };
        break;
      }
      case "photos": {
        formFields[key].value.splice(index, 1);
        break;
      }
      default:
    }
    this.setState({ formFields });
  };

  _onChangeFormFields = (key, value) => {
    if (key === "numberOfRooms" && (isNaN(value) || value.includes("."))) {
      return;
    }

    const { formFields } = deepClone(this.state);
    if (key === "checkInTime" || key === "checkOutTime") {
      formFields[key].value = value ? new Date(value).toISOString() : "";
    } else {
      formFields[key].value = value;
    }
    formFields[key].isDirty = true;
    if (key === "country") {
      formFields.state.value = "";
      formFields.state.error = null;
      formFields.state.isDirty = false;
    }
    this.setState({ formFields }, () => this._validateFormFields());
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = deepClone(this.state);
      Object.keys(formFields).forEach((key) => {
        formFields[key].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _onSubmitForm = async (event) => {
    try {
      if (event) event.preventDefault();

      await this._markAllFieldDirty();

      const isFormValid = await this._validateFormFields();

      if (isFormValid) {
        this.setState({ loading: true });

        const { formFields } = deepClone(this.state);

        const payload = {
          name: formFields.name.value.trim(),
          numberOfRooms: formFields.numberOfRooms.value.trim(),
          checkInTime: formFields.checkInTime.value.trim(),
          checkOutTime: formFields.checkOutTime.value.trim(),
          address: {
            street: formFields.resortAddress.value.trim(),
            city: formFields.city.value.trim(),
            country: formFields.country.value.trim(),
            state: formFields.state.value.trim(),
            zip: formFields.zipcode.value.trim(),
          },
          description: formFields.description.value.trim(),
          photos: [],
          coverImage: "",
          logo: "",
        };
console.log(payload,"payload")
        if (formFields.logo.value.uploadData) {
          formFields.logo.value["forKeyName"] = "logo";
          formFields.photos.value.push({ ...formFields.logo.value });
        } else if (formFields.logo.value.uploadUrl) {
          payload["logo"] = formFields.logo.value.uploadUrl;
        }

        if (
          formFields.photos.value.length &&
          formFields.photos.value.some((photo) => photo.uploadData)
        ) {
          const uploadDataFilter = formFields.photos.value.filter(
            (photo) => photo.uploadData
          );

          const response = await uploadFileOnServer(uploadDataFilter);

          response.forEach((res) => {
            switch (res.forKeyName) {
              case "coverImage": {
                payload["coverImage"] = res.url;
                break;
              }
              case "photos": {
                payload["photos"].push(res.url);
                break;
              }
              case "logo": {
                payload["logo"] = res.url;
                break;
              }
              default:
            }
          });
        }

        if (
          formFields.photos.value.length &&
          formFields.photos.value.some((photo) => photo.uploadUrl)
        ) {
          formFields.photos.value.forEach((photo) => {
            if (!photo.isCoverPhoto && photo.uploadUrl)
              payload.photos.push(photo.uploadUrl);
            else if (photo.isCoverPhoto && photo.uploadUrl)
              payload["coverImage"] = photo.uploadUrl;
          });
        }

        if (
          formFields.photos.value.length &&
          formFields.photos.value.some((photo) => photo.uploadData)
        ) {
          formFields.photos.value = [];

          if (payload.coverImage?.length) {
            formFields.photos.value.push({
              uploadData: null,
              type: "image",
              previewBlob: null,
              isCoverPhoto: true,
              uploadUrl: payload["coverImage"],
            });
          }

          if (payload.photos?.length) {
            payload.photos.forEach((photoUrl) =>
              formFields.photos.value.push({
                uploadData: null,
                type: "image",
                previewBlob: null,
                uploadUrl: photoUrl,
              })
            );
          }
        }

        if (payload.logo?.length && formFields.logo.value?.uploadData) {
          formFields.logo.value = {
            uploadData: null,
            type: "image",
            previewBlob: null,
            uploadUrl: payload["logo"],
          };
        }

        this.setState({ formFields }, () => this._updateResortDetails(payload));
      }
    } catch (error) {
      console.log("error>>", error);
      this.setState({ loading: false });
      showToast(
        error?.reason?.length
          ? error.reason
          : "Something went wrong, Try again after sometime.",
        "error"
      );
    }
  };

  render() {
    const { resort, formFields, loading } = this.state;
 
    return (
      <>
        <div className="content">
          <Container fluid className="mobilePadding-0">
            <div className="page_Inner_header">
              <Row className="align-items-center">
                <Col xl="5" md="4">
                  <div className="page_title">
                    <Button
                      color="link"
                      className="pl-0 pr-2"
                      onClick={() => this.props.history.goBack()}
                    >
                      <i className="fa fa-chevron-left" />
                    </Button>
                    <h1 className="space_remove pr-2">Resort Details</h1>
                    <Button
                      className="btn btn-primary mr-2"
                      onClick={() =>
                        this.props.history.push(`/settings/${resort._id}`)
                      }
                    >
                      Settings
                    </Button>
                    {loading ? <Spinner /> : null}
                  </div>
                </Col>
              </Row>
            </div>

            {/* resort */}
            <Card body className="mt-lg-4 mt-2">
              <div className="innerForm reactTimeBorder">
                <Row>
                  <Col md="6">
                    <div className="amenity-location profile_center">
                      <div className="cover_content text-center mt-2">
                        <h1>Upload Logo</h1>
                        <p>
                          Picture Should be in the standard format PNG, JPG and
                          no more than 5MB
                        </p>
                      </div>
                      <div className="input-gallery">
                        <div className="input-gallery-col largesize plus">
                          {formFields.logo.value.previewBlob ||
                          formFields.logo.value.uploadUrl ? (
                            <>
                              <div className="group_image">
                                <img
                                  src={
                                    formFields.logo.value.previewBlob ||
                                    formFields.logo.value.uploadUrl
                                  }
                                  alt="cover"
                                  loading="lazy"
                                />
                              </div>
                              <Label className="replace_map">
                                <span className="replace_map mt-1">
                                  <Input
                                    type="file"
                                    value=""
                                    className="d-none"
                                    disabled={loading}
                                    accept="image/*"
                                    onChange={(e) =>
                                      this._updateLocalPhoto(e, "logo")
                                    }
                                  />
                                  Replace
                                </span>
                              </Label>
                            </>
                          ) : (
                            <div className="input-gallery-img">
                              <Label className="mb-0">
                                <Input
                                  type="file"
                                  value=""
                                  disabled={loading}
                                  className="d-none"
                                  accept="image/*"
                                  onChange={(e) =>
                                    this._updateLocalPhoto(e, "logo")
                                  }
                                />
                                <img
                                  src={
                                    require("../../assets/img/plus.png").default
                                  }
                                  alt="category"
                                  className="img-fluid"
                                  loading="lazy"
                                />
                              </Label>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {formFields.logo.error ? (
                      <div className="form-error">{formFields.logo.error}</div>
                    ) : null}
                  </Col>

                  <Col md="6">
                    <FormGroup className="d-flex align-items-center">
                      <div className="coverPhoto">
                        {/* when set cover image hide this icon */}
                        {formFields.photos.value.length ? (
                          formFields.photos.value.map(
                            (photo) =>
                              photo.isCoverPhoto &&
                              (photo.previewBlob || photo.uploadUrl) && (
                                <img
                                  src={photo.previewBlob || photo.uploadUrl}
                                  alt="cover"
                                  key={photo.previewBlob || photo.uploadUrl}
                                  loading="lazy"
                                />
                              )
                          )
                        ) : (
                          <i className="fa fa-picture-o" />
                        )}
                      </div>
                      <div className="cover_content">
                        <h1>Upload Photos</h1>
                        <p>
                          Picture Should be in the standard format PNG, JPG and
                          no more than 5MB
                        </p>
                      </div>
                    </FormGroup>
                    <div className="input-gallery d-flex flex-wrap mb-2">
                      <div className="input-gallery-col plus">
                        <div className="input-gallery-img">
                          <Label className="mb-0">
                            <Input
                              type="file"
                              value=""
                              multiple
                              disabled={loading}
                              accept="image/*"
                              onChange={(e) =>
                                this._updateLocalPhoto(e, "photos")
                              }
                            />
                            <img
                              src={require("../../assets/img/plus.png").default}
                              alt="category"
                              className="img-fluid"
                              loading="lazy"
                            />
                          </Label>
                        </div>
                      </div>

                      {formFields.photos.value.length
                        ? formFields.photos.value.map(
                            (photo, index) =>
                              (photo.previewBlob || photo.uploadUrl) && (
                                <div
                                  className={`input-gallery-col ${
                                    photo.isCoverPhoto ? "selected" : ""
                                  }`}
                                  key={photo.previewBlob || photo.uploadUrl}
                                >
                                  <div className="input-gallery-img">
                                    <img
                                      src={photo.previewBlob || photo.uploadUrl}
                                      alt="cover"
                                      loading="lazy"
                                    />
                                    {!photo.isCoverPhoto && !loading ? (
                                      <i
                                        className="fa fa-close"
                                        onClick={() =>
                                          this._removePhoto(index, "photos")
                                        }
                                      />
                                    ) : null}
                                  </div>
                                  {photo.isCoverPhoto ? (
                                    <span>Cover Photo</span>
                                  ) : !loading ? (
                                    <span
                                      onClick={() =>
                                        this._makeCoverPhoto(index)
                                      }
                                    >
                                      Make Cover
                                    </span>
                                  ) : null}
                                </div>
                              )
                          )
                        : null}

                      {formFields.photos.error ? (
                        <div className="form-error">
                          {formFields.photos.error}
                        </div>
                      ) : null}
                    </div>
                  </Col>

                  <Col md="6" className="pt-3">
                    <FormGroup>
                      <Label>Resort Name</Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/hotel.svg").default}
                            alt="user"
                            className="img-fluid w-20"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder="Enter your resort name"
                          autoComplete="off"
                          disabled={loading}
                          value={formFields.name.value}
                          onChange={(e) =>
                            this._onChangeFormFields("name", e.target.value)
                          }
                        />
                      </InputGroup>
                      {formFields.name.error ? (
                        <div className="form-error">
                          {formFields.name.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="6" className="pt-3">
                    <FormGroup>
                      <Label>Number of Rooms</Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/hotel.svg").default}
                            alt="user"
                            className="img-fluid w-20"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder="Enter the number of rooms in your resorts"
                          autoComplete="off"
                          disabled={loading}
                          value={formFields.numberOfRooms.value}
                          onChange={(e) =>
                            this._onChangeFormFields(
                              "numberOfRooms",
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                      {formFields.numberOfRooms.error ? (
                        <div className="form-error">
                          {formFields.numberOfRooms.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label>Check In Time</Label>
                      <InputGroup>
                        <InputGroupText className="check-time">
                          <img
                            src={
                              require("../../assets/img/calendar.svg").default
                            }
                            alt="user"
                            className="img-fluid w-20"
                            loading="lazy"
                          />
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Select check in time",
                              value: formatTime(formFields.checkInTime.value),
                              disabled: loading,
                            }}
                            value={
                              formFields.checkInTime.value
                                ? new Date(formFields.checkInTime.value)
                                : ""
                            }
                            onChange={(e) =>
                              this._onChangeFormFields("checkInTime", e._d)
                            }
                            timeConstraints={{
                              minutes: {
                                step: 15,
                              },
                            }}
                            dateFormat={false}
                            timeFormat={true}
                          />
                        </InputGroupText>
                      </InputGroup>
                      {formFields.checkInTime.error ? (
                        <div className="form-error">
                          {formFields.checkInTime.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label>Check Out Time</Label>
                      <InputGroup>
                        <InputGroupText className="check-time">
                          <img
                            src={
                              require("../../assets/img/calendar.svg").default
                            }
                            alt="user"
                            className="img-fluid w-20"
                            loading="lazy"
                          />

                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Select check out time",

                              value: formatTime(formFields.checkOutTime.value),
                              disabled: loading,
                            }}
                            value={
                              formFields.checkOutTime.value
                                ? new Date(formFields.checkOutTime.value)
                                : ""
                            }
                            onChange={(e) =>
                              this._onChangeFormFields("checkOutTime", e._d)
                            }
                            timeConstraints={{
                              minutes: {
                                step: 15,
                              },
                            }}
                            dateFormat={false}
                            timeFormat={true}
                          />
                        </InputGroupText>
                      </InputGroup>
                      {formFields.checkOutTime.error ? (
                        <div className="form-error">
                          {formFields.checkOutTime.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="6" className="pt-3">
                    <FormGroup>
                      <Label>Resort Address</Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={
                              require("../../assets/img/location.svg").default
                            }
                            alt="user"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder="Enter your resort address"
                          autoComplete="off"
                          value={formFields.resortAddress.value}
                          disabled={loading}
                          onChange={(e) =>
                            this._onChangeFormFields(
                              "resortAddress",
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                      {formFields.resortAddress.error ? (
                        <div className="form-error">
                          {formFields.resortAddress.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="6" className="pt-3">
                    <FormGroup>
                      <Label>City</Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/zip.svg").default}
                            alt="city"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder="Enter your city"
                          disabled={loading}
                          value={formFields.city.value}
                          onChange={(e) =>
                            this._onChangeFormFields("city", e.target.value)
                          }
                        />
                      </InputGroup>
                      {formFields.city.error ? (
                        <div className="form-error">
                          {formFields.city.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="4" className="pt-3">
                    <FormGroup>
                      <Label>Country</Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/city.svg").default}
                            alt="State"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>

                        <Input
                          type="select"
                          value={formFields.country.value}
                          disabled={loading}
                          onChange={(e) =>
                            this._onChangeFormFields("country", e.target.value)
                          }
                        >
                          {countryCodes.map((each) => (
                            <option key={each.code} value={each.code}>
                              {each.name}
                            </option>
                          ))}
                        </Input>
                      </InputGroup>
                      {formFields.country.error ? (
                        <div className="form-error">
                          {formFields.country.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="4" className="pt-3">
                    <FormGroup>
                      <Label>State</Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/city.svg").default}
                            alt="city"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>

                        {formFields.country.value &&
                        (formFields.country.value === "IN" ||
                          formFields.country.value === "US" ||
                          formFields.country.value === "MX") ? (
                          <Input
                            type="select"
                            name="state"
                            disabled={loading}
                            onChange={(e) =>
                              this._onChangeFormFields("state", e.target.value)
                            }
                            value={formFields.state.value}
                          >
                            <option value="">Select State</option>
                            {StatesConfig[formFields.country.value].map(
                              (stateCode, stateIndex) => (
                                <option key={stateIndex} value={stateCode.code}>
                                  {stateCode.name}
                                </option>
                              )
                            )}
                          </Input>
                        ) : (
                          <Input
                            type="text"
                            placeholder="Enter your state"
                            disabled={loading}
                            name="state"
                            value={formFields.state.value}
                            onChange={(e) =>
                              this._onChangeFormFields("state", e.target.value)
                            }
                          />
                        )}
                      </InputGroup>
                      {formFields.state.error ? (
                        <div className="form-error">
                          {formFields.state.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="4" className="pt-3">
                    <FormGroup>
                      <Label>Zipcode</Label>
                      <InputGroup>
                        <InputGroupText>
                          <img
                            src={require("../../assets/img/city.svg").default}
                            alt="State"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder="Enter your zipcode"
                          value={formFields.zipcode.value}
                          disabled={loading}
                          onChange={(e) =>
                            this._onChangeFormFields("zipcode", e.target.value)
                          }
                        />
                      </InputGroup>
                      {formFields.zipcode.error ? (
                        <div className="form-error">
                          {formFields.zipcode.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="12" className="pt-3">
                    <FormGroup className="commentArea">
                      <Label>Description</Label>
                      <InputGroup className="textarea">
                        <InputGroupText>
                          <img
                            src={
                              require("../../assets/img/comment.svg").default
                            }
                            alt="user"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </InputGroupText>
                        <Input
                          type="textarea"
                          placeholder="Enter the description"
                          name="description"
                          value={formFields.description.value}
                          onChange={(e) =>
                            this._onChangeFormFields(
                              "description",
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                      {formFields.description.error ? (
                        <div className="form-error">
                          {formFields.description.error}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col md="12" className="border-top my-4 py-4">
                    <FormGroup className="text-center mb-4">
                      <Button
                        className="btn-primary mw-130 fw-bold"
                        disabled={loading}
                        onClick={(event) => this._onSubmitForm(event)}
                      >
                        {loading ? (
                          <i className="fa fa-spinner fa-spin mr-1" />
                        ) : null}
                        Save
                      </Button>

                      <Button
                        color="primary"
                        className="btn-cancel mw-130 ml-3 fw-bold"
                        disabled={loading}
                        onClick={() => this._setForm()}
                      >
                        Reset
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}

export default ResortDetails;
