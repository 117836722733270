import React from "react";
import { Table, Modal, ModalHeader, ModalBody } from "reactstrap";

const VacationerOutingModal = ({ isOpen, toggle }) => {
  const _closeModal = () => {
    toggle();
  };
  return (
    <Modal
      className="vacationerOutingModal modal-large"
      modalTransition={{ timeout: 100 }}
      isOpen={isOpen}
      size="xl"
      toggle={() => _closeModal()}
      centered
    >
      <ModalHeader toggle={() => _closeModal()}>Outings</ModalHeader>
      <ModalBody>
        {/* ======== Table ======== */}
        <Table responsive className="m-0">
          <thead>
            <tr>
              <th>Date</th>
              <th>Outing</th>
              <th>Amount</th>
              <th>PAX</th>
              <th>Discount</th>
              <th>Location</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>12 Dec</td>
              <td>Corol</td>
              <td>$100</td>
              <td>4</td>
              <td>10%</td>
              <td>Calo</td>
              <td>Completed</td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};

export default VacationerOutingModal;
