import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { errorHandler } from "../../helper-methods";
import { getEButlerGraphData } from "../../http/http-calls";
import LoadingSkeleton from "react-loading-skeleton";

const EButlerGraph = ({ payload }) => {
  // #6.1
  const [eConciergeServiceBarGraph, setEConciergeServiceBarGraph] = useState({
    barOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        stacked: true,
        type: "bar",
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: (value) => value.toFixed(0),
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#008ffb", "#FF1654", "#feb019", "#F1512E", "#255aee"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    barSeries: [
      {
        name: "Front Desk",
        data: [],
      },
      {
        name: "House Keeping",
        data: [],
      },
      {
        name: "Concierge Desk",
        data: [],
      },
      {
        name: "In Room Dining",
        data: [],
      },
    ],
  });
  // #6.2
  const [eConciergeServiceGraph, setEConciergeServiceGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: 100,
        labels: {
          formatter: (value) => value.toFixed(0),
          style: {
            colors: "#FF1654",
          },
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#FF1654"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: "eButler Service",
        data: [],
      },
    ],
  });
  // #6.3
  const [resortServiceBookingsGraph, setResortServiceBookingsGraph] = useState({
    lineOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        // tickAmount: 4,
        // min: 0,
        // max: 100,
        labels: {
          formatter: (value) => value.toFixed(0),
          style: {
            colors: "#247BA0",
          },
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#247BA0"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    lineSeries: [
      {
        name: "Booking Resort Services",
        data: [],
      },
    ],
  });

  const [loading, setLoading] = useState(false);

  const _setGuestUsingEConciergeGraph = (guestUsingEConcierge = []) => {
    const newEConciergeServiceBarGraph = { ...eConciergeServiceBarGraph };

    newEConciergeServiceBarGraph.barOptions.xaxis.categories = [];
    newEConciergeServiceBarGraph.barSeries[0].data = []; // # frontDesk
    newEConciergeServiceBarGraph.barSeries[1].data = []; // # houseKeeping
    newEConciergeServiceBarGraph.barSeries[2].data = []; // # conciergeDesk
    newEConciergeServiceBarGraph.barSeries[3].data = []; // # inRoomDining

    guestUsingEConcierge.forEach((each) => {
      newEConciergeServiceBarGraph.barOptions.xaxis.categories.push(
        `Week ${each._id.split("-")[1]}`
      );
      newEConciergeServiceBarGraph.barSeries[0].data.push(
        each.details.find((each) => each.serviceType === "frontDesk")?.count ||
          0
      );
      newEConciergeServiceBarGraph.barSeries[1].data.push(
        each.details.find((each) => each.serviceType === "houseKeeping")
          ?.count || 0
      );
      newEConciergeServiceBarGraph.barSeries[2].data.push(
        each.details.find(
          (each) =>
            each.serviceType === "conciergeDesk" ||
            each.serviceType === "concierge"
        )?.count || 0
      );
      newEConciergeServiceBarGraph.barSeries[3].data.push(
        each.details.find((each) => each.serviceType === "inRoomDining")
          ?.count || 0
      );
    });

    setEConciergeServiceBarGraph(newEConciergeServiceBarGraph);
  };

  const _setActiveUserBookingEConcierge = (data = []) => {
    const newEConciergeServiceGraph = { ...eConciergeServiceGraph };
    newEConciergeServiceGraph.lineOptions.xaxis.categories = [];
    newEConciergeServiceGraph.lineSeries[0].data = [];
    data.forEach((each) => {
      newEConciergeServiceGraph.lineOptions.xaxis.categories.push(
        `Week ${each._id.split("-")[1]}`
      );
      newEConciergeServiceGraph.lineSeries[0].data.push(each.count);
    });
    setEConciergeServiceGraph(newEConciergeServiceGraph);
  };

  const _setBookingResortServices = (data = []) => {
    const newResortServiceBookingsGraph = { ...resortServiceBookingsGraph };
    newResortServiceBookingsGraph.lineOptions.xaxis.categories = [];
    newResortServiceBookingsGraph.lineSeries[0].data = [];
    data.forEach((each) => {
      newResortServiceBookingsGraph.lineOptions.xaxis.categories.push(
        `Week ${each._id.split("-")[1]}`
      );
      newResortServiceBookingsGraph.lineSeries[0].data.push(each.count);
    });
    setResortServiceBookingsGraph(newResortServiceBookingsGraph);
  };

  const _getEButlerGraphData = (payload) => {
    setLoading(true);
    getEButlerGraphData(payload)
      .then((res) => {
        if (res.result) {
          _setGuestUsingEConciergeGraph(res.result.guestUsingEConcierge);
          _setActiveUserBookingEConcierge(
            res.result.activeUserBookingEConcierge
          );
          _setBookingResortServices(res.result.bookingResortServices);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  useEffect(() => {
    if (payload?.startDate && payload?.endDate && payload?.groupByUnit)
      _getEButlerGraphData(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.startDate, payload?.endDate, payload?.groupByUnit]);

  return (
    <div className="graphWrapper">
      <div className="graphTitleWrap">
        <h1>eButler</h1>
      </div>

      <p className="subHeading"># Guest Using eButler</p>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {eConciergeServiceBarGraph.barSeries[0]?.data?.length ? (
            <Chart
              options={eConciergeServiceBarGraph.barOptions}
              series={eConciergeServiceBarGraph.barSeries}
              type="bar"
              width="100%"
              height="270"
              className="chartWrap"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}

      <p className="subHeading" style={{ marginTop: 45 }}>
        Active Users Booking eButler
      </p>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {eConciergeServiceGraph.lineSeries[0]?.data?.length ? (
            <Chart
              options={eConciergeServiceGraph.lineOptions}
              series={eConciergeServiceGraph.lineSeries}
              type="area"
              width="100%"
              height="270"
              className="chartWrap"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}

      <p className="subHeading" style={{ marginTop: 45 }}>
        Booking Resort Services
      </p>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {resortServiceBookingsGraph.lineSeries[0]?.data?.length ? (
            <Chart
              options={resortServiceBookingsGraph.lineOptions}
              series={resortServiceBookingsGraph.lineSeries}
              type="area"
              width="100%"
              height="270"
              className="chartWrap"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}
    </div>
  );
};

export default EButlerGraph;
