import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Label,
  FormGroup,
  Input,
  Row,
  Media,
  Table,
  Spinner,
  List,
  CardBody,
} from "reactstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import RequestChatBoxComponent from "../../components/RequestChatBoxComponent";
import {
  assignToComplimentaryServicesConfig,
  requestStatusConfig,
} from "../../config/helper-config";
import {
  capitalize,
  extractQueryParams,
  formatDate,
  formatDateAndTime,
  formatDateAndTimeForApi,
  formatTime,
  getTimezoneDate,
  showToast,
} from "../../helper-methods";
import {
  getAllGroups,
  getAllRequests,
  getAmenitiesList,
  getRequestById,
  updateRequest,
  getAllAssigneeList,
} from "../../http/http-calls";
import ReactPaginate from "react-paginate";
import ReactDatetime from "react-datetime";
import { useSelector } from "react-redux";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import PreviousRequestModal from "../../components/Modals/previous-request-modal";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";

let searchInterval;

const GuestRequests = () => {
  const history = useHistory();

  const [assigneeArray, setAssigneeArray] = useState([]);
  const [amenityArray, setAmenityArray] = useState([]);
  const [groups, setGroups] = useState([]);

  const [activeResortId, setActiveResortId] = useState(null);
  const [timeZone, setTimeZone] = useState(null);

  const [requests, setRequests] = useState([]);

  const userCredential = useSelector((state) => state["userCredential"]);

  const [requestsCount, setRequestsCount] = useState(0);
  const [getAllRequestsPayload, setGetAllRequestsPayload] = useState({
    filters: {},
    search: "",
    skip: 0,
    pageNumber: 0,
    limit: 10,
    resortId: null,
  });
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [filters, setFilters] = useState({
    subCategoryFilter: "",
    status: "",
    _group: "",
    assignedTo: "",
  });

  const [isEditRequestBoxFields, setIsEditRequestBoxFields] = useState(false);

  const [requestBoxFields, setRequestBoxFields] = useState({
    time: "",
    comment: "",
  });
  const [requestBoxLoading, setRequestBoxLoading] = useState(false);

  const [isShowFloatingChat, setIsShowFloatingChat] = useState(false);
  const [isMinimizeFloatingChat, setIsMinimizeFloatingChat] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const [previousRequestModal, setPreviousRequestModal] = useState({
    isOpen: false,
    data: null,
  });

  const _togglePreviousRequestModal = (isOpen = false, data = null) => {
    setPreviousRequestModal({
      isOpen,
      data,
    });
  };

  const _getAllAssigneeList = (resortid) => {
    getAllAssigneeList({ resortid })
      .then((res) => {
        setAssigneeArray(res?.assignees?.length ? res.assignees : []);
      })
      .catch((error) => {
        console.log("error>>", error);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _getAllRequests = (payload) => {
    setLoading(true);
    getAllRequests(payload)
      .then((res) => {
        setRequests(res.bookings?.length ? res.bookings : []);
        setRequestsCount(res.count || 0);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
        setStatusLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        setSearchLoading(false);
        setFiltersLoading(false);
        setStatusLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _onPageChange = (pageNumber = 0) => {
    const newGetAllRequestsPayload = { ...getAllRequestsPayload };
    newGetAllRequestsPayload["skip"] =
      pageNumber * newGetAllRequestsPayload["limit"];
    newGetAllRequestsPayload["pageNumber"] = pageNumber;
    setGetAllRequestsPayload(newGetAllRequestsPayload);
    _getAllRequests(newGetAllRequestsPayload);
  };

  const _toggleEditRequestBox = (isEdit = false, newSelectedRequest) => {
    if (newSelectedRequest) {
      const newRequestBoxFields = { ...requestBoxFields };

      if (isEdit) {
        newRequestBoxFields["time"] = newSelectedRequest?.selectedTime
          ? getTimezoneDate(newSelectedRequest.selectedTime, timeZone)
          : newSelectedRequest?.startTimeStamp
          ? getTimezoneDate(newSelectedRequest.startTimeStamp, timeZone)
          : "";

        newRequestBoxFields["comment"] = newSelectedRequest?.comments || "";
      } else {
        newRequestBoxFields["time"] = newSelectedRequest?.selectedTime
          ? getTimezoneDate(newSelectedRequest.selectedTime, timeZone)
          : newSelectedRequest?.startTimeStamp
          ? getTimezoneDate(newSelectedRequest.startTimeStamp, timeZone)
          : "";

        newRequestBoxFields["comment"] = newSelectedRequest?.comments || "";
      }

      setRequestBoxFields(newRequestBoxFields);
      setIsEditRequestBoxFields(isEdit);
    } else {
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  const _getRequestById = (id) => {
    setSelectedRequest(null);

    getRequestById(id, { resortid: activeResortId })
      .then((res) => {
        _toggleEditRequestBox(false, res.booking);

        setSelectedRequest(res.booking);
      })
      .catch((error) => {
        console.log("error>>", error);
        setIsShowFloatingChat(false);
        setSelectedRequest(null);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _updateRequest = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateRequest(id, payload)
        .then((res) => {
          _getAllRequests(getAllRequestsPayload);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const _onChangeRequestStatus = async (request, key, value) => {
    try {
      setStatusLoading(true);
      const payload = {
        [key]: value,
      };
      await _updateRequest(request.id, payload);
      showToast("Request updated", "success");
    } catch (error) {
      console.log("error>>", error);
      setStatusLoading(false);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Server error, Try again after sometime.",
        "error"
      );
    }
  };

  const _onClickRequestBox = async (status) => {
    try {
      const payload = {
        status,
      };

      if (status === "completed") {
        payload["selectedTime"] = requestBoxFields.time
          ? formatDateAndTimeForApi(requestBoxFields.time)
          : "";
      }
      payload["comments"] = requestBoxFields.comment.trim().length
        ? requestBoxFields.comment.trim()
        : "";

      if (selectedRequest?.id && status) {
        setRequestBoxLoading(true);
        const updateRes = await _updateRequest(selectedRequest.id, payload);
        const newSelectedRequest = { ...selectedRequest };
        newSelectedRequest["status"] =
          updateRes.booking.status || newSelectedRequest.status;
        newSelectedRequest["selectedTime"] =
          updateRes.booking.selectedTime || "";
        newSelectedRequest["comments"] = updateRes.booking.comments || "";
        setSelectedRequest(newSelectedRequest);
        _toggleEditRequestBox(false, newSelectedRequest);
        showToast(
          status === "completed" ? "Request accepted" : "Request declined",
          "success"
        );
        setRequestBoxLoading(false);
      } else {
        showToast("Something went wrong, Try again after sometime.", "error");
      }
    } catch (error) {
      console.log("error>>", error);
      _toggleEditRequestBox(false, selectedRequest);
      setRequestBoxLoading(false);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Server error, Try again after sometime.",
        "error"
      );
    }
  };

  const _onClickRequestMessage = (request) => {
    setIsMinimizeFloatingChat(false);
    if (selectedRequest?.id !== request?.id) {
      setIsShowFloatingChat(true);
      _getRequestById(request.id);
      if (!request.isRead) _updateRequest(request.id, { isRead: true });
    }
  };

  const _closeFloatingChat = () => {
    setIsShowFloatingChat(false);
    setIsMinimizeFloatingChat(false);
    setSelectedRequest(null);
    setRequestBoxFields({
      time: "",
      comment: "",
    });
  };

  const _onChangeSearch = (searchValue) => {
    clearInterval(searchInterval);
    setSearchLoading(true);
    const newGetAllRequestsPayload = { ...getAllRequestsPayload };
    newGetAllRequestsPayload["search"] = searchValue;
    newGetAllRequestsPayload["skip"] = 0;
    newGetAllRequestsPayload["pageNumber"] = 0;
    setGetAllRequestsPayload(newGetAllRequestsPayload);
    searchInterval = setTimeout(() => {
      _getAllRequests(newGetAllRequestsPayload);
    }, 1000);
  };

  const _onChangeFilters = (key, value) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    const newGetAllRequestsPayload = { ...getAllRequestsPayload };
    newGetAllRequestsPayload["skip"] = 0;
    newGetAllRequestsPayload["pageNumber"] = 0;
    newGetAllRequestsPayload["filters"] = {};

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (newFilters[eachFilterKey])
        newGetAllRequestsPayload.filters[eachFilterKey] =
          newFilters[eachFilterKey];
    });

    setGetAllRequestsPayload(newGetAllRequestsPayload);

    _getAllRequests(newGetAllRequestsPayload);
  };

  const _clearFilters = () => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    const newGetAllRequestsPayload = { ...getAllRequestsPayload };
    newGetAllRequestsPayload["search"] = "";
    newGetAllRequestsPayload["skip"] = 0;
    newGetAllRequestsPayload["pageNumber"] = 0;
    newGetAllRequestsPayload["filters"] = {};
    setGetAllRequestsPayload(newGetAllRequestsPayload);

    _getAllRequests(newGetAllRequestsPayload);
  };

  const _getAmenitiesList = (resortid) => {
    getAmenitiesList({ resortid })
      .then((res) => {
        setAmenityArray(res.amenity?.length ? res.amenity : []);
      })
      .catch((error) => {
        console.log("error>>", error);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _getAllGroups = (resortid) => {
    getAllGroups({ resortid })
      .then((res) => {
        setGroups(res.groups?.length ? res.groups : []);
      })
      .catch((error) => {
        console.log("error>>", error);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _serviceType = useCallback((request) => {
    if (request?._service?.serviceType) {
      const findService = assignToComplimentaryServicesConfig.find(
        (each) => each.value === request._service.serviceType
      );
      return findService?.label ? findService.label : "-";
    }

    if (request?._amenity?.name) {
      return capitalize(request._amenity.name);
    }

    return "-";
  }, []);

  useEffect(() => {
    const { resortId, timeZone } = extractQueryParams();

    setActiveResortId(resortId);
    setTimeZone(timeZone);

    _getAllAssigneeList(resortId);
    _getAmenitiesList(resortId);
    _getAllGroups(resortId);

    const newGetAllRequestsPayload = { ...getAllRequestsPayload };
    newGetAllRequestsPayload["resortId"] = resortId;
    setGetAllRequestsPayload(newGetAllRequestsPayload);

    _getAllRequests(newGetAllRequestsPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content wedding-group-chat requests position-relative">
      <Container fluid>
        {/* ===== Page Title ===== */}

        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col xl={12} md={12}>
              <div className="page_title">
                <Button
                  color="link"
                  className="pl-0 pr-2"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h1 className="space_remove">
                  Requests{" "}
                  {filtersLoading || statusLoading ? <Spinner /> : null}
                </h1>
              </div>
            </Col>
          </Row>
        </div>
        {/* ======== Filter ======= */}
        <Card body className="search-filter pt-2 pb-3 mb-4">
          <div>
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex justify-content-center align-items-end">
                <FormGroup className="mb-0">
                  <Label>Service Category</Label>
                  <Input
                    type="select"
                    value={filters.subCategoryFilter}
                    onChange={(e) =>
                      _onChangeFilters("subCategoryFilter", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {React.Children.toArray(
                      assignToComplimentaryServicesConfig.map((each) => (
                        <option value={each.value}>{each.label}</option>
                      ))
                    )}
                    {amenityArray.map((each) => (
                      <option key={each._id} value={each.name}>
                        {capitalize(each.name)}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label>Request Status</Label>
                  <Input
                    type="select"
                    value={filters.status}
                    onChange={(e) => _onChangeFilters("status", e.target.value)}
                  >
                    <option value="">All</option>
                    {requestStatusConfig.map((each) => (
                      <option key={each.value} value={each.value}>
                        {each.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label>Group</Label>
                  <Input
                    type="select"
                    value={filters._group}
                    onChange={(e) => _onChangeFilters("_group", e.target.value)}
                  >
                    <option value="">All</option>
                    {groups.map(
                      (each) =>
                        (each.id || each._id) &&
                        each.name && (
                          <option
                            key={each.id || each._id}
                            value={each.id || each._id}
                          >
                            {capitalize(each.name)}
                          </option>
                        )
                    )}
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label>Assignee</Label>
                  <Input
                    type="select"
                    value={filters.assignedTo}
                    onChange={(e) =>
                      _onChangeFilters("assignedTo", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {assigneeArray.map(
                      (each) =>
                        each._id &&
                        each.name?.full && (
                          <option key={each._id} value={each._id}>
                            {capitalize(each.name.full)}
                          </option>
                        )
                    )}
                  </Input>
                </FormGroup>
              </div>
              <div className="d-flex align-items-end">
                <div className="searchName">
                  <Label>Search By Name</Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Enter"
                      autoComplete="off"
                      value={getAllRequestsPayload.search}
                      onChange={(e) => _onChangeSearch(e.target.value)}
                    />
                    <InputGroup.Text>
                      {searchLoading ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : getAllRequestsPayload.search ? (
                        <i
                          className="fas fa-times"
                          onClick={() => _onChangeSearch("")}
                        />
                      ) : (
                        <i className="fas fa-search" />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </div>
                <Button
                  color="primary"
                  className="btn-clear ml-4"
                  onClick={() => _clearFilters()}
                >
                  <i className="fa fa-eraser fs-15" />
                </Button>
              </div>
            </div>
          </div>
        </Card>

        <Table responsive className="m-0">
          <thead>
            <tr>
              <th>Vacationer</th>
              <th>Service Category</th>
              <th>Status</th>
              <th>Assignee</th>
              <th>Action</th>
              <th>Last Update</th>
              <th>Room #</th>
              <th>Group Name</th>
              <th>Check In - Check Out</th>
            </tr>
          </thead>
          <tbody>
            {requests?.length ? (
              requests.map((request) => (
                <tr
                  key={request.id}
                  className={!request.isRead ? "unread-request" : ""}
                >
                  <td>
                    {request._vacationer?.name?.full
                      ? capitalize(request._vacationer.name.full)
                      : "N/A"}
                  </td>
                  <td>{_serviceType(request)}</td>

                  <td>
                    <Input
                      type="select"
                      value={request.status}
                      disabled={statusLoading}
                      onChange={(e) =>
                        _onChangeRequestStatus(
                          request,
                          "status",
                          e.target.value
                        )
                      }
                    >
                      {requestStatusConfig.map((each) => (
                        <option key={each.value} value={each.value}>
                          {each.label}
                        </option>
                      ))}
                    </Input>
                  </td>

                  <td>
                    <Input
                      type="select"
                      value={request.assignedTo?._id || ""}
                      disabled={statusLoading}
                      onChange={(e) =>
                        _onChangeRequestStatus(
                          request,
                          "assignedToId",
                          e.target.value
                        )
                      }
                    >
                      <option disabled value="">
                        Select Assignee
                      </option>
                      {request._service?.teamMembers?.length
                        ? request._service.teamMembers.map(
                            (each) =>
                              each?._id &&
                              each?.name?.full && (
                                <option key={each._id} value={each._id}>
                                  {capitalize(each.name.full)}
                                </option>
                              )
                          )
                        : null}
                      {request._amenity?.teamMembers?.length
                        ? request._amenity.teamMembers.map(
                            (each) =>
                              each?._id &&
                              each?.name?.full && (
                                <option key={each._id} value={each._id}>
                                  {capitalize(each.name.full)}
                                </option>
                              )
                          )
                        : null}
                    </Input>
                  </td>

                  <td className="action">
                    <i
                      className="fa fa-envelope text-blue"
                      title="View request"
                      onClick={() => _onClickRequestMessage(request)}
                      style={{ fontSize: 20 }}
                    >
                      {/* <Badge color="danger">114</Badge> */}
                    </i>
                  </td>
                  <td>
                    {request.updatedAt
                      ? formatDateAndTime(request.updatedAt)
                      : "-"}
                  </td>
                  <td>
                    {request._resortBooking?.roomNo
                      ? request._resortBooking.roomNo
                      : "-"}
                  </td>

                  <td>
                    {request._resortBooking?._group?.name
                      ? capitalize(request._resortBooking._group.name)
                      : "N/A"}
                  </td>

                  <td>
                    {request._resortBooking?.startTimeStamp
                      ? formatDate(
                          request._resortBooking.startTimeStamp,
                          timeZone
                        )
                      : "-"}
                    {request._resortBooking?.endTimeStamp
                      ? ` - ${formatDate(
                          request._resortBooking.endTimeStamp,
                          timeZone
                        )}`
                      : null}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="9">
                  {loading ? <Spinner /> : "There is no data to display."}
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* ====== Pagination ====== */}
        {requests?.length && requestsCount ? (
          <div className="row mt-4">
            <div className="col-md-6">
              <span className="showng_entries">
                Showing {requests?.length} entries out of {requestsCount}
              </span>
            </div>
            <div className="col-md-6">
              <ReactPaginate
                forcePage={getAllRequestsPayload.pageNumber}
                className="pagination justify-content-end mb-2"
                breakLabel="..."
                nextLabel={<i className="fa fa-chevron-right" />}
                previousLabel={<i className="fa fa-chevron-left" />}
                previousClassName="previous"
                nextClassName="next"
                onPageChange={({ selected }) => _onPageChange(selected)}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(
                  requestsCount / getAllRequestsPayload.limit
                )}
                pageClassName="page-item"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                disabledClassName={"page-item"}
              />
            </div>
          </div>
        ) : null}

        {/* ======= Floating Chat Box ====== */}
        {isShowFloatingChat ? (
          <div
            className={`floating-chat-container ${
              isMinimizeFloatingChat ? "minimize" : "maximize"
            }`}
          >
            <div className="chat-card h-100">
              <Card className="p-0 h-100">
                <CardHeader className="d-flex align-items-center justify-content-between">
                  {/* === Chat Profile === */}

                  <div className="chat-profile">
                    <Media className="align-items-center">
                      <Media left>
                        <Media
                          object
                          src={
                            selectedRequest?._vacationer?.avatar ||
                            DEFAULT_PROFILE_PICTURE
                          }
                        />
                      </Media>
                      <Media body className="pl-2">
                        <Media
                          heading
                          className="fs-16 m-0 font-weight-bold lh-1 text-wrap-overflow text-blue cursorPointer"
                          onClick={() =>
                            selectedRequest?._vacationer
                              ? _togglePreviousRequestModal(
                                  true,
                                  selectedRequest
                                )
                              : null
                          }
                        >
                          {selectedRequest?._vacationer?.name?.full
                            ? capitalize(selectedRequest._vacationer.name.full)
                            : "Loading"}
                        </Media>
                        {selectedRequest?._vacationer?.username ? (
                          <span className="fs-13">
                            @{selectedRequest._vacationer.username}
                          </span>
                        ) : null}
                      </Media>
                    </Media>
                  </div>

                  <div className="windows-icon d-flex align-items-center justify-content-end">
                    <i
                      className="fa fa-window-minimize fs-14 mr-3 cursor-pointer"
                      onClick={() => {
                        setIsMinimizeFloatingChat((prev) => !prev);
                      }}
                    />
                    <i
                      className="fa fa-close fs-16 mt-1 cursor-pointer"
                      onClick={() => {
                        _closeFloatingChat();
                      }}
                    />
                  </div>
                </CardHeader>

                {!isMinimizeFloatingChat ? (
                  <>
                    {selectedRequest ? (
                      <>
                        <div className="chat-top-strip">
                          <List type="unstyled">
                            <li className="gp">
                              <span>Group:</span>
                              <b
                                title={
                                  selectedRequest._resortBooking?._group
                                    ?.name || "N/A"
                                }
                              >
                                {selectedRequest._resortBooking?._group?.name
                                  ? capitalize(
                                      selectedRequest._resortBooking._group.name
                                    )
                                  : "N/A"}
                              </b>
                            </li>
                            <li>
                              <span>Room #:</span>
                              <b>
                                {selectedRequest?._resortBooking?.roomNo ||
                                  "N/A"}
                              </b>
                            </li>
                            <li>
                              <span>Check In:</span>
                              <b>
                                {selectedRequest?._resortBooking?.startTimeStamp
                                  ? formatDate(
                                      selectedRequest._resortBooking
                                        .startTimeStamp,
                                      timeZone
                                    )
                                  : "N/A"}
                              </b>
                            </li>
                            <li>
                              <span>Check Out:</span>
                              <b>
                                {selectedRequest?._resortBooking?.endTimeStamp
                                  ? formatDate(
                                      selectedRequest._resortBooking
                                        .endTimeStamp,
                                      timeZone
                                    )
                                  : "N/A"}
                              </b>
                            </li>
                            <li>
                              <span>Total Guest:</span>
                              <b>
                                {selectedRequest.totalGuests
                                  ? selectedRequest.totalGuests
                                  : "N/A"}
                              </b>
                            </li>
                          </List>
                        </div>

                        <CardBody className="cardInfo">
                          <Card
                            body
                            className="request-card-details white-wrap"
                          >
                            <div className="d-flex justify-content-between">
                              <span>
                                {selectedRequest._vacationer?.name?.first
                                  ? capitalize(
                                      selectedRequest._vacationer.name.first
                                    )
                                  : "N/A"}{" "}
                                has created a new request for{" "}
                                <b className="text-dark">
                                  {_serviceType(selectedRequest)}
                                </b>
                              </span>

                              {selectedRequest._amenity ? (
                                <i
                                  className={`fas ${
                                    requestBoxLoading || !selectedRequest
                                      ? "fa-spinner fa-spin"
                                      : isEditRequestBoxFields
                                      ? "fa-times"
                                      : "fa-pencil-alt"
                                  } text-light fs-13 cursorPointer`}
                                  onClick={() =>
                                    _toggleEditRequestBox(
                                      !isEditRequestBoxFields,
                                      selectedRequest
                                    )
                                  }
                                />
                              ) : null}
                            </div>

                            {selectedRequest.subServiceType?.length ? (
                              <span>
                                {selectedRequest.subServiceType.length > 1
                                  ? "Items"
                                  : "Item"}{" "}
                                Requested{" "}
                                {React.Children.toArray(
                                  selectedRequest.subServiceType.map(
                                    (each, index) => (
                                      <b className="text-dark">
                                        {each}
                                        {selectedRequest.subServiceType[
                                          index + 1
                                        ]
                                          ? ", "
                                          : ""}
                                      </b>
                                    )
                                  )
                                )}
                              </span>
                            ) : null}

                            {selectedRequest.serviceComments ||
                            selectedRequest.amenityQuery ? (
                              <span>
                                {selectedRequest.serviceComments ||
                                  selectedRequest.amenityQuery}
                              </span>
                            ) : null}

                            {selectedRequest._amenity ? (
                              <>
                                <List
                                  type="unstyled"
                                  className="d-flex flex-wrap"
                                >
                                  <li>
                                    <span className="text-capitalize">
                                      Service Date : {""}
                                    </span>
                                    <b className="text-capitalize">
                                      {selectedRequest.startTimeStamp
                                        ? formatDate(
                                            selectedRequest.startTimeStamp,
                                            timeZone
                                          )
                                        : "N/A"}
                                    </b>
                                  </li>
                                  <li>
                                    <span className="text-capitalize">
                                      Service Time : {""}
                                    </span>
                                    <b className="text-capitalize">
                                      {selectedRequest.startTimeStamp
                                        ? formatTime(
                                            selectedRequest.startTimeStamp,
                                            timeZone
                                          )
                                        : "N/A"}
                                      {selectedRequest.endTimeStamp
                                        ? ` - ${formatTime(
                                            selectedRequest.endTimeStamp,
                                            timeZone
                                          )}`
                                        : "N/A"}
                                    </b>
                                  </li>
                                  <li>
                                    <span className="text-capitalize">
                                      Total Guests : {""}
                                    </span>

                                    <b className="text-capitalize">
                                      {selectedRequest.totalGuests
                                        ? selectedRequest.totalGuests
                                        : "N/A"}
                                    </b>
                                  </li>
                                </List>

                                <div>
                                  <Row>
                                    <Col md={6}>
                                      <FormGroup>
                                        <ReactDatetime
                                          inputProps={{
                                            className: "form-control",
                                            placeholder: "Select time",
                                            value: formatTime(
                                              requestBoxFields.time
                                            ),
                                            disabled:
                                              !isEditRequestBoxFields ||
                                              requestBoxLoading,
                                          }}
                                          value={
                                            requestBoxFields.time
                                              ? requestBoxFields.time
                                              : ""
                                          }
                                          onChange={(e) =>
                                            setRequestBoxFields((prev) => {
                                              return {
                                                time: e ? e : "",
                                                comment: prev.comment,
                                              };
                                            })
                                          }
                                          timeConstraints={{
                                            minutes: {
                                              step: 15,
                                            },
                                          }}
                                          dateFormat={false}
                                          timeFormat={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                      <FormGroup>
                                        <Input
                                          type="textarea"
                                          placeholder="Enter the comment"
                                          name="comment"
                                          disabled={
                                            !isEditRequestBoxFields ||
                                            requestBoxLoading
                                          }
                                          value={requestBoxFields.comment}
                                          onChange={(e) =>
                                            setRequestBoxFields((prev) => {
                                              return {
                                                time: prev.time,
                                                comment: e.target.value,
                                              };
                                            })
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ) : null}

                            {isEditRequestBoxFields ? (
                              <div className="d-flex align-items-center mt-2">
                                <Button
                                  color="primary"
                                  size="sm"
                                  className="mr-3"
                                  disabled={requestBoxLoading}
                                  onClick={() =>
                                    _onClickRequestBox("completed")
                                  }
                                >
                                  Accept
                                </Button>
                                <Button
                                  color="light"
                                  size="sm"
                                  disabled={requestBoxLoading}
                                  onClick={() =>
                                    _onClickRequestBox("cancelled")
                                  }
                                >
                                  Decline
                                </Button>
                              </div>
                            ) : null}
                          </Card>
                        </CardBody>
                      </>
                    ) : null}

                    {/* Request Chat Box Component */}
                    <RequestChatBoxComponent
                      selectedRequest={selectedRequest}
                      user={userCredential?.user}
                      activeResortId={activeResortId}
                    />
                  </>
                ) : null}
              </Card>
            </div>
          </div>
        ) : null}
      </Container>

      <PreviousRequestModal
        isOpen={previousRequestModal.isOpen}
        data={previousRequestModal.data}
        toggle={() => _togglePreviousRequestModal()}
      />
    </div>
  );
};

export default GuestRequests;
