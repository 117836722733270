import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  InputGroupText,
  FormGroup,
  Input,
  Row,
  Label,
} from "reactstrap";
import AuthFooter from "../../components/Footers/AuthFooter";
// import GoogleLoginComponent from '../../components/google-login';
// import FacebookLoginComponent from '../../components/facebook-login';
import { useHistory } from "react-router-dom";
import { RegexConfig } from "../../config/RegexConfig";
import { showLoader } from "../../redux/actions/loader-data";
import { useDispatch } from "react-redux";
// import { socialLogin } from "../../http/http-calls";
import { showToast } from "../../helper-methods";
import { hideLoader } from "../../redux/actions/loader-data";
import { getIpData, login } from "../../http/http-calls";
import {
  addUserCredential,
  getAndUpdateUserData,
} from "../../redux/actions/user-credential";
import { getAndUpdateSettings } from "../../redux/actions/settings";
import { APP_LOGO, LOGIN_PAGE_BANNER } from "../../config/index";
import OneSignalHelper from "../../onesignal-helper/index";
import DeviceMetaData from "../../device-metadata/index";

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    handle: "",
    password: "",
  });
  const [isDirty, setIsDirty] = useState({
    handle: false,
    password: false,
  });
  const [errors, setErrors] = useState({});

  const _initializeOneSignal = async () => {
    const hasPermission = await OneSignalHelper.getNotificationPermission();
    if (!hasPermission) {
      OneSignalHelper.showPrompt();
    }
  };

  // eslint-disable-next-line no-unused-vars
  const _getDeviceDetails = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const deviceDetails = {};
        deviceDetails["name"] =
          DeviceMetaData.getBrowser() + " on " + DeviceMetaData.getOs();
        deviceDetails["ipAddress"] = (await getIpData())["ip"];
        // Get device id
        // First try to get from one signal
        let onesignalDeviceId = null;
        onesignalDeviceId = await OneSignalHelper.getDeviceId();
        if (onesignalDeviceId) {
          deviceDetails["allowNotification"] = true;
          deviceDetails["deviceId"] = onesignalDeviceId;
        } else {
          deviceDetails["allowNotification"] = false;
          deviceDetails["deviceId"] = DeviceMetaData.getDeviceId();
        }
        resolve(deviceDetails);
      } catch (error) {
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
        reject(error);
      }
    });
  };

  const _validateFormFields = (newCredentials, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newCredentials).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "handle": {
              if (newCredentials[key]?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(newCredentials[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Invalid email";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "password": {
              if (newCredentials[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newCredentials = { ...credentials };
    const newIsDirty = { ...isDirty };
    newCredentials[key] = value;
    newIsDirty[key] = true;
    setCredentials(newCredentials);
    setIsDirty(newIsDirty);
    _validateFormFields(newCredentials, newIsDirty);
  };

  const _onSubmitLoginForm = async (event) => {
    if (event) event.preventDefault();

    const newIsDirty = {
      handle: true,
      password: true,
    };

    const isFormValid = await _validateFormFields(credentials, newIsDirty);

    if (isFormValid) {
      dispatch(showLoader("Login..."));

      const payload = {
        ...credentials,
        // userCategory: "resortadmin",
      };

    
      login(payload)
        .then((res) => {
          dispatch(hideLoader());
          dispatch(addUserCredential({ token: res.token, user: res.user }));
          dispatch(getAndUpdateUserData());
          dispatch(getAndUpdateSettings());
          history.replace("/team-members");
        })
        .catch((error) => {
          console.log("error>>", error);
          dispatch(hideLoader());
          showToast(
            error?.reason?.length
              ? error.reason
              : "Server error, Try again after sometime.",
            "error"
          );
          history.replace("/");
        });
    }
  };

  // const _socialLogin = (platform, accessToken) => {
  //   dispatch(showLoader(`Login through ${platform}...`));

  //   const payload = {
  //     accessToken,
  //     userCategory: 'resortadmin',
  //   };

  //   try {
  //     const deviceDetails = await _getDeviceDetails();
  //     if (deviceDetails) {
  //       payload["deviceDetails"] = deviceDetails;
  //       payload["allowNotification"] = deviceDetails.allowNotification;
  //     }
  //   } catch (error) {
  //     console.log("deviceDetails error>>", error);
  //   }

  //   socialLogin(platform, payload)
  //     .then((res) => {
  //       dispatch(hideLoader());
  //       dispatch(addUserCredential({ token: res.token, user: res.user }));
  //       history.push('/dashboard');
  //     })
  //     .catch((error) => {
  //       console.log('error>>', error);
  //       dispatch(hideLoader());
  //       showToast(
  //         error?.reason?.length
  //           ? error.reason
  //           : 'Server error, Try again after sometime.',
  //         'error'
  //       );
  //     });
  // };

  useEffect(() => {
    _initializeOneSignal();
  }, []);

  return (
    <div className="vayco-public login_singup">
      <div className="public-header">
        <img
          src={APP_LOGO}
          onClick={() => history.push("/login")}
          alt="Logo"
          className="company-logo"
          width="130"
          loading="lazy"
        />

        {/* <Button
          color="link"
          onClick={() => history.push("/register")}
          className="loginLink"
        >
          <span>Sign Up</span>
        </Button> */}
      </div>

      <div className="app animated fadeIn ">
        <div className="login_wrapepr d-flex align-items-center">
          <div className="login_left">
            <img
              src={LOGIN_PAGE_BANNER}
              alt="banner"
              className="img-fluid"
              loading="lazy"
            />
          </div>
          <div className="login_right">
            <div className="login_Container">
              <div className="form-title text-center">
                <h2 className="mt-0">Login</h2>
              </div>

              <Form onSubmit={(e) => _onSubmitLoginForm(e)}>
                <FormGroup>
                  <Label>Email</Label>
                  <InputGroup>
                    <InputGroupText>
                      <i className="fas fa-user" />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder="Enter your email"
                      autoComplete="off"
                      name="handle"
                      value={credentials.handle}
                      onChange={(e) =>
                        _onChangeFormFields("handle", e.target.value)
                      }
                    />
                  </InputGroup>
                  {errors["handle"] ? (
                    <div className="form-error">{errors["handle"]}</div>
                  ) : null}
                </FormGroup>

                <FormGroup className="mb-2">
                  <Label>Password</Label>
                  <InputGroup>
                    <InputGroupText>
                      <i className="fas fa-unlock-alt" />
                    </InputGroupText>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      autoComplete="off"
                      name="password"
                      value={credentials.password}
                      onChange={(e) =>
                        _onChangeFormFields("password", e.target.value)
                      }
                    />
                    <InputGroupText>
                      <i
                        className={`far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    </InputGroupText>
                  </InputGroup>
                  {errors["password"] ? (
                    <div className="form-error">{errors["password"]}</div>
                  ) : null}
                </FormGroup>

                {/* <div className="text-right d-block">
                  <Button
                    color="link"
                    onClick={() => history.push("/forgot-password")}
                    className="p-0"
                  >
                    <span className="forgotPassword">Forgot Password?</span>
                  </Button>
                </div> */}

                <Row className="no-margin">
                  <Col className="text-center">
                    <Button
                      type="submit"
                      color="primary"
                      className="btn-submit login_btn"
                    >
                      Login
                    </Button>
                  </Col>
                </Row>
              </Form>

              {/* <div className="or">
                <span>OR</span>
              </div>

              <div className="socialLogin">
                <GoogleLoginComponent
                  className="googleSignUpBtn"
                  response={(res) => _socialLogin('google', res.accessToken)}
                />
                <FacebookLoginComponent
                  className="fbSignUpBtn"
                  response={(res) => _socialLogin('facebook', res.accessToken)}
                />
              </div> */}
            </div>

            <AuthFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
