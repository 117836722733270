import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routes from "../../routes";
import HotelAmenities from "../protectedPages/HotelAmenities";
import AddAmenity from "../protectedPages/AddAmenity";
import TeamMembers from "../protectedPages/TeamMembers";
import Staff from "../protectedPages/Staff";
import GroupChat from "../protectedPages/GroupChat";
import GuestRequests from "../protectedPages/GuestRequests";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import MediaQueueViewer from "../../components/media-queue-viewer";
import ProtectedRoute from "../../components/protected-routes";
import { connectToSocket, disconnectToSocket } from "../../socket-io";
import { showToast } from "../../helper-methods";
import OffersPage from "../protectedPages/OffersPage";
import NotificationsPage from "../protectedPages/NotificationsPage";
import SettingsPage from "../protectedPages/SettingsPage";
import Vacationers from "../protectedPages/Vacationers";
import Outings from "../protectedPages/Outings";
import Resorts from "../protectedPages/Resorts";
import ResortDetails from "../protectedPages/ResortDetails";
import SubscriptionInvoice from "../protectedPages/SubscriptionInvoice";
import ProfilePage from "../protectedPages/ProfilePage";
import ResortSettingsPage from "../protectedPages/ResortSettingsPage";
import KpiDashboard from "../protectedPages/KpiDashboard";

const AdminLayout = (props) => {
  const _connectToSocket = () => {
    connectToSocket()
      .then((res) => {
       
      })
      .catch((error) => {
        showToast(
          error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      });
  };

  const _disconnectToSocket = () => {
    disconnectToSocket()
      .then((flag) => {
        console.log("AdminLayout: disconnect>>", flag);
      })
      .catch((error) => {
        showToast(
          error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      });
  };

  useEffect(() => {
    _connectToSocket();

    return () => {
      _disconnectToSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="vayco-admin">
      {/* //Protected Routes */}
      <div className="wrapper">
        <Sidebar routes={routes} />
        <div className="main-panel">
          <AdminNavbar {...props} />
          <Switch>
            <ProtectedRoute
              exact
              path="/hotel-amenities"
              component={HotelAmenities}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/team-members"
              component={TeamMembers}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/add-amenity"
              component={AddAmenity}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/group-chat"
              component={GroupChat}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/requests"
              component={GuestRequests}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/offers"
              component={OffersPage}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/notifications"
              component={NotificationsPage}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/resorts"
              component={Resorts}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/resort-details/:resortId"
              component={ResortDetails}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/settings/:resortId"
              component={ResortSettingsPage}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/staff"
              component={Staff}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/settings"
              component={SettingsPage}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/vacationers"
              component={Vacationers}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/outings"
              component={Outings}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/subscription-invoice"
              component={SubscriptionInvoice}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/profile"
              component={ProfilePage}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              exact
              path="/dashboard"
              component={KpiDashboard}
              redirectRoute={"/login"}
            />

            <Route
              exact
              path="*"
              render={() => <Redirect to="/team-members" />}
            />
          </Switch>

          <MediaQueueViewer />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
