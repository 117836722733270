import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import Chips from "react-chips";
import { deepClone, showToast, uploadFileOnServer } from "../../helper-methods";
import { getAndUpdateSettings } from "../../redux/actions/settings";
import { connect } from "react-redux";
import {
  updateComplimentaryServices,
  addAmenityCategories,
  updateAmenityCategories,
  removeAmenityCategories,
} from "../../http/http-calls";
import SweetAlert from "react-bootstrap-sweetalert";

const chipsTheme = {
  chipsContainer: {
    display: "flex",
    position: "relative",
    border: "1px solid #e4e7ea",
    backgroundColor: "#fff",
    fontSize: "13px",
    minHeight: 24,
    alignItems: "center",
    flexWrap: "wrap",
    padding: "3px 4px 4px",
    borderRadius: "4px",
    ":focus": {
      border: "1px solid #e4e7ea",
    },
  },
  container: {
    flex: 1,
  },
  containerOpen: {},
  input: {
    border: "none",
    outline: "none",
    boxSizing: "border-box",
    width: "100%",
    padding: 5,
    margin: 0,
  },
  suggestionsContainer: {},
  suggestionsList: {
    position: "absolute",
    border: "1px solid #ccc",
    zIndex: 10,
    left: 0,
    top: "100%",
    width: "100%",
    backgroundColor: "#fff",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  suggestion: {
    padding: "5px 15px",
  },
  suggestionHighlighted: {
    background: "#ddd",
  },
  sectionContainer: {},
  sectionTitle: {},
};

const chipTheme = {
  chip: {
    padding: "1px 4px 0",
    background: "#fff",
    border: "1px solid #fd3762",
    color: "#fd3762",
    margin: 2.5,
    borderRadius: 6,
    textTransform: "capitalize",
  },
  chipSelected: {
    background: "#888",
  },
  chipRemove: {
    marginLeft: 4,
    marginRight: 1,
    fontSize: 17,
    color: "#f16667",
    cursor: "pointer",
    verticalAlign: -1,
    ":hover": {
      color: "#f16667",
    },
  },
};

class SettingsPage extends Component {
  state = {
    complimentaryServices: {
      frontDesk: [],
      houseKeeping: [],
      concierge: [],
      inRoomDining: [],
    },
    amenityCategories: [],
    amenityCategoriesFormFields: {
      label: {
        value: {
          uploadData: null,
          previewBlob: null,
          type: null,
          uploadUrl: null,
        },
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
      category: {
        value: "",
        isDirty: false,
        error: null,
        isValidationRequired: true,
      },
    },
    isEditAmenityCategoryId: false,
    loading: {
      settingLoading: false,
      complimentaryServicesLoading: false,
      addAmenityLoading: false,
      updateAmenityLoading: false,
      removeAmenityLoading: false,
    },
    alert: null,
  };

  _resetAmenityCategoriesFormFields = () => {
    this.setState({
      amenityCategoriesFormFields: {
        label: {
          value: {
            uploadData: null,
            previewBlob: null,
            type: null,
            uploadUrl: null,
          },
          isDirty: false,
          error: null,
          isValidationRequired: true,
        },
        category: {
          value: "",
          isDirty: false,
          error: null,
          isValidationRequired: true,
        },
      },
    });
  };

  _setComplimentaryServicesSettings = () => {
    const { settings } = this.props;

    const { complimentaryServices } = this.state;

    complimentaryServices.frontDesk = settings.complimentaryServices?.frontDesk
      ?.length
      ? settings.complimentaryServices.frontDesk
      : [];
    complimentaryServices.houseKeeping = settings.complimentaryServices
      ?.houseKeeping?.length
      ? settings.complimentaryServices.houseKeeping
      : [];
    complimentaryServices.concierge = settings.complimentaryServices?.concierge
      ?.length
      ? settings.complimentaryServices.concierge
      : [];
    complimentaryServices.inRoomDining = settings.complimentaryServices
      ?.inRoomDining?.length
      ? settings.complimentaryServices.inRoomDining
      : [];

    this.setState({
      complimentaryServices,
    });
  };

  _setAmenityCategoriesSettings = () => {
    const { settings } = this.props;

    if (settings?.amenityCategories?.length) {
      let { amenityCategories, isEditAmenityCategoryId } = deepClone(
        this.state
      );

      const findEditAmenityCategory =
        isEditAmenityCategoryId || amenityCategories?.length
          ? amenityCategories.find(
              (each) => each.id === isEditAmenityCategoryId
            )
          : null;

      amenityCategories = [];

      settings.amenityCategories.forEach((each) => {
        if (
          findEditAmenityCategory &&
          findEditAmenityCategory.id === each._id
        ) {
          amenityCategories.push(findEditAmenityCategory);
        } else {
          const obj = {
            id: each.id || each._id,
            label: {
              value: {
                uploadData: null,
                previewBlob: null,
                type: null,
                uploadUrl: each.label || "",
              },
              isDirty: false,
              error: null,
              isValidationRequired: true,
            },
            category: {
              value: each.category || "",
              isDirty: false,
              error: null,
              isValidationRequired: true,
            },
          };
          amenityCategories.push(obj);
        }
      });

      this.setState({ amenityCategories });
    }
  };

  _getSettings = async () => {
    try {
      this._manageLoading("settingLoading", true);

      await this.props.getAndUpdateSettings();

      this._manageLoading("settingLoading", false);

      this._setComplimentaryServicesSettings();
      this._setAmenityCategoriesSettings();
    } catch (error) {
      this._manageLoading("settingLoading", false);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Server error, Try again after sometime.",
        "error"
      );
    }
  };

  _manageLoading = (key, value) => {
    const { loading } = this.state;
    loading[key] = value;
    this.setState({ loading });
  };

  componentDidMount = () => {
    this._setComplimentaryServicesSettings();
    this._setAmenityCategoriesSettings();

    this._getSettings();
  };

  _onChangeComplimentaryServices = (key, arr) => {
    const { complimentaryServices } = this.state;

    complimentaryServices[key] = arr;

    this.setState({ complimentaryServices });
  };

  _updateComplimentaryServices = () => {
    this._manageLoading("complimentaryServicesLoading", true);

    const { complimentaryServices } = this.state;

    updateComplimentaryServices({ complimentaryServices })
      .then((res) => {
        this._manageLoading("complimentaryServicesLoading", false);
        showToast("Complimentary services updated", "success");
        this._getSettings();
      })
      .catch((error) => {
        this._manageLoading("complimentaryServicesLoading", false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  _validateFormFields = () => {
    return new Promise((resolve) => {
      const { amenityCategoriesFormFields } = deepClone(this.state);

      let isFormValid = true;

      Object.keys(amenityCategoriesFormFields).forEach((key) => {
        if (
          amenityCategoriesFormFields[key].isDirty &&
          amenityCategoriesFormFields[key].isValidationRequired
        ) {
          switch (key) {
            case "category": {
              if (amenityCategoriesFormFields[key].value?.trim().length) {
                amenityCategoriesFormFields[key].error = null;
                amenityCategoriesFormFields[key].isDirty = false;
              } else {
                amenityCategoriesFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "label": {
              if (
                amenityCategoriesFormFields[key].value?.uploadData ||
                amenityCategoriesFormFields[key].value?.uploadUrl
              ) {
                amenityCategoriesFormFields[key].error = null;
                amenityCategoriesFormFields[key].isDirty = false;
              } else {
                amenityCategoriesFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ amenityCategoriesFormFields }, () =>
        resolve(isFormValid)
      );
    });
  };

  _onChangeFile = (key, e) => {
    try {
      if (e?.target?.files?.length) {
        const { amenityCategoriesFormFields } = this.state;

        const file = e.target.files[0];

        const fileType = file.type.split("/")[0];

        if (fileType === "image") {
          const previewBlob = URL.createObjectURL(file);

          amenityCategoriesFormFields[key].value["uploadData"] = file;
          amenityCategoriesFormFields[key].value["previewBlob"] = previewBlob;
          amenityCategoriesFormFields[key].value["type"] = fileType;
          amenityCategoriesFormFields[key].value["uploadUrl"] = null;
        } else {
          showToast("Only image file is allowed", "error");
          return;
        }

        this.setState({ amenityCategoriesFormFields }, () =>
          this._validateFormFields()
        );
      }
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  _onChangeFormFields = (key, value) => {
    const { amenityCategoriesFormFields } = this.state;
    amenityCategoriesFormFields[key].value = value;
    amenityCategoriesFormFields[key].isDirty = true;
    this.setState({ amenityCategoriesFormFields }, () =>
      this._validateFormFields()
    );
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { amenityCategoriesFormFields } = this.state;
      Object.keys(amenityCategoriesFormFields).forEach((key) => {
        amenityCategoriesFormFields[key].isDirty = true;
      });
      this.setState({ amenityCategoriesFormFields }, () => resolve(true));
    });
  };

  _addAmenityCategories = async () => {
    await this._markAllFieldDirty();

    const isFormValid = await this._validateFormFields();

    if (isFormValid) {
      this._manageLoading("addAmenityLoading", true);

      const { amenityCategoriesFormFields } = this.state;

      const payload = {
        category: amenityCategoriesFormFields.category.value.trim(),
      };

      if (amenityCategoriesFormFields.label.value.uploadData) {
        const response = await uploadFileOnServer([
          { ...amenityCategoriesFormFields.label.value },
        ]);
        payload["label"] = response[0].url;
        amenityCategoriesFormFields.label.value["uploadUrl"] = response[0].url;
        amenityCategoriesFormFields.label.value["uploadData"] = null;

        this.setState({ amenityCategoriesFormFields });
      } else if (amenityCategoriesFormFields.label.value.uploadUrl) {
        payload["label"] = amenityCategoriesFormFields.label.value.uploadUrl;
      }

      addAmenityCategories({ amenityCategories: [{ ...payload }] })
        .then((res) => {
          this._manageLoading("addAmenityLoading", false);
          showToast("Amenity Category added", "success");
          this._resetAmenityCategoriesFormFields();
          this._getSettings();
        })
        .catch((error) => {
          this._manageLoading("addAmenityLoading", false);
          showToast(
            error?.reason?.length
              ? error.reason
              : "Server error, Try again after sometime.",
            "error"
          );
        });
    }
  };

  // ============= update aminety category ================ //

  _validateEditFormFields = (index) => {
    return new Promise((resolve) => {
      const { amenityCategories } = deepClone(this.state);

      let isFormValid = true;

      Object.keys(amenityCategories[index]).forEach((key) => {
        if (
          amenityCategories[index][key].isDirty &&
          amenityCategories[index][key].isValidationRequired
        ) {
          switch (key) {
            case "category": {
              if (amenityCategories[index][key].value?.trim().length) {
                amenityCategories[index][key].error = null;
                amenityCategories[index][key].isDirty = false;
              } else {
                amenityCategories[index][key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "label": {
              if (
                amenityCategories[index][key].value?.uploadData ||
                amenityCategories[index][key].value?.uploadUrl
              ) {
                amenityCategories[index][key].error = null;
                amenityCategories[index][key].isDirty = false;
              } else {
                amenityCategories[index][key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ amenityCategories }, () => resolve(isFormValid));
    });
  };

  _onChangeEditFile = (key, e, index) => {
    try {
      if (e?.target?.files?.length) {
        const { amenityCategories } = deepClone(this.state);

        const file = e.target.files[0];

        const fileType = file.type.split("/")[0];

        if (fileType === "image") {
          const previewBlob = URL.createObjectURL(file);

          amenityCategories[index][key].value["uploadData"] = file;
          amenityCategories[index][key].value["previewBlob"] = previewBlob;
          amenityCategories[index][key].value["type"] = fileType;
          amenityCategories[index][key].value["uploadUrl"] = null;
        } else {
          showToast("Only image file is allowed", "error");
          return;
        }

        this.setState({ amenityCategories }, () =>
          this._validateEditFormFields(index)
        );
      }
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime.", "error");
    }
  };

  _onChangeEditFormFields = (key, value, index) => {
    const { amenityCategories } = deepClone(this.state);
    amenityCategories[index][key].value = value;
    amenityCategories[index][key].isDirty = true;
    this.setState({ amenityCategories }, () =>
      this._validateEditFormFields(index)
    );
  };

  _markAllEditFieldDirty = (index) => {
    return new Promise((resolve) => {
      const { amenityCategories } = this.state;
      Object.keys(amenityCategories[index]).forEach((key) => {
        if (key !== "id") amenityCategories[index][key].isDirty = true;
      });
      this.setState({ amenityCategories }, () => resolve(true));
    });
  };

  _onSaveEditAmenityCategories = async (id, index) => {
    await this._markAllEditFieldDirty(index);

    const isFormValid = await this._validateEditFormFields(index);

    if (isFormValid) {
      this._manageLoading("updateAmenityLoading", id);

      const { amenityCategories } = deepClone(this.state);

      const payload = {
        category: amenityCategories[index].category.value.trim(),
      };

      if (amenityCategories[index].label.value.uploadData) {
        const response = await uploadFileOnServer([
          { ...amenityCategories[index].label.value },
        ]);
        payload["label"] = response[0].url;
        amenityCategories[index].label.value["uploadUrl"] = response[0].url;
        amenityCategories[index].label.value["uploadData"] = null;

        this.setState({ amenityCategories });
      } else if (amenityCategories[index].label.value.uploadUrl) {
        payload["label"] = amenityCategories[index].label.value.uploadUrl;
      }

      updateAmenityCategories(id, { amenityCategories: { ...payload } })
        .then((res) => {
          this._manageLoading("updateAmenityLoading", false);
          showToast("Amenity Category updated", "success");
          this.setState({ isEditAmenityCategoryId: false }, () => {
            this._resetAmenityCategoriesFormFields();
            this._getSettings();
          });
        })
        .catch((error) => {
          this._manageLoading("updateAmenityLoading", false);
          showToast(
            error?.reason?.length
              ? error.reason
              : "Server error, Try again after sometime.",
            "error"
          );
        });
    }
  };

  // ============== remove amenity category ================= //

  _hideAlert = () => {
    this.setState({ alert: null });
  };

  _removeAmenityCategories = (id) => {
    this._hideAlert();

    this._manageLoading("removeAmenityLoading", id);

    const { amenityCategories } = deepClone(this.state);

    const findAmenityCategoryIndex = amenityCategories.findIndex(
      (each) => each.id === id
    );

    if (findAmenityCategoryIndex > -1) {
      amenityCategories.splice(findAmenityCategoryIndex, 1);

      removeAmenityCategories({ amenityCategoriesId: [id] })
        .then((res) => {
          this._manageLoading("removeAmenityLoading", false);
          showToast("Amenity Category removed", "success");
          this.setState({ amenityCategories }, () => {
            this._getSettings();
          });
        })
        .catch((error) => {
          this._manageLoading("removeAmenityLoading", false);
          this._getSettings();
          showToast(
            error?.reason?.length
              ? error.reason
              : "Server error, Try again after sometime.",
            "error"
          );
        });
    }
  };

  _removeAmenityCategoriesAlert = (amenityCategory) => {
    if (amenityCategory) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => this._removeAmenityCategories(amenityCategory.id)}
            onCancel={() => this._hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, remove it!"
            cancelBtnText="Cancel"
            showCancel
          >
            You want to remove {amenityCategory.category.value} category. You
            will not be able to recover it!
          </SweetAlert>
        ),
      });
    } else {
      showToast("Somthing went wrong, Try again after sometime.", "error");
    }
  };

  render() {
    const {
      complimentaryServices,
      amenityCategories,
      amenityCategoriesFormFields,
      loading,
      isEditAmenityCategoryId,
      alert,
    } = this.state;

    return (
      <>
        {alert}

        <div className="content">
          <Container fluid className="mobilePadding-0">
            <div className="page_Inner_header">
              <Row className="align-items-center">
                <Col xl={5} md={4}>
                  <div className="page_title">
                    <Button
                      color="link"
                      className="pl-0 pr-2"
                      onClick={() => this.props.history.goBack()}
                    >
                      <i className="fa fa-chevron-left" />
                    </Button>
                    <h1 className="space_remove">
                      Settings {loading.settingLoading ? <Spinner /> : null}
                    </h1>
                  </div>
                </Col>
              </Row>
            </div>

            {/* === Complimentary Services === */}
            <Card className="settingsWrap" data-test="left-card">
              <CardBody className="p-2">
                <div className="complimentary-services">
                  <h4 className="my-0" style={{ fontWeight: "600" }}>
                    Complimentary Services
                    <hr />
                  </h4>
                  <Row>
                    <Col md="6">
                      <div className="setting-chips-row">
                        <h5 className="mb-2 mt-0" style={{ fontWeight: "600" }}>
                          Front Desk
                        </h5>

                        <Chips
                          style={{ width: "100%" }}
                          theme={chipsTheme}
                          chipTheme={chipTheme}
                          value={complimentaryServices.frontDesk}
                          onChange={(arr) =>
                            this._onChangeComplimentaryServices(
                              "frontDesk",
                              arr
                            )
                          }
                          createChipKeys={[9, 13]} //  Key codes to add chips
                          placeholder={"Add front desk.."}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="setting-chips-row">
                        <h5 className="mb-2 mt-0" style={{ fontWeight: "600" }}>
                          House Keeping
                        </h5>

                        <Chips
                          style={{ width: "100%" }}
                          theme={chipsTheme}
                          chipTheme={chipTheme}
                          value={complimentaryServices.houseKeeping}
                          onChange={(arr) =>
                            this._onChangeComplimentaryServices(
                              "houseKeeping",
                              arr
                            )
                          }
                          createChipKeys={[9, 13]} //  Key codes to add chips
                          placeholder={"Add house keeping.."}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mt-4 setting-chips-row">
                        <h5 className="mb-2 mt-0" style={{ fontWeight: "600" }}>
                          Concierge
                        </h5>

                        <Chips
                          style={{ width: "100%" }}
                          theme={chipsTheme}
                          chipTheme={chipTheme}
                          value={complimentaryServices.concierge}
                          onChange={(arr) =>
                            this._onChangeComplimentaryServices(
                              "concierge",
                              arr
                            )
                          }
                          createChipKeys={[9, 13]} //  Key codes to add chips
                          placeholder={"Add concierge.."}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mt-4 setting-chips-row">
                        <h5 className="mb-2 mt-0" style={{ fontWeight: "600" }}>
                          In Room Dining
                        </h5>

                        <Chips
                          style={{ width: "100%" }}
                          theme={chipsTheme}
                          chipTheme={chipTheme}
                          value={complimentaryServices.inRoomDining}
                          onChange={(arr) =>
                            this._onChangeComplimentaryServices(
                              "inRoomDining",
                              arr
                            )
                          }
                          createChipKeys={[9, 13]} //  Key codes to add chips
                          placeholder={"Add in room dining.."}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="d-flex equal_width justify-content-center mt-4">
                  <Button
                    className="btn-primary  fw-bold"
                    disabled={loading.complimentaryServicesLoading}
                    onClick={() => this._updateComplimentaryServices()}
                  >
                    {loading.complimentaryServicesLoading ? (
                      <i className="fa fa-spinner fa-spin fs-13 mr-1" />
                    ) : null}
                    Update
                  </Button>

                  <Button
                    color="priamry"
                    className="btn-cancel ml-3"
                    disabled={loading.complimentaryServicesLoading}
                    onClick={() => this._setComplimentaryServicesSettings()}
                  >
                    Reset
                  </Button>
                </div>
              </CardBody>
            </Card>

            {/* === Amenity Services === */}
            <Card className="settingsWrap mt-4" data-test="left-card">
              <CardBody className="p-2">
                <div className="setting-chips-row">
                  <h4 className="mb-2 mt-0" style={{ fontWeight: "600" }}>
                    Amenity Categories
                  </h4>
                  <hr className="mb-4" />

                  {/* ======== Table ======== */}
                  <Table responsive className="m-0">
                    <thead>
                      <tr>
                        <th width="10%">Label</th>
                        <th width="80%">Cateogry</th>
                        <th width="10%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/*=== Add fields ===*/}
                      <tr>
                        <td>
                          <div className="amenity-location">
                            {amenityCategoriesFormFields.label.value
                              .previewBlob ||
                            amenityCategoriesFormFields.label.value
                              .uploadUrl ? (
                              <div className={`input-gallery-col`}>
                                <div className="group_image">
                                  <img
                                    src={
                                      amenityCategoriesFormFields.label.value
                                        .previewBlob ||
                                      amenityCategoriesFormFields.label.value
                                        .uploadUrl
                                    }
                                    alt="cover"
                                    loading="lazy"
                                  />
                                  {/* {!loading.addAmenityLoading ? (
                                    <i
                                      className="fa fa-close"
                                      disabled={loading.addAmenityLoading}
                                      onClick={() =>
                                        this._resetDisplayPicture()
                                      }
                                    />
                                  ) : null} */}
                                </div>
                                <Label>
                                  <span className="replace_map">
                                    <Input
                                      type="file"
                                      value=""
                                      className="d-none"
                                      disabled={loading.addAmenityLoading}
                                      accept="image/*"
                                      onChange={(e) =>
                                        this._onChangeFile("label", e)
                                      }
                                    />
                                    Replace
                                  </span>
                                </Label>
                              </div>
                            ) : (
                              <div className="group_image plus">
                                <Label className="mb-0">
                                  <Input
                                    type="file"
                                    value=""
                                    disabled={loading.addAmenityLoading}
                                    className="d-none"
                                    accept="image/*"
                                    onChange={(e) =>
                                      this._onChangeFile("label", e)
                                    }
                                  />
                                  <img
                                    src={
                                      require("../../assets/img/plus.png")
                                        .default
                                    }
                                    alt="category"
                                    className="img-fluid"
                                    loading="lazy"
                                  />
                                </Label>
                              </div>
                            )}

                            {amenityCategoriesFormFields.label.error ? (
                              <div className="form-error">
                                {amenityCategoriesFormFields.label.error}
                              </div>
                            ) : null}
                          </div>
                        </td>

                        <td>
                          <div>
                            <Input
                              type="text"
                              value={amenityCategoriesFormFields.category.value}
                              disabled={loading.addAmenityLoading}
                              onChange={(e) =>
                                this._onChangeFormFields(
                                  "category",
                                  e.target.value
                                )
                              }
                            />
                            {amenityCategoriesFormFields.category.error ? (
                              <div className="form-error">
                                {amenityCategoriesFormFields.category.error}
                              </div>
                            ) : null}
                          </div>
                        </td>
                        <td className="td-action">
                          <div className="equal_width">
                            <Button
                              // className="btn--primary d-flex justify-content-center"
                              color="primary"
                              disabled={loading.addAmenityLoading}
                              onClick={() => this._addAmenityCategories()}
                            >
                              {loading.addAmenityLoading ? (
                                <i className="fa fa-spinner fa-spin fs-13 mr-1" />
                              ) : null}
                              Add
                            </Button>
                            <Button
                              color="priamry"
                              className="btn-cancel ml-3"
                              disabled={loading.addAmenityLoading}
                              onClick={() =>
                                this._resetAmenityCategoriesFormFields()
                              }
                            >
                              Reset
                            </Button>
                          </div>
                        </td>
                      </tr>

                      {/* ====== Fields ======= */}
                      {amenityCategories.length
                        ? amenityCategories.map((each, index) => (
                            <tr
                              className={
                                isEditAmenityCategoryId !== each.id
                                  ? `unread-request`
                                  : ""
                              }
                              key={each.id}
                            >
                              <td>
                                <div className="amenity-location">
                                  {each.label?.value?.previewBlob ||
                                  each.label?.value?.uploadUrl ? (
                                    <div className={`input-gallery-col`}>
                                      <div className="group_image">
                                        <img
                                          src={
                                            each.label.value.previewBlob ||
                                            each.label.value.uploadUrl
                                          }
                                          alt="cover"
                                          loading="lazy"
                                        />
                                      </div>
                                      {isEditAmenityCategoryId === each.id ? (
                                        <Label>
                                          <span className="replace_map">
                                            <Input
                                              type="file"
                                              value=""
                                              className="d-none"
                                              disabled={
                                                isEditAmenityCategoryId ===
                                                each.id
                                                  ? false
                                                  : true
                                              }
                                              accept="image/*"
                                              onChange={(e) =>
                                                this._onChangeEditFile(
                                                  "label",
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                            Replace
                                          </span>
                                        </Label>
                                      ) : null}
                                    </div>
                                  ) : (
                                    <div className="group_image plus">
                                      <Label className="mb-0">
                                        <Input
                                          type="file"
                                          value=""
                                          disabled={
                                            isEditAmenityCategoryId === each.id
                                              ? false
                                              : true
                                          }
                                          className="d-none"
                                          accept="image/*"
                                          onChange={(e) =>
                                            this._onChangeEditFile(
                                              "label",
                                              e,
                                              index
                                            )
                                          }
                                        />
                                        <img
                                          src={
                                            require("../../assets/img/plus.png")
                                              .default
                                          }
                                          alt="category"
                                          className="img-fluid"
                                          loading="lazy"
                                        />
                                      </Label>
                                    </div>
                                  )}

                                  {each.label.error ? (
                                    <div className="form-error">
                                      {each.label.error}
                                    </div>
                                  ) : null}
                                </div>
                              </td>

                              <td>
                                <div>
                                  <Input
                                    type="text"
                                    disabled={
                                      isEditAmenityCategoryId === each.id
                                        ? false
                                        : true
                                    }
                                    value={each.category.value}
                                    onChange={(e) =>
                                      this._onChangeEditFormFields(
                                        "category",
                                        e.target.value,
                                        index
                                      )
                                    }
                                  />
                                  {each.category.error ? (
                                    <div className="form-error">
                                      {each.category.error}
                                    </div>
                                  ) : null}
                                </div>
                              </td>

                              <td className="td-action">
                                {isEditAmenityCategoryId === each.id ? (
                                  <div className="equal_width save-cancel btn-blue">
                                    <Button
                                      // className="d-flex align-items-center justify-content-center btn-blue-primary"
                                      color="primary"
                                      disabled={
                                        each.id ===
                                          loading.updateAmenityLoading ||
                                        each.id === loading.removeAmenityLoading
                                          ? true
                                          : false
                                      }
                                      onClick={() =>
                                        this._onSaveEditAmenityCategories(
                                          each.id,
                                          index
                                        )
                                      }
                                    >
                                      {each.id ===
                                      loading.updateAmenityLoading ? (
                                        <i className="fa fa-spinner fa-spin fs-13 mr-1" />
                                      ) : null}
                                      Save
                                    </Button>
                                    <Button
                                      color="priamry"
                                      className="btn-cancel ml-3"
                                      disabled={
                                        each.id ===
                                          loading.updateAmenityLoading ||
                                        each.id === loading.removeAmenityLoading
                                          ? true
                                          : false
                                      }
                                      onClick={() =>
                                        this.setState(
                                          { isEditAmenityCategoryId: false },
                                          () =>
                                            this._setAmenityCategoriesSettings()
                                        )
                                      }
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                ) : (
                                  <div className="edit-del">
                                    <div className="equal_width">
                                      <Button
                                        // className="btn-blue-primary d-flex justify-content-center"
                                        color="primary"
                                        disabled={
                                          each.id ===
                                            loading.updateAmenityLoading ||
                                          each.id ===
                                            loading.removeAmenityLoading
                                            ? true
                                            : false
                                        }
                                        onClick={() =>
                                          this.setState(
                                            {
                                              isEditAmenityCategoryId: each.id,
                                            },
                                            () =>
                                              this._setAmenityCategoriesSettings()
                                          )
                                        }
                                      >
                                        {each.id ===
                                        loading.updateAmenityLoading ? (
                                          <i className="fa fa-spinner fa-spin fs-13 mr-1" />
                                        ) : null}
                                        Edit
                                      </Button>
                                      <Button
                                        // className="btn-red-outline d-flex justify-content-center ml-3"
                                        color="priamry"
                                        className="btn-cancel ml-3"
                                        disabled={
                                          each.id ===
                                            loading.updateAmenityLoading ||
                                          each.id ===
                                            loading.removeAmenityLoading
                                            ? true
                                            : false
                                        }
                                        onClick={() =>
                                          this._removeAmenityCategoriesAlert(
                                            each
                                          )
                                        }
                                      >
                                        {each.id ===
                                        loading.removeAmenityLoading ? (
                                          <i className="fa fa-spinner fa-spin fs-13 mr-1" />
                                        ) : null}
                                        Remove
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAndUpdateSettings: () => dispatch(getAndUpdateSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
