import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { errorHandler, formatDecimalNumber } from "../../helper-methods";
import { getGuestGraphData } from "../../http/http-calls";
import LoadingSkeleton from "react-loading-skeleton";

const GuestGraph = ({ payload }) => {
  // #1.1
  const [activeUsersGraph, setActiveUsersGraph] = useState({
    barOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        type: "bar",
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: (value) => value.toFixed(0),
          style: {
            colors: "#247BA0",
          },
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#247BA0"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    barSeries: [
      {
        name: `# Active Users`,
        data: [],
      },
    ],
  });
  // #1.2
  const [activeUsersAgeGraph, setActiveUsersAgeGraph] = useState({
    barOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          style: {
            colors: "#247BA0",
          },
          formatter: function (value) {
            if (value === "not-given") return `Not Given`;

            if (value === "75+") return value;

            if (typeof value === "string") {
              const [min, max] = value.split("-");

              if (max) return `${min} - ${max}`;
            }

            return value;
          },
        },
      },
      xaxis: {
        categories: ["not-given", "18-29", "30-44", "45-59", "60-74", "75+"],
      },
      colors: ["#247BA0"],
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
    },
    barSeries: [
      {
        name: `# Active Users`,
        data: [],
      },
    ],
  });

  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const [pastWeekPercentage, setPastWeekPercentage] = useState(0);

  const [loading, setLoading] = useState(false);

  const _setDemographicsGraph = (guestDemographics = []) => {
    const newActiveUsersGraph = { ...activeUsersGraph };
    newActiveUsersGraph.barOptions.xaxis.categories = [];
    newActiveUsersGraph.barSeries[0].data = [];
    guestDemographics.forEach((each) => {
      newActiveUsersGraph.barOptions.xaxis.categories.push(
        `Week ${each._id.week}`
      );
      newActiveUsersGraph.barSeries[0].data.push(each.count);
    });
    setActiveUsersGraph(newActiveUsersGraph);

    setActiveUsersCount(
      guestDemographics.reduce((acc, each) => acc + each.count, 0)
    );

    const guestLen = guestDemographics?.length;

    if (guestLen > 1) {
      const percentage = formatDecimalNumber(
        (guestDemographics[guestLen - 1].count /
          guestDemographics[guestLen - 2].count) *
          100 -
          100
      );
      setPastWeekPercentage(percentage);
    } else {
      setPastWeekPercentage(0);
    }
  };

  const _setAgeGraph = (guestAge = []) => {
    const newActiveUsersAgeGraph = { ...activeUsersAgeGraph };

    newActiveUsersAgeGraph.barSeries[0].data = [];

    newActiveUsersAgeGraph.barOptions.xaxis.categories.forEach((category) => {
      const findGuestAge = guestAge.find((each) => each._id === category);

      newActiveUsersAgeGraph.barSeries[0].data.push(
        findGuestAge?.count ? findGuestAge.count : 0
      );
    });
    setActiveUsersAgeGraph(newActiveUsersAgeGraph);
  };

  const _getGuestGraphData = (payload) => {
    setLoading(true);
    getGuestGraphData(payload)
      .then((res) => {
        if (res.result) {
          _setDemographicsGraph(res.result.guestDemographics);
          _setAgeGraph(res.result.guestAge);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  useEffect(() => {
    if (payload?.startDate && payload?.endDate && payload?.groupByUnit)
      _getGuestGraphData(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.startDate, payload?.endDate, payload?.groupByUnit]);

  return (
    <div className="graphWrapper">
      {/* Guest Demographics */}
      <div className="graphTitleWrap">
        <h1>Guest Demographics</h1>
        <div className="activeUser">
          {loading ? (
            <LoadingSkeleton />
          ) : (
            <h4>
              <span style={{ background: "#247ba0" }} /> {activeUsersCount}
            </h4>
          )}

          <div>
            <h5># Active Users</h5>
            <p
              className={
                pastWeekPercentage
                  ? pastWeekPercentage > 0
                    ? "success"
                    : "danger"
                  : ""
              }
            >
              {loading ? (
                <LoadingSkeleton />
              ) : (
                <>
                  {pastWeekPercentage ? (
                    pastWeekPercentage > 0 ? (
                      <i className="fa fa-caret-up" />
                    ) : (
                      <i className="fa fa-caret-down" />
                    )
                  ) : null}
                  <span>{Math.abs(pastWeekPercentage)}%</span> Over the past
                  Week
                </>
              )}
            </p>
          </div>
        </div>
      </div>

      <p className="subHeading" style={{ marginTop: 40 }}>
        # Active Users
      </p>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {activeUsersGraph.barSeries[0]?.data?.length ? (
            <Chart
              options={activeUsersGraph.barOptions}
              series={activeUsersGraph.barSeries}
              type="bar"
              width="100%"
              height="275"
              className="chartWrap"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}

      {/* Guest Age */}
      <p className="subHeading" style={{ marginTop: 40 }}>
        # Guest Age
      </p>

      {loading ? (
        <LoadingSkeleton height={200} />
      ) : (
        <>
          {activeUsersAgeGraph.barSeries[0]?.data?.length ? (
            <Chart
              options={activeUsersAgeGraph.barOptions}
              series={activeUsersAgeGraph.barSeries}
              type="bar"
              width="100%"
              height="270"
              className="chartWrap"
            />
          ) : (
            <p>There is no data to display.</p>
          )}
        </>
      )}
    </div>
  );
};

export default GuestGraph;
