import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row, List } from "reactstrap";

const NotificationsPage = () => {
  const history = useHistory();

  return (
    <div className="content">
      <Container fluid>
        {/* ===== Page Title ===== */}
        <div className="page_Inner_header">
          <Row className="align-items-center no-margin">
            <Col xl={8} className="mobilePadding-0">
              <div className="page_title">
                <Button
                  color="link"
                  className="pl-0 pr-2"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h1 className="space_remove">Notifications</h1>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-4  main_inner  bg-white">
          <div className="notificationContentWrap">
            <div className="notificationDay">Today</div>
            <List>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../../assets/img/user-1.png").default}
                    alt="avatar"
                    loading="lazy"
                  />
                </div>

                <div className="ml-3 d-flex flex-column justify-content-center">
                  <h2 className="d-flex align-items-center">
                    <b>Mark Bill</b>{" "}
                    <p className="mb-0 ml-2 fs-14">(@Mark_Bill)</p>
                  </h2>

                  <p className="Notification_content">
                    It is a long established fact that a reader will be
                    distracted by the readable content
                    <span className="commentTime">2h</span>
                  </p>
                </div>
              </li>
            </List>
          </div>
          <div className="notificationContentWrap">
            <div className="notificationDay">Today</div>
            <List>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../../assets/img/user-1.png").default}
                    alt="avatar"
                    loading="lazy"
                  />
                </div>

                <div className="ml-3 d-flex flex-column justify-content-center">
                  <h2 className="d-flex align-items-center">
                    <b>Mark Bill</b>{" "}
                    <p className="mb-0 ml-2 fs-14">(@Mark_Bill)</p>
                  </h2>

                  <p className="Notification_content">
                    It is a long established fact that a reader will be
                    distracted by the readable content
                    <span className="commentTime">2h</span>
                  </p>
                </div>
              </li>
            </List>
          </div>
          <div className="notificationContentWrap">
            <div className="notificationDay">Today</div>
            <List>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../../assets/img/user-1.png").default}
                    alt="avatar"
                    loading="lazy"
                  />
                </div>

                <div className="ml-3 d-flex flex-column justify-content-center">
                  <h2 className="d-flex align-items-center">
                    <b>Mark Bill</b>{" "}
                    <p className="mb-0 ml-2 fs-14">(@Mark_Bill)</p>
                  </h2>

                  <p className="Notification_content">
                    It is a long established fact that a reader will be
                    distracted by the readable content
                    <span className="commentTime">2h</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../../assets/img/user-1.png").default}
                    alt="avatar"
                    loading="lazy"
                  />
                </div>

                <div className="ml-3 d-flex flex-column justify-content-center">
                  <h2 className="d-flex align-items-center">
                    <b>Mark Bill</b>{" "}
                    <p className="mb-0 ml-2 fs-14">(@Mark_Bill)</p>
                  </h2>

                  <p className="Notification_content">
                    It is a long established fact that a reader will be
                    distracted by the readable content
                    <span className="commentTime">2h</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../../assets/img/user-1.png").default}
                    alt="avatar"
                    loading="lazy"
                  />
                </div>

                <div className="ml-3 d-flex flex-column justify-content-center">
                  <h2 className="d-flex align-items-center">
                    <b>Mark Bill</b>{" "}
                    <p className="mb-0 ml-2 fs-14">(@Mark_Bill)</p>
                  </h2>

                  <p className="Notification_content">
                    It is a long established fact that a reader will be
                    distracted by the readable content
                    <span className="commentTime">2h</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={require("../../assets/img/user-1.png").default}
                    alt="avatar"
                    loading="lazy"
                  />
                </div>

                <div className="ml-3 d-flex flex-column justify-content-center">
                  <h2 className="d-flex align-items-center">
                    <b>Mark Bill</b>{" "}
                    <p className="mb-0 ml-2 fs-14">(@Mark_Bill)</p>
                  </h2>

                  <p className="Notification_content">
                    It is a long established fact that a reader will be
                    distracted by the readable content
                    <span className="commentTime">2h</span>
                  </p>
                </div>
              </li>
            </List>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NotificationsPage;
