import React, { useState } from "react";
import { Label, FormGroup, Input, Table } from "reactstrap";
import moment from "moment";
import { formatDateForApi } from "../../helper-methods";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import {
  Button,
  InputGroup,
  FormControl,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";

const Outings = (props) => {
  const [filters, setFilters] = useState({
    dateRange: {
      startDate: null,
      endDate: null,
    },
    resort: "",
    pax: "",
    dicount: "",
    status: "",
  });
  const [focusedInput, setFocusedInput] = useState(null);

  const _onDatesChange = (startDate, endDate) => {
    const newFilters = { ...filters };
    if (startDate) startDate = moment(startDate).startOf("week");
    if (endDate) endDate = moment(endDate).endOf("week");
   

    newFilters.dateRange["startDate"] = startDate;
    newFilters.dateRange["endDate"] = endDate;
    if (startDate && endDate && (focusedInput === "endDate" )) {
      const newFilter = { ...filters };
      // newPayload.startDate = "01/04/2022";
      // newPayload.endDate = "01/06/2022";
      newFilters.dateRange["startDate"]= formatDateForApi(startDate);
      newFilters.dateRange["endDate"] = formatDateForApi(endDate);
      setFilters(newFilter);
    }
   
  };


 
  const _onChangeFilters = (key, value) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);
  };

  const _resetFilters = () => {
    setFilters({
      dateRange: {
        startDate: null,
        endDate: null,
      },
      resort: "",
      pax: "",
      dicount: "",
      status: "",
    });
  };

  return (
    <div className="content">
      <Container fluid className="mobilePadding-0">
        <div className="page_Inner_header">
          <Row className="align-items-center">
            <Col xl={5} md={4}>
              <div className="page_title">
                <h1 className="space_remove">Outings</h1>
              </div>
            </Col>
          </Row>
        </div>

        {/* ======== Filter ======= */}
        <Card body className="search-filter pt-2 pb-3 mb-4">
          <div>
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex justify-content-center align-items-end">
                <i className="fa fa-filter" />
                <FormGroup className="mb-0">
                  <Label>Resort</Label>
                  <Input
                    type="select"
                    value={filters.resort}
                    onChange={(e) => _onChangeFilters("resort", e.target.value)}
                  >
                    <option value="">All</option>
                    <option>0-50</option>
                    <option>51-100</option>
                    <option>100-250</option>
                    <option>250+</option>
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label>PAX</Label>
                  <Input
                    type="select"
                    value={filters.pax}
                    onChange={(e) => _onChangeFilters("pax", e.target.value)}
                  >
                    <option value="">All</option>
                    <option>0</option>
                    <option>1 - 5</option>
                    <option>6 - 10</option>
                    <option>10 +</option>
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label className="d-block">Date Range</Label>

                  {/* <CustomDateRangePicker
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    maxDate={dateRange.maxDate}
                    startDateId={"startDate_kpi_dashboard"}
                    endDateId={`endDate_kpi_dashboard`}
                    isDisabledOutsideRange={true}
                    isMaxDateValidation={true}
                    onDatesChange={({ startDate, endDate }) =>
                      _onDatesChange({ startDate, endDate })
                    }
                    focusedInput={focusedInput}
                    setFocusedInput={setFocusedInput}
                  /> */}
{/* <DateRangePicker
  startDate={filters.dateRange.startDate} // momentPropTypes.momentObj or null,
  startDateId="startDate_outings" // PropTypes.string.isRequired,
  endDate={filters.dateRange.endDate} // momentPropTypes.momentObj or null,
  endDateId="endDate_outings" // PropTypes.string.isRequired,
  onDatesChange={({ startDate, endDate }) =>  _onDatesChange(startDate, endDate)} // PropTypes.func.isRequired,
  focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
  onFocusChange={setFocusedInput} // PropTypes.func.isRequired,
/> */}
                  <CustomDateRangePicker 
                    startDate={filters.dateRange.startDate}
                    endDate={filters.dateRange.endDate}
                    startDateId={"startDate_outings"}
                    endDateId={`endDate_outings`}
                    onDatesChange={(startDate, endDate) =>
                      _onDatesChange(startDate, endDate)
                    }
                    focusedInput={focusedInput}
                    setFocusedInput={setFocusedInput}
                  /> 
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label>Discount</Label>
                  <Input
                    type="select"
                    value={filters.dicount}
                    onChange={(e) =>
                      _onChangeFilters("dicount", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option>0%</option>
                    <option>10 - 25%</option>
                    <option>upto 10 %</option>
                    <option>25% +</option>
                  </Input>
                </FormGroup>
                <FormGroup className="mb-0 ml-3">
                  <Label>Status</Label>
                  <Input
                    type="select"
                    value={filters.status}
                    onChange={(e) => _onChangeFilters("status", e.target.value)}
                  >
                    <option value="">All</option>
                    <option>Booked</option>
                    <option>Completed</option>
                    <option>Cancelled by user</option>
                    <option>Cancelled by operator</option>
                  </Input>
                </FormGroup>
              </div>
              <div className="d-flex align-items-end ml-3">
                <FormGroup className="mb-0">
                  <Label>Search By Name</Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Enter"
                      autoComplete="off"
                      name="firstName"
                    />
                    <InputGroup.Text id="basic-addon2">
                      <i className="fas fa-search" />
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
                <Button
                  color="primary"
                  className="btn-clear ml-4"
                  onClick={() => _resetFilters()}
                >
                  <i className="fa fa-eraser fs-15" />
                </Button>
              </div>
            </div>
          </div>
        </Card>

        <Table responsive className="m-0">
          <thead>
            <tr>
              <th>Vacationer</th>
              <th>Resort</th>
              <th>Outings</th>
              <th>Location</th>
              <th>PAX</th>
              <th>Amount</th>
              <th>Discount</th>
              <th>Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Link to="#">John Doe</Link>
              </td>
              <td>
                <Link to="#">XYZ</Link>
              </td>
              <td>Skydiving</td>
              <td>Cabo</td>
              <td>4</td>
              <td>$100</td>
              <td>10%</td>
              <td>
                <p className="fs-15 m-0">
                  <span>Dec 16</span> - <span>06:00 pm</span>
                </p>
              </td>
              <td>
                <Input type="select" name="category">
                  <option>Booked</option>
                  <option>Completed</option>
                  <option>Cancelled by user</option>
                  <option>Cancelled by operator</option>
                </Input>
              </td>
              <td>
                <Link to="#">Invoice</Link>
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default Outings;
