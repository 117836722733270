import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

import { persistor, store } from "./redux/store";
import AdminLayout from "./views/containers/AdminLayout";
import FullPageLoader from "./views/containers/full-page-loader";
import LoginPage from "./views/publicPages/LoginPage";
import RegisterPage from "./views/publicPages/RegisterPage";
import ForgotPassword from "./views/publicPages/ForgotPassowrdPage";
import PublicRoute from "./components/public-route";
import ProtectedRoute from "./components/protected-routes";
import ErrorNotFound from "./components/Error-not-found";
import { useEffect } from "react";
import { OneSignalKey } from "./config";

const App = () => {
  useEffect(() => {
    var OneSignal = window.OneSignal || [];
    OneSignal.push(function () {
      OneSignal.init({
        appId: OneSignalKey,
        notifyButton: {
          enable: false,
        },
        requiresUserPrivacyConsent: false,
        autoResubscribe: true,
      });
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={<div></div>} persistor={persistor}>
        <Router>
          {/* //Public Routes */}
          <div className="vayco-auth">
            <FullPageLoader />

            <Toaster position="bottom-right" reverseOrder={false} />

            <Switch>
              <PublicRoute
                exact
                path="/register"
                component={RegisterPage}
                redirectRoute={"/"}
              />
              <PublicRoute
                exact
                path="/forgot-password"
                component={ForgotPassword}
                redirectRoute={"/"}
              />
              <PublicRoute
                exact
                path="/login"
                component={LoginPage}
                redirectRoute={"/"}
              />

              <ProtectedRoute
                exact
                path="*"
                component={AdminLayout}
                redirectRoute="/login"
              />

              <Route component={ErrorNotFound} />
            </Switch>
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
