import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  List,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import {
  getChatGroupMemberDetails,
  removeParticipantFromGroup,
  updateChatGroupMemberDetails,
} from "../../http/http-calls";
import {
  capitalize,
  formatDate,
  formatDateAndTimeForApi,
  getTimezoneDate,
  showToast,
} from "../../helper-methods";
import SweetAlert from "react-bootstrap-sweetalert";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import ReactDatetime from "react-datetime";
import moment from "moment";

const GroupChatMemberModal = ({
  isOpen,
  toggle,
  removeMemberFromList,
  data,
  selectedGroup,
  resortDetails,
  timeZone,
}) => {
  const [alert, setAlert] = useState(null);
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(false);

  const [formFields, setFormFields] = useState({
    startTimeStamp: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    endTimeStamp: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    roomNo: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: true,
    },
    totalGuests: {
      value: "",
      error: null,
      isDirty: false,
      isValidationRequired: false,
    },
  });

  const _resetModalState = () => {
    setAlert(null);
    setLoading(false);
    setBooking(null);

    setFormFields({
      startTimeStamp: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      endTimeStamp: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      roomNo: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: true,
      },
      totalGuests: {
        value: "",
        error: null,
        isDirty: false,
        isValidationRequired: false,
      },
    });
  };

  const _closeModal = () => {
    _resetModalState();
    toggle();
  };

  const _hideAlert = () => {
    setAlert(null);
  };

  const _removeParticipantFromGroup = () => {
    setLoading("remove");
    _hideAlert();

    const payload = {
      _participant: data?._id,
    };

    removeParticipantFromGroup(selectedGroup?._id, payload)
      .then((res) => {
        showToast("Participant removed successfully.", "success");
        setLoading(false);
        // need groupId & memberId - remove it from local state
        removeMemberFromList(selectedGroup?._id, data?._id);
        _closeModal();
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _onRemoveConfirmAlert = () => {
    if (data?._id && selectedGroup?._id) {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => _removeParticipantFromGroup()}
          onCancel={() => _hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, remove it!"
          cancelBtnText="Cancel"
          showCancel
        >
          You are removing{" "}
          {data?.name?.full
            ? `${capitalize(data.name.full)}`
            : data?.username
            ? `@${data.username}`
            : "N/A"}{" "}
          user. You will not be able to recover it!
        </SweetAlert>
      );
    } else {
      console.log("data, selectedGroup>>", data, selectedGroup);
      showToast("Somthing went wrong, Try again after sometime.", "error");
    }
  };

  const _validateFormFields = (newFormFields) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newFormFields[key].isDirty) {
          switch (key) {
            case "roomNo": {
              if (newFormFields[key]?.value?.trim().length) {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "startTimeStamp": {
              if (newFormFields[key]?.value) {
                if (newFormFields.endTimeStamp.value) {
                  if (
                    new Date(newFormFields[key].value) <
                      new Date(newFormFields.endTimeStamp.value) ||
                    moment(newFormFields[key].value).isSame(
                      newFormFields.endTimeStamp.value,
                      "day"
                    )
                  ) {
                    newFormFields[key].error = null;
                    newFormFields[key].isDirty = false;
                  } else {
                    newFormFields[key].error = "*Invalid Date";
                    isFormValid = false;
                  }
                } else {
                  newFormFields[key].error = null;
                  newFormFields[key].isDirty = false;
                }
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "endTimeStamp": {
              if (newFormFields[key]?.value) {
                if (newFormFields.startTimeStamp.value) {
                  if (
                    new Date(newFormFields[key].value) >
                      new Date(newFormFields.startTimeStamp.value) ||
                    moment(newFormFields[key].value).isSame(
                      newFormFields.startTimeStamp.value,
                      "day"
                    )
                  ) {
                    newFormFields[key].error = null;
                    newFormFields[key].isDirty = false;
                  } else {
                    newFormFields[key].error = "*Invalid Date";
                    isFormValid = false;
                  }
                } else {
                  newFormFields[key].error = null;
                  newFormFields[key].isDirty = false;
                }
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setFormFields(newFormFields);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };

    if (key === "startTimeStamp" || key === "endTimeStamp")
      newFormFields[key].value = value ? new Date(value).toISOString() : "";
    else newFormFields[key].value = value;

    newFormFields[key].isDirty = true;
    setFormFields(newFormFields);
    _validateFormFields(newFormFields);
  };

  const _updateChatGroupMemberDetails = (payload) => {
    payload["resortId"] = resortDetails?._id;

    updateChatGroupMemberDetails(payload)
      .then((res) => {
        showToast("Updated successfully.", "success");
        _closeModal();
      })
      .catch((error) => {
        console.log("error>>", error);
        setLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _onSaveMember = async () => {
    try {
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach(
        (key) => (newFormFields[key].isDirty = true)
      );
      setFormFields(newFormFields);
      const isFormValid = await _validateFormFields(newFormFields);

      if (isFormValid) {
        setLoading("save");

        const newFormFields = { ...formFields };

        const payload = {
          vacationerId: data?._id,
          roomNo: newFormFields.roomNo.value.trim(),
          startTimeStamp: formatDateAndTimeForApi(
            newFormFields.startTimeStamp.value
          ),
          endTimeStamp: formatDateAndTimeForApi(
            newFormFields.endTimeStamp.value
          ),
        };

        if (newFormFields?.totalGuests?.value?.trim().length)
          payload["totalGuests"] = newFormFields.totalGuests.value.trim();

        _updateChatGroupMemberDetails(payload);
      }
    } catch (error) {
      console.log("error>>", error);
      setLoading(false);
      showToast(
        error?.reason?.length
          ? error.reason
          : "Something went wrong, Try again after sometime.",
        "error"
      );
    }
  };

  const _setModalForm = (data) => {
    const newFormFields = { ...formFields };
    newFormFields.roomNo.value = data?.roomNo ? data.roomNo.toString() : "";
    newFormFields.startTimeStamp.value = data?.startTimeStamp
      ? getTimezoneDate(data.startTimeStamp, timeZone)
      : "";
    newFormFields.endTimeStamp.value = data?.endTimeStamp
      ? getTimezoneDate(data.endTimeStamp, timeZone)
      : "";
    newFormFields.totalGuests.value = data?.totalGuests
      ? data.totalGuests.toString()
      : "";
    setFormFields(newFormFields);
  };

  const _getChatGroupMemberDetails = (vacationerid) => {
    setLoading("get");

    const payload = {
      resortid: resortDetails?._id,
      vacationerid,
    };

    getChatGroupMemberDetails(payload)
      .then((res) => {
        if (res.booking) {
          setBooking(res.booking);
          _setModalForm(res.booking);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
        _closeModal();
      });
  };

  useEffect(() => {
    if (isOpen) _getChatGroupMemberDetails(data?._id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data?._id]);

  return (
    <>
      {alert}

      <Modal
        isOpen={isOpen}
        className="addUserModal"
        toggle={() => _closeModal()}
        centered
      >
        <ModalHeader toggle={() => _closeModal()} className="text-center">
          <div className="user-img mt-4">
            <img
              src={
                booking?._vacationer?.avatar || data?.avatar
                  ? booking?._vacationer?.avatar || data?.avatar
                  : DEFAULT_PROFILE_PICTURE
              }
              onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
              alt="avatar"
              loading="lazy"
            />
          </div>
          <div className="fs-18 text-blue mb-0 mt-4 text-capitalize fw-bold">
            {(booking?._vacationer?.name?.full &&
              capitalize(booking._vacationer.name.full)) ||
              (data?.name?.full && capitalize(data.name.full)) ||
              "Loading"}{" "}
            {loading === "get" ? (
              <i className="fa fa-spinner fa-spin mr-1" />
            ) : null}
          </div>
          <span className="fs-16 text-secondary text-capitalize fw-light">
            @
            {(booking?._vacationer?.username &&
              capitalize(booking._vacationer.username)) ||
              (data?.username && capitalize(data.username)) ||
              "Loading"}
          </span>
        </ModalHeader>
        <ModalBody className="pt-0">
          <div className="d-flex justify-content-center btn-blue">
            <Button
              className="btn-primary-outline px-5"
              disabled={loading ? true : false}
              onClick={() => _onRemoveConfirmAlert()}
            >
              {loading === "remove" ? (
                <i className="fa fa-spinner fa-spin mr-1" />
              ) : (
                <i className="fas fa-trash mr-2" />
              )}{" "}
              Remove
            </Button>
          </div>

          <List type="unstyled">
            <li>
              <Row>
                <Col md="6">
                  <div className="d-flex justify-content-between mt-2">
                    <span className="text-capitalize text-secondary fs-16">
                      Check In
                    </span>
                    <span className="text-secondary fs-16">:</span>
                  </div>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-0">
                    <InputGroup>
                      <ReactDatetime
                        inputProps={{
                          disabled: loading ? true : false,
                          className: "form-control",
                          placeholder: "Select the check-in date",
                          value: formatDate(formFields.startTimeStamp.value),
                        }}
                        value={
                          formFields.startTimeStamp.value
                            ? new Date(formFields.startTimeStamp.value)
                            : ""
                        }
                        onChange={(e) =>
                          _onChangeFormFields("startTimeStamp", e._d)
                        }
                        dateFormat={true}
                        timeFormat={false}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                    {formFields.startTimeStamp.error ? (
                      <div className="form-error">
                        {formFields.startTimeStamp.error}
                      </div>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </li>

            <li>
              <Row>
                <Col md="6">
                  <div className="d-flex justify-content-between">
                    <span className="text-capitalize text-secondary fs-16">
                      Check Out
                    </span>
                    <span className="text-secondary fs-16">:</span>
                  </div>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-0">
                    <InputGroup>
                      <ReactDatetime
                        inputProps={{
                          disabled: loading ? true : false,
                          className: "form-control",
                          placeholder: "Select the check-out date",
                          value: formatDate(formFields.endTimeStamp.value),
                        }}
                        value={
                          formFields.endTimeStamp.value
                            ? new Date(formFields.endTimeStamp.value)
                            : ""
                        }
                        onChange={(e) =>
                          _onChangeFormFields("endTimeStamp", e._d)
                        }
                        isValidDate={(current) =>
                          formFields.startTimeStamp.value
                            ? current.isAfter(
                                moment(
                                  formFields.startTimeStamp.value
                                ).subtract(1, "day")
                              )
                            : true
                        }
                        dateFormat={true}
                        timeFormat={false}
                        closeOnSelect={true}
                      />
                    </InputGroup>
                    {formFields.endTimeStamp.error ? (
                      <div className="form-error">
                        {formFields.endTimeStamp.error}
                      </div>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </li>

            <li>
              <Row>
                <Col md="6">
                  <div className="d-flex justify-content-between">
                    <span className="text-capitalize text-secondary fs-16">
                      Room #
                    </span>
                    <span className="text-secondary fs-16">:</span>
                  </div>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-0">
                    <InputGroup>
                      <Input
                        type="text"
                        placeholder="Enter the room number"
                        autoComplete="off"
                        name="roomNo"
                        disabled={loading ? true : false}
                        value={formFields.roomNo.value}
                        onChange={(e) =>
                          _onChangeFormFields("roomNo", e.target.value)
                        }
                      />
                    </InputGroup>
                    {formFields.roomNo.error ? (
                      <div className="form-error">
                        {formFields.roomNo.error}
                      </div>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </li>

            <li>
              <Row className="align-items-center">
                <Col md="6">
                  <div className="d-flex justify-content-between">
                    <span className="text-capitalize text-secondary fs-16">
                      Total Guest #
                    </span>
                    <span className="text-secondary fs-16">:</span>
                  </div>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-0">
                    <InputGroup>
                      <Input
                        type="text"
                        placeholder="Enter the total guest #"
                        autoComplete="off"
                        name="totalGuests"
                        disabled={loading ? true : false}
                        value={formFields.totalGuests.value}
                        onChange={(e) =>
                          _onChangeFormFields("totalGuests", e.target.value)
                        }
                      />
                    </InputGroup>
                    {formFields.totalGuests.error ? (
                      <div className="form-error">
                        {formFields.totalGuests.error}
                      </div>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </li>
          </List>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center mt-4">
          <Button
            className="btn-primary-outline px-5 mr-3"
            disabled={loading ? true : false}
            onClick={() => _closeModal()}
          >
            Cancel
          </Button>
          <Button
            className="btn-primary px-5"
            disabled={loading ? true : false}
            onClick={() => _onSaveMember()}
          >
            {loading === "save" ? (
              <i className="fa fa-spinner fa-spin mr-1" />
            ) : null}{" "}
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default GroupChatMemberModal;
