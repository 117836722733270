import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Spinner, Button } from "reactstrap";
import {
  InputGroup,
  FormControl,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Label } from "reactstrap";
import { deleteAmenityById, getAllAmenities } from "../../http/http-calls";
import {
  capitalize,
  extractQueryParams,
  showToast,
} from "../../helper-methods";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

let searchInterval;

const HotelAmenities = () => {
  const history = useHistory();

  const [resortId, setResortId] = useState(null);

  const [getAllAmenitiesPayload, setGetAllAmenitiesPayload] = useState({
    filters: {},
    search: "",
    skip: 0,
    pageNumber: 0,
    limit: 100,
  });
  const [amenities, setAmenities] = useState([]);
  const [formattedAmenities, setFormattedAmenities] = useState([]);
  const [amenitiesCount, setAmenitiesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [removeAmenityLoading, setRemoveAmenityLoading] = useState(false);

  const [alert, setAlert] = useState(null);

  const mountedRef = useRef(true);

  const _formatAmenities = (amenities = []) => {
    let formattedAmenities = [];

    if (amenities?.length) {
      amenities.forEach((each) => {
        const findFormatAmenityIndex = formattedAmenities.findIndex(
          (subEach) => subEach.category === each._group?.category
        );

        if (findFormatAmenityIndex > -1) {
          formattedAmenities[findFormatAmenityIndex].amenities.push({
            ...each,
          });
        } else {
          formattedAmenities.push({
            category: each._group?.category,
            amenities: [{ ...each }],
          });
        }
      });
    }

    setFormattedAmenities(formattedAmenities);
  };

  const _getAllAmenities = (payload) => {
    setLoading(true);

    const { resortId } = extractQueryParams();
    setResortId(resortId);

    payload["resortId"] = resortId;

    getAllAmenities(payload)
      .then((res) => {
        if (!mountedRef.current) return null;

        setAmenities(res.amenities);
        setAmenitiesCount(res.count);

        _formatAmenities(res.amenities);

        setLoading(false);
        setSearchLoading(false);
      })
      .catch((error) => {
        if (!mountedRef.current) return null;

        console.log("error>>", error);
        setLoading(false);
        setSearchLoading(false);
        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _searchFilter = (text = "") => {
    try {
      let newAmenities = [...amenities];

      newAmenities = amenities.filter(
        (each) =>
          each.name?.toLowerCase().includes(text.toLowerCase()) ||
          each.category?.toLowerCase().includes(text.toLowerCase())
        // || each.description?.toLowerCase().includes(text.toLowerCase())
      );

      setSearchLoading(false);

      _formatAmenities(newAmenities);
    } catch (error) {
      console.log("error>>", error);
      showToast("Something went wrong, Try again after sometime");
    }
  };

  const _onChangeSearch = (searchValue) => {
    clearInterval(searchInterval);

    setSearchLoading(true);
    const newGetAllAmenitiesPayload = { ...getAllAmenitiesPayload };
    newGetAllAmenitiesPayload["search"] = searchValue;
    newGetAllAmenitiesPayload["skip"] = 0;
    newGetAllAmenitiesPayload["pageNumber"] = 0;
    setGetAllAmenitiesPayload(newGetAllAmenitiesPayload);

    searchInterval = setTimeout(() => {
      _searchFilter(searchValue);
    }, 1000);
  };

  const _hideAlert = () => {
    setAlert(null);
  };

  const _successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Amenity Deleted!"
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
      />
    );
  };

  const _deleteErrorAlert = (error) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Amenity is not deleted"
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
        confirmBtnBsStyle="info"
      >
        {error?.reason?.length
          ? error.reason
          : "Server error, Try again after sometime."}
      </SweetAlert>
    );
  };

  const _deleteAmenityById = (amenityId) => {
    setRemoveAmenityLoading(amenityId);

    const newAmenities = [...amenities];
    const findAmenityIndex = newAmenities.findIndex(
      (each) => each._id === amenityId
    );
    if (findAmenityIndex < 0) {
      showToast("Something went wrong, Try again after sometime.", "error");
      return;
    }

    newAmenities.splice(findAmenityIndex, 1);

    deleteAmenityById(amenityId)
      .then((res) => {
        setRemoveAmenityLoading(false);

        setAmenities(newAmenities);
        setAmenitiesCount((prev) => --prev);

        _formatAmenities(newAmenities);

        // _getAllAmenities(getAllAmenitiesPayload);

        _successDelete();

        showToast("Server error, Try again after sometime.", "success");
      })
      .catch((error) => {
        console.log("error>>", error);
        setRemoveAmenityLoading(false);

        _getAllAmenities(getAllAmenitiesPayload);
        _deleteErrorAlert(error);

        showToast(
          error?.reason?.length
            ? error.reason
            : "Server error, Try again after sometime.",
          "error"
        );
      });
  };

  const _onConfirmAlert = (amenity) => {
    _deleteAmenityById(amenity._id);

    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <Spinner className="mr-2" /> Deleting
            <span>
              {amenity?.name
                ? `${capitalize(amenity.name)}`
                : amenity?.email || "N/A"}{" "}
            </span>
            Account
          </div>
        }
        onConfirm={() => _hideAlert()}
        onCancel={() => _hideAlert()}
        showConfirm={false}
        cancelBtnBsStyle="danger"
        cancelBtnText="Dismiss"
        showCancel
      />
    );
  };

  const _onDeleteAmenityAlert = (amenity) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => _onConfirmAlert(amenity)}
        onCancel={() => _hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You are deleting {amenity?.name ? `${capitalize(amenity.name)}` : "N/A"}{" "}
        amenity. You will not be able to recover it!
      </SweetAlert>
    );
  };

  useEffect(() => {
    // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    _getAllAmenities(getAllAmenitiesPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {alert}

      <div className="content">
        <Container fluid className="mobilePadding-0">
          <div className="page_Inner_header">
            <Row className="align-items-center">
              <Col xl={5} md={4}>
                <div className="page_title">
                  <Button
                    color="link"
                    className="pl-0 pr-2"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  <h1 className="space_remove">Hotel Amenities</h1>
                </div>
              </Col>

              <Col
                xl={7}
                md={8}
                className="text-right d-flex justify-content-md-end justify-content-between mt-3 mt-md-0 align-items-center page_header_right"
              >
                <Button
                  onClick={() =>
                    history.push(`/add-amenity?resortId=${resortId}`)
                  }
                  className="btn btn-primary"
                >
                  Create New
                </Button>
              </Col>
            </Row>
          </div>

          {/* ======== Filter ======= */}
          <Card body className="search-filter pt-2 pb-3 mb-4">
            <div>
              <div className="d-flex justify-content-end align-items-end">
                <FormGroup className="mb-0">
                  <Label>Search By Name</Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Enter"
                      autoComplete="off"
                      name="firstName"
                      value={getAllAmenitiesPayload.search}
                      onChange={(e) => _onChangeSearch(e.target.value)}
                    />
                    <InputGroup.Text id="basic-addon2">
                      {searchLoading ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : getAllAmenitiesPayload.search ? (
                        <i
                          className="fas fa-times"
                          onClick={() => _onChangeSearch("")}
                        />
                      ) : (
                        <i className="fas fa-search" />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
              </div>
            </div>
          </Card>

          {/* ==== Cards ====== */}
          <div className="mt-4 main_inner gridBox bg-white">
            {formattedAmenities.length ? (
              formattedAmenities.map((each) => (
                <div className="tour_list" key={each.category}>
                  <Row className="text-center">
                    <Col xs={12}>
                      <h1 className="title">{capitalize(each.category)}</h1>
                    </Col>
                    {each.amenities.length ? (
                      each.amenities.map((amenity) => (
                        <Col
                          key={amenity._id}
                          xl="3"
                          lg="4"
                          md="4"
                          sm="6"
                          xs="12"
                        >
                          <Card>
                            <Card.Img
                              variant="top"
                              src={
                                amenity.coverImage || amenity.photos?.length
                                  ? amenity.coverImage || amenity.photos[0]
                                  : DEFAULT_PROFILE_PICTURE
                              }
                              onError={(e) =>
                                (e.target.src = DEFAULT_PROFILE_PICTURE)
                              }
                              className="img-responsive"
                            />
                            <Card.Body>
                              <Card.Title>
                                {amenity.name
                                  ? capitalize(amenity.name)
                                  : "N/A"}
                              </Card.Title>
                              {/* <span>
                              {amenity._group?.category
                                ? capitalize(amenity._group?.category)
                                : "N/A"}
                            </span> */}

                              <span>
                                {removeAmenityLoading === amenity._id ? (
                                  <i className="fa fa-spinner fa-spin mr-1" />
                                ) : (
                                  <>
                                    <i
                                      title="Update Amenity"
                                      className="fas fa-pencil-alt edit cursorPointer m-1"
                                      onClick={() =>
                                        history.push(
                                          `/add-amenity?amenityId=${amenity._id}&resortId=${resortId}`
                                        )
                                      }
                                    />
                                    <i
                                      title="Remove Amenity"
                                      className="far fa-trash-alt detele cursorPointer m-1"
                                      onClick={() =>
                                        _onDeleteAmenityAlert(amenity)
                                      }
                                    />
                                  </>
                                )}
                              </span>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                    ) : (
                      <Col sm="12">
                        {loading ? <Spinner /> : "There is no data to display."}
                      </Col>
                    )}
                  </Row>
                </div>
              ))
            ) : (
              <div className="tour_list">
                <Row className="text-center">
                  <Col sm="12">
                    {loading ? <Spinner /> : "There is no data to display."}
                  </Col>
                </Row>
              </div>
            )}
          </div>

          {amenities?.length &&
          amenities?.length > 1 &&
          !getAllAmenitiesPayload.search ? (
            <div className="row mt-4">
              <div className="col-md-6">
                <span className="showng_entries">
                  Total {amenitiesCount} amenities
                </span>
              </div>
            </div>
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default HotelAmenities;
